import "./Cover.css";
import "../../assets/animations/animations.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { toast } from "react-hot-toast";
import { RxInfoCircled } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import { webControls } from "../../endpoints";

function Cover() {
  const isLoggedIn = useContext(AuthContext);
  const { t } = useTranslation();

  function handleRegister() {
    if (isLoggedIn.isLoggedIn) {
      toast(
        (y) => (
          <span className="toast-info">
            <RxInfoCircled className="info-icon" />
            {t("You are already Logged In")}!
          </span>
        ),
        {
          id: "alreadyLoggedIn",
        }
      );
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <>
      <div className={webControls.isCarmax ? "cover" : "cover-ks"}>
        {webControls.isCarmax ? (
          <div className="cover__content">
            <div className="cover__description">
              <h1>{t("Buy cars")}</h1>
              <h3>{t("From online auctions")}</h3>
              <h4>
                {t("Bid")}, <span>{t("Win")}, </span> {t("Own")}, {t("Drive")},{" "}
                {t("Enjoy")}.
              </h4>
            </div>
            <div className="cover__buttons">
              {!isLoggedIn?.isLoggedIn &&
                isLoggedIn.isLoggedIn != undefined && (
                  <>
                    <Link
                      to={!isLoggedIn.isLoggedIn ? "registration/0" : ""}
                      className="register__btn"
                      onClick={handleRegister}
                    >
                      {t("Register")}
                    </Link>
                    <Link
                      className="login__btn"
                      to="login"
                      onClick={handleClick}
                    >
                      {t("Login")}
                    </Link>
                  </>
                )}
            </div>
          </div>
        ) : (
          <section className="container container-cover-ks">
            <div className="cover-content-ks">
              <h1>BLEJ VETURË NGA ANKANDET ONLINE</h1>
              <h4>Ofro, fito, zotëro, vozit, kënaqu!</h4>
              <div className="cover-content-btns__ks">
                {" "}
                <Link
                  to={!isLoggedIn.isLoggedIn ? "registration/0" : ""}
                  className="register__btn-ks"
                  onClick={handleRegister}
                >
                  Regjistrohu
                </Link>
                <Link
                  className="login__btn-ks"
                  to="login"
                  onClick={handleClick}
                >
                  {t("Login")}
                </Link>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default Cover;
