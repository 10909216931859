import PageHeading from "../PageHeading/PageHeading";
import MyBids from "../MyBids/MyBids";
import Protector from "../Protector/Protector";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

import "../MyBids/MyBids.css";
import { Outlet } from "react-router-dom";
import { FavoriteCarsContext } from "../../Context/FavoriteCarsContext";
import { useTranslation } from "react-i18next";

function MyBidsPage() {
  const isLoggedIn = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <Protector
      isLoggedIn={isLoggedIn.isLoggedIn}
      error={
        <div className="my-bids-error">
          {t("You don't have access to this path!")}
        </div>
      }
    >
      <>
        <PageHeading
          title={t("My bids")}
          thisPage={t("My bids")}
          paragraph={t("Win more bids with our expert bid writing services")}
        />
        <MyBids />
        <Outlet />
      </>
    </Protector>
  );
}

export default MyBidsPage;
