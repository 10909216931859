import "./WhyChooseUsSection.css";
import { GiGears } from "react-icons/gi";
import { FaGlobeAmericas } from "react-icons/fa";
import whyChooseUsPhoto from "../../assets/images/why-choose-us-photo.jpg";
import { HiChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import { TbListDetails } from "react-icons/tb";
import { useTranslation } from "react-i18next";

function WhyChooseUsSection() {
  const { t } = useTranslation();

  return (
    <>
      <div className="container">
        <div className="why-choose-us">
          <div className="why-choose-us-content">
            <div className="why-choose-us-content__title">
              <h3 className="title">{t("WHY CHOOSE US")}?</h3>
              <p className="paragraph paragraph-width">
                {t(
                  "Carefully selected auctions, clear transactions, worldwide access"
                )}
                .
              </p>
              <div className="line-dec"></div>
            </div>
            <div className="why-choose-us-content__description">
              <div className="description-group">
                <div className="description">
                  <div className="why-choose-us-icon">
                    {" "}
                    <FaGlobeAmericas size={23} />
                  </div>
                  <div>
                    <h3 className="description__title">
                      {t("GLOBAL MARKETPLACE")}
                    </h3>
                    <p className="paragraph">
                      {t(
                        "Explore unique automotive opportunities on our dynamic global platform"
                      )}
                      .
                    </p>
                  </div>
                </div>
                <div className="description">
                  <div className="why-choose-us-icon">
                    {" "}
                    <TbListDetails size={23} />
                  </div>
                  <div>
                    <h3 className="description__title">{t("CLEAR DEALS")}</h3>
                    <p className="paragraph">
                      {t(
                        "Choose transparent transactions for informed decisions on vehicle details, from history to condition"
                      )}
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="description-group">
                <div className="description">
                  <div className="why-choose-us-icon">
                    <HiChatBubbleOvalLeftEllipsis size={23} />
                  </div>
                  <div>
                    <h3 className="description__title">
                      {t("EXPERT GUIDANCE")}
                    </h3>
                    <p className="paragraph">
                      {t(
                        "Receive insights and support from our team, guiding your experience"
                      )}
                      .
                    </p>
                  </div>
                </div>
                <div className="description">
                  <div className="why-choose-us-icon">
                    <GiGears size={23} />
                  </div>
                  <div>
                    <h3 className="description__title">
                      {t("DIVERSE SELECTION")}
                    </h3>
                    <p className="paragraph">
                      {t(
                        "Explore a diverse array of premium products, tailored to meet your unique needs and preferences"
                      )}
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="why-choose-us-image">
            <img src={whyChooseUsPhoto} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyChooseUsSection;
