import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  DownloadInvoice,
  GetInvoiceByCarId,
  webControls,
} from "../../endpoints";
import "./InvoiceDetailsModal.css";
import { IoMdDownload } from "react-icons/io";
import carmaxLogo from "../../assets/images/carmax-logo-ph.png";
import carmaxKsLogo from "../../assets/images/carmax-logo-red-and-blackversion.png";
import Preloader from "../Preloader/Preloader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InvoiceDetailsModal = ({
  showInvoiceModal,
  carId,
  setShowInvoiceModal,
}) => {
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [isReady, setIsReady] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const columns = [
    {
      title: t("Charges"),
      dataIndex: "charges1",
      key: "charges1",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "",
      dataIndex: "charges2",
      key: "charges2",
      render: (text) => <a>{text}</a>,
    },
  ];

  useEffect(() => {
    if (showInvoiceModal) {
      GetInvoiceByCarId(carId).then((res) => {
        setInvoiceDetails(res.data);
        setIsReady(true);
      });
    }
  }, [showInvoiceModal]);

  const data = [
    {
      charges1: t("Car Price"),
      charges2: <>{invoiceDetails?.carPrice}€</>,
    },
    {
      charges1: t("Bank Fee"),
      charges2: <>{invoiceDetails?.bankFee}€</>,
    },
    {
      charges1: t("Transport"),
      charges2: <>{invoiceDetails?.transport}€</>,
    },
    {
      charges1: t("Auction Service Fee"),
      charges2: <>{invoiceDetails?.auctionServiceFee}€</>,
    },
    {
      charges1: t("Total Amount"),
      charges2: <>{invoiceDetails?.totalAmount}€</>,
    },
  ];

  return (
    <Modal
      className="invoice-modal"
      title={
        <div>
          <div>
            {t("INVOICE")} <span>#{invoiceDetails?.invoiceNo}</span>
          </div>
          <img
            src={webControls.isCarmax ? carmaxLogo : carmaxKsLogo}
            alt="Company Logo"
          />
        </div>
      }
      centered
      open={showInvoiceModal}
      width={600}
      closable={false}
      onCancel={() => setShowInvoiceModal(false)}
      footer={[
        <Button
          type="primary"
          ghost
          onClick={() => setShowInvoiceModal(false)}
          className="invoice-downloadbtn"
        >
          {t("Cancel")}
        </Button>,
        <Button
          type="primary"
          className="invoice-downloadbtn"
          onClick={() =>
            DownloadInvoice(invoiceDetails.id, invoiceDetails.carId)
          }
        >
          {/* <Link
            style={{ textDecoration: "none" }}
            to={`/view-invoice/${invoiceDetails?.id}`}
            target="_blank"
          > */}
          <IoMdDownload /> {t("Download")}
          {/* </Link> */}
        </Button>,
      ]}
    >
      <Preloader isReady={isReady}>
        <div className="invoice-clientinfo">
          <div className="client-contactinfo">
            <div>
              <h3>{t("Client Contact Info")} </h3>
              <p>Email: {invoiceDetails?.clientEmail}</p>
              <p>
                {t("Phone Number")}: {invoiceDetails?.clientMobileNo}
              </p>
            </div>
            <div>
              <h3>{t("Bill to")} </h3>
              <p>
                {t("Company Name")}: {invoiceDetails?.companyName}
              </p>
              <p>
                {t("Address")}: {invoiceDetails?.clientAddress}
              </p>
              <p>
                {t("City")}: {invoiceDetails?.clientCity}
              </p>
              <p>
                {t("Zip Code")}: {invoiceDetails?.clientZipCode}
              </p>
            </div>
          </div>
          <div>
            <h3>{t("Client Info")} </h3>
            <p>
              {t("Name")}: {invoiceDetails?.clientName}{" "}
              {invoiceDetails?.clientLastName}{" "}
            </p>
            <p>
              {t("Registration Number")}:{" "}
              {invoiceDetails?.clientRegistrationNumber}
            </p>
            <p>
              {t("VAT Registration Number")}:{" "}
              {invoiceDetails?.clientVATRegistrationNumber}
            </p>
          </div>
        </div>
        <div className="invoice-info">
          <Table
            className="invoice-table"
            columns={columns}
            dataSource={data}
            pagination={{
              position: ["none", "none"],
            }}
          />
        </div>
      </Preloader>
    </Modal>
  );
};

export default InvoiceDetailsModal;
