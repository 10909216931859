import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function Preloader({ children, isReady, minHeight = "30vh" }) {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: "3rem",
      }}
      spin
    />
  );

  return (
    <>
      {!isReady && (
        <div
          style={{
            minHeight: minHeight,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin
          // style={{ color: "#6a717c" }} indicator={antIcon}
          />
        </div>
      )}
      {isReady && children}
    </>
  );
}
export default Preloader;
