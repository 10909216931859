import "./SocialMedia.css";

import { TfiFacebook } from "react-icons/tfi";
import { FaTwitter } from "react-icons/fa";
import { TfiGoogle } from "react-icons/tfi";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

import UserDropDown from "./UserDropDown";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { ContactContext } from "../Context/ContactInfoContext";
import { webControls } from "../endpoints";
import { useTranslation } from "react-i18next";

function SocialMedia() {
  const isLoggedIn = useContext(AuthContext);

  const { data } = useContext(ContactContext);
  const { t } = useTranslation();

  const getTelValueByKey = (key) => {
    const keyData = data.find((item) => item.key === key);
    if (keyData) {
      return keyData.value;
    }
    return null;
  };

  return (
    <>
      <div
        className={`social-media ${
          isLoggedIn.isLoggedIn ? "social-media__position" : ""
        }`}
      >
        <div className="container">
          <div className="social-media__icons">
            <Link
              className="social-media__fb-link"
              to={
                webControls.isCarmax
                  ? "https://www.facebook.com/carmaxslovenia"
                  : "https://www.facebook.com/profile.php?id=61554240477849"
              }
              target="_blank"
            >
              <TfiFacebook />
            </Link>
            <FaTwitter />
            <TfiGoogle />
            <BsInstagram />
            <FaLinkedinIn />
          </div>
          <div
            className={`social-media__link ${
              isLoggedIn.isLoggedIn ? "isLoggedIn-gap" : ""
            }`}
          >
            {!isLoggedIn.isLoggedIn ? (
              <p>
                {t("Call us")}:{" "}
                <span className="companyNum">{getTelValueByKey("Tel")}</span>
              </p>
            ) : (
              <span className="loggedInUser">
                {isLoggedIn.data.firstName} {isLoggedIn.data.lastName}
              </span>
            )}
            {isLoggedIn.isLoggedIn && <UserDropDown />}
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialMedia;
