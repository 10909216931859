import { useState } from "react";
import UploadDocumentStep1Private from "./UploadDocumentStep1Private";
import UploadDocumentsStep2Private from "./UploadDocumentsStep2Private";

function UploadDocumentsStepsPrivate({ setStep }) {
  const [uploadDocumentStep, setUploadDocumentStep] = useState(1);

  return (
    <>
      {uploadDocumentStep === 1 && (
        <UploadDocumentStep1Private
          setUploadDocumentStep={setUploadDocumentStep}
        />
      )}
      {uploadDocumentStep === 2 && (
        <UploadDocumentsStep2Private setStep={setStep} />
      )}
    </>
  );
}

export default UploadDocumentsStepsPrivate;
