import React, { useContext, useEffect, useState } from "react";
import { BsEye, BsTrash } from "react-icons/bs";
import { GrDocumentText } from "react-icons/gr";
import { MdAddLink } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import "./UploadDocumentsStep1.css";
import Modal from "./Modal";
import Backdrop from "../../../Backdrop";
import { UploadDocumentsContext } from "../../../Context/UploadDocumentsContext";
import { useParams } from "react-router-dom";
import { DeleteUploadedDocument, UploadFile } from "../../../endpoints";
import { toast } from "react-hot-toast";
import PreviewFileModal from "./PreviewFileModal";
import { useTranslation } from "react-i18next";

function FileInput({ id, documentType }) {
  const { token } = useParams();
  const { t } = useTranslation();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const uploadCtx = useContext(UploadDocumentsContext);

  function uploadSelectedFile(file, id) {
    let formData = new FormData();

    formData.append("documentFile", file);
    formData.append("clientDocumentToken", token);
    formData.append("documentType", documentType);
    formData.append("documentId", id);

    UploadFile(formData);
  }

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body element
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling on the body element
    }
  }, [showModal]);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    const allowedSize = 10 * 1024 * 1024; // 10 MB

    if (file && allowedTypes.includes(file.type) && file.size <= allowedSize) {
      const newFile = {
        url: URL.createObjectURL(file),
        id: uuidv4(),
        name: file.name,
        type: file.type,
      };

      setSelectedFiles([...selectedFiles, newFile]);
      uploadCtx.addFile({
        name: id,
        files: [...selectedFiles, newFile],
      });

      uploadSelectedFile(file, newFile.id);
    } else {
      alert(
        t(
          "Invalid file selected. Please select a PDF, JPG, or PNG file that is 10MB or smaller."
        )
      );
    }
  };

  const handleImageDelete = (fileId) => {
    DeleteUploadedDocument(fileId).then((res) => {
      if (res.data.success) {
        const newSelectedImages = selectedFiles.filter(
          (image) => image.id !== fileId
        );

        setSelectedFiles(newSelectedImages);
        uploadCtx.addFile({
          name: id,
          files: [...newSelectedImages],
        });
      } else {
        toast.error(res.data.message, {
          duration: 2000,
          id: "errorWHiledeleting",
        });
      }
    });
  };

  const openModal = (index) => {
    setShowModal(true);
    setSelectedIndex(index);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <>
          {/* <Modal
            type={selectedFiles[selectedIndex].type}
            file={selectedFiles[selectedIndex].url}
            name={selectedFiles[selectedIndex].name}
            closeModal={closeModal}
          />

          <Backdrop onClick={closeModal} /> */}
          <PreviewFileModal
            showModal={showModal}
            closeModal={closeModal}
            type={selectedFiles[selectedIndex].type}
            file={selectedFiles[selectedIndex].url}
            name={selectedFiles[selectedIndex].name}
          />
        </>
      )}

      <div className="upload-document-button__section">
        <div className="upload-document-button__content">
          <label htmlFor={id}>
            <input
              id={id}
              name={id}
              type="file"
              accept=".pdf,.jpg,.jpeg,.png"
              onChange={(e) => {
                handleImageSelect(e);
              }}
              onClick={(event) => {
                event.currentTarget.value = null;
              }}
            />
            <MdAddLink /> {t("Choose a file...")}
          </label>
        </div>
        <div className="upload-document-button__content">
          {selectedFiles.map((image, index) => {
            return (
              <>
                <div className="upload-document-image__section" key={image.id}>
                  <div className="image-item">
                    <GrDocumentText />
                    <span>{image.name}</span>
                  </div>
                  <div className="upload-document-image__icons">
                    <BsEye
                      className="eyeIcon"
                      onClick={() => openModal(index)}
                    />
                    <BsTrash
                      className="deleteIcon"
                      onClick={() => {
                        handleImageDelete(image.id);
                      }}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default FileInput;
