import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";

import "./ForgotPassword.css";
import { ClientForgotPassword } from "../../endpoints";
import { Spin } from "antd";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
    setEmailError(
      validateEmail(event.target.value)
        ? ""
        : t("Please enter a valid email address!")
    );
  }

  function handleContinueClick() {
    if (!validateEmail(email)) {
      setEmailError(t("Please enter a valid email address!"));
    } else {
      setLoader(true);
      ClientForgotPassword(email).then((res) => {
        if (res.data.success) {
          setLoader(false);
          navigate("forgot-password-confirmation");
          setEmailError("");
        } else {
          setLoader(false);
          toast.error(res.data.message, {
            duration: 2000,
            id: "resetError",
          });
        }
      });
    }
  }
  return (
    <>
      <div className="login-section">
        <div className="container">
          <div className="login">
            <div className="login__content">
              <p className="login__content-p">{t("Forgot password")}</p>
              <span className="forgot-password__text">
                {t(
                  "To reset your password, please provide the email address associated with your account"
                )}
              </span>
              <div className="login-input__container">
                <input
                  className="login__input forgot-password__input"
                  type="text"
                  placeholder="example@gmail.com"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <p className="error-message forgot-password-error">
                    {emailError}
                  </p>
                )}
              </div>
              <div className="login-line"></div>
              <div className="forgot-password__buttons">
                <Link className="forgot-password__cancel-link" to="/login">
                  {t("Back")}
                </Link>

                <button
                  className="forgot-password__continue-link"
                  onClick={handleContinueClick}
                >
                  {!loader ? t("Continue") : <Spin />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
