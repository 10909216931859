import { useState } from "react";

import "./EnterDataStep.css";

import EnterDataStep1 from "./EnterDataStep1";
import EnterDataStep2 from "./EnterDataStep2";
import EnterDataStep3 from "./EnterDataStep3";
import { useTranslation } from "react-i18next";

function EnterDataStep({ setStep }) {
  const [enteredDataStep, setEnteredDataStep] = useState(1);
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();

  return (
    <>
      <div className="enter-data">
        <h1 className="enter-data__title">
          {t("Fill in the registration form")}
        </h1>
        <div className="enter-data__content">
          {enteredDataStep === 1 && (
            <EnterDataStep1
              setEnteredDataStep={setEnteredDataStep}
              setFormData={setFormData}
              formData={formData}
            />
          )}
          {enteredDataStep === 2 && (
            <EnterDataStep2
              setEnteredDataStep={setEnteredDataStep}
              setFormData={setFormData}
              formData={formData}
            />
          )}
          {enteredDataStep === 3 && (
            <EnterDataStep3
              setEnteredDataStep={setEnteredDataStep}
              setStep={setStep}
              setFormData={setFormData}
              formData={formData}
            />
          )}
        </div>

        <p className="enter-data__paragraph">
          {t("We protect your data in accordance with EU standards")}
        </p>
      </div>
    </>
  );
}
export default EnterDataStep;
