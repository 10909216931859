import { useTranslation } from "react-i18next";
import "./AboutUs.css";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container">
        <div className="about-us">
          <h1 className="about-us__title">{t("Who we are")}</h1>
          <span className="about-us__line"></span>
          <p className="about-us__paragraph">
            {t(
              "Welcome to our online car auction platform! We are a team of passionate car enthusiasts who believe that buying and selling cars should be a fun and enjoyable experience. With years of experience in the automotive industry, we have created a user-friendly and transparent platform where buyers and sellers can connect and make deals in a hassle-free manner"
            )}
            .
          </p>
          <p className="about-us__paragraph">
            {t(
              "Our mission is to provide a seamless car auction experience that is both convenient and secure. Whether you are looking for a reliable car for your daily commute or a rare classic car for your collection, our website offers a wide variety of vehicles to suit your needs and budget. Our team of experts thoroughly inspect each car before it is listed on our website, ensuring that you get a high-quality vehicle that meets your expectations."
            )}
          </p>
          <p className="about-us__paragraph">
            {t(
              "We take pride in our commitment to customer satisfaction. Our dedicated customer support team is available round the clock to assist you with any questions or concerns you may have. We strive to create a positive and transparent bidding process that encourages fair play and good sportsmanship. We believe that building trust and long-term relationships with our customers is key to our success."
            )}
          </p>
          <p className="about-us__paragraph">
            {t(
              "At our car auction website, you can bid on cars from the comfort of your own home, no matter where you are in the world. With our user-friendly interface and secure payment system, buying and selling cars has never been easier. So, whether you're a seasoned car collector or a first-time buyer, we invite you to explore our website and discover the thrill of the car auction."
            )}
          </p>
        </div>
      </div>
      <div className="section-line"></div>
    </>
  );
}

export default AboutUs;
