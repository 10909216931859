import React, { useEffect } from "react";
import { Select } from "antd";

import "./EnterDataStep1.css";
import { useTranslation } from "react-i18next";

function HowDidYouHearSelect({
  defaultValue,
  value,
  setHowDidYouHearSelect,
  className,
  tabIndex,
  activityName,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    setHowDidYouHearSelect(defaultValue);
  }, [defaultValue, setHowDidYouHearSelect]);

  const options = [
    {
      label: "Facebook",
      value: "Facebook",
    },
    {
      label: "Google",
      value: "Google",
    },
    {
      label: t("Our Team Member"),
      value: "Our Team Member",
    },
    {
      label: t("Other"),
      value: "Other",
    },
  ];

  return (
    <>
      <Select
        tabIndex={tabIndex}
        name={activityName}
        className={className}
        id="country"
        options={options}
        value={value}
        defaultValue={defaultValue}
        onChange={(selectedOption) => setHowDidYouHearSelect(selectedOption)}
      />
    </>
  );
}

export default HowDidYouHearSelect;
