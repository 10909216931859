import SlickSlider from "./Slider";
import ViewCurrentAuctions from "./ViewCurrentAuctions";
import WhyChooseUsSection from "./WhyChooseUsSection";
import Cover from "./Cover";
import RegisterForFree from "./RegisterForFree";
import { GetCarSoldSlider, webControls } from "../../endpoints";
import WhyChooseUsKSSection from "./WhyChooseUsKSSection";
import ViewCurrentAuctionsKS from "./ViewCurrentAuctionsKS";
import RegisterNowKs from "./RegisterNowKs";
import RegisterForFreeKs from "./RegisterForFreeKs";
import AboutAuctionKs from "./AboutAuctionKs";
import CarmaxKsHomeCarSearch from "./CarmaxKsHomeCarSearch";
import SoldCars from "./SoldCars";
import { useEffect, useState } from "react";

function Main() {
  const [soldCars, setSoldCars] = useState([]);

  useEffect(() => {
    GetCarSoldSlider(1).then((res) => {
      setSoldCars(res?.data?.soldCars);
    });
  }, []);

  return (
    <>
      <Cover />
      {/* {!webControls.isCarmax && <CarmaxKsSlider />} */}
      {!webControls.isCarmax && <CarmaxKsHomeCarSearch />}
      {webControls.isCarmax ? <SlickSlider /> : <AboutAuctionKs />}
      {!webControls.isCarmax && soldCars && <SoldCars soldCars={soldCars} />}
      {webControls.isCarmax ? (
        <ViewCurrentAuctions />
      ) : (
        <ViewCurrentAuctionsKS />
      )}
      {!webControls.isCarmax && <RegisterNowKs />}
      {webControls.isCarmax ? <WhyChooseUsSection /> : <WhyChooseUsKSSection />}
      {webControls.isCarmax ? <RegisterForFree /> : <RegisterForFreeKs />}
    </>
  );
}

export default Main;
