import { Checkbox, Col, Row, Spin } from "antd";
import YearFromToDropdown from "../CarFilterComponents/YearFromTo/YearFromTo";
import PowerFromTo from "../CarFilterComponents/PowerFromTo/PowerFromTo";
import EngineCapacity from "../CarFilterComponents/EngineCapacity/EngineCapacity";
import MileageFromTo from "../CarFilterComponents/MileageFromTo/MileageFromTo";
import FuelBodyGearbox from "../CarFilterComponents/FuelBodyGearbox/FuelBodyGearbox";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetCarBrands,
  GetFuelTypes,
  GetGreenhouseGasEmissionClass,
} from "../../../endpoints";
import Preloader from "../../Preloader/Preloader";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { FilterContext } from "../../../Context/FilterContext";

function Filter({
  showFilter,
  setShowList,
  searchValues,
  setSearchValues,
  setCurrentPage,
  status,
  isRequestBeingMade,
}) {
  const { id } = useParams();
  const { t } = useTranslation();

  const {searchValues1, setSearchValues1} = useContext(FilterContext)

  const [brands, setBrands] = useState();
  const [isReady, setIsReady] = useState(false);
  const [fuelTypes, setFuelTypes] = useState();
  const [greenhouseGasEmissionClass, setGreenhouseGasEmissionClass] =
    useState();


  useEffect(() => {
    Promise.all([
      GetCarBrands(id || "", status),
      GetFuelTypes(id || "", status),
      GetGreenhouseGasEmissionClass(id || "", status),
    ]).then((res) => {
      setBrands(res[0].data.brandsWithCounts);
      setFuelTypes(res[1].data.fuels);
      setGreenhouseGasEmissionClass(res[2].data.green);
      setIsReady(true);
    });
  }, []);

  const handleShowClick = () => {
    setShowList(true);
    setCurrentPage(1);
    setSearchValues(searchValues1);
  };

  const handleBrandCheckboxChange = (checkedValues) => {
    setSearchValues1((prevValues) => ({
      ...prevValues,
      brands: checkedValues,
    }));
  };

  return (
    <>
      <div className="container">
        <div
          style={{ width: "100%" }}
          className={`grid-control ${showFilter ? "grid-expand" : ""}`}
        >
          <Preloader isReady={isReady} minHeight={"15vh"}>
            <div className="carFilter">
              <span className="carFilter-line"></span>
              <div className="carFilter-checkbox ">
                {isReady && (
                  <div
                    className={`${
                      brands?.length > 0 && "carFilter-checkbox__content"
                    } `}
                  >
                    <Checkbox.Group
                      className="checkboxes checkbox-border"
                      onChange={handleBrandCheckboxChange}
                      value={searchValues1.brands}
                    >
                      {brands?.map((brand, index) => {
                        return (
                          <Checkbox value={brand.brand} key={index}>
                            {brand.brand} ({brand.count})
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  </div>
                )}
                <div className="carFilter-dropdowns">
                  <div className="carFilter-dropdowns__content">
                    <YearFromToDropdown
                      searchValues={searchValues1}
                      setSearchValues={setSearchValues1}
                    />
                    <PowerFromTo
                      searchValues={searchValues1}
                      setSearchValues={setSearchValues1}
                    />
                  </div>
                  <div className="carFilter-dropdowns__content">
                    <EngineCapacity
                      searchValues={searchValues1}
                      setSearchValues={setSearchValues1}
                    />
                    <MileageFromTo
                      searchValues={searchValues1}
                      setSearchValues={setSearchValues1}
                    />
                  </div>
                  <FuelBodyGearbox
                    searchValues={searchValues1}
                    setSearchValues={setSearchValues1}
                    fuelTypes={fuelTypes}
                    greenhouseGasEmissionClass={greenhouseGasEmissionClass}
                  />
                </div>
              </div>
              {!isRequestBeingMade && (
                <button className="carFilter__button" onClick={handleShowClick}>
                  {t("Show Results")}
                </button>
              )}

              {isRequestBeingMade && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <Spin
                    size="small"
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                </div>
              )}
            </div>
          </Preloader>
          <span className="carFilter-line"></span>
        </div>
      </div>
    </>
  );
}

export default Filter;
