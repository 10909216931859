import "./ActivateAccount.css";
import { HiOutlineMail } from "react-icons/hi";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../Context/AuthContext";

function ActivateAccount() {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const { data } = useContext(AuthContext);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <div className="activate-account">
        <h3>{t("Check your e-mail")}</h3>
        <p>
          {t(
            "We have sent an activation link to your email address. Confirm your registration by clicking activation link in the email. If you can not find activation email - please check spam and Ad folders."
          )}
        </p>
        <div className="activate-account__content">
          <span>
            <HiOutlineMail />
          </span>
          {/* <input
            type="email"
            name="email"
            id="email"
            value={email}
            placeholder="example@gmail.com"
            onChange={handleChange}
          />
          <Link className="resend-acc-link" to="">
            {t("Resend activation e-mail")}
          </Link> */}
          <p>{data?.email}</p>
        </div>
      </div>
    </>
  );
}

export default ActivateAccount;
