

function Backdrop2({onClick}) {
    return (
      <div className="backdrop2" onClick={onClick}>
          
      </div>
    )
  }
  
  export default Backdrop2