import { toast } from "react-hot-toast";
import { CheckIfEmailAlreadyExist } from "../../../endpoints";
import "./EnterDataStep1.css";
import "./EnterDataStep2.css";
import { AuthContext } from "../../../Context/AuthContext";

import { useContext, useReducer, useState } from "react";
import HowDidYouHearSelect from "./HowDidYouHearSelect";
import { useTranslation } from "react-i18next";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT__TYPE__TEXT": {
      const { field, payload } = action;
      const errorMessage = payload.trim() === "" ? "input-red" : "";
      return {
        ...state,
        [field]: { value: payload, error: errorMessage },
      };
    }

    default:
      return state;
  }
};

function EnterDataStep2({ setEnteredDataStep, setFormData, formData }) {
  const [howDidYouHearSelect, setHowDidYouHearSelect] = useState(
    formData.howDidYouHear
  );
  const { t } = useTranslation();
  const { setData } = useContext(AuthContext);

  const initialFormState = {
    firstName: {
      error: "",
      value: formData.firstName || "",
    },
    email: {
      error: "",
      value: formData.email || "",
    },
    telephone: {
      error: "",
      value: formData.telephone || "",
    },
    lastName: {
      error: "",
      value: formData.lastName || "",
    },
    emailRepeat: {
      error: "",
      value: formData.emailRepeat || "",
    },
    emailMatch: {
      error: "",
      value: formData.emailMatch || "",
    },

    howDidYouHear: {
      value: formData.howDidYouHear || "",
    },
  };

  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  const handleChangeText = (e) => {
    dispatch({
      type: "INPUT__TYPE__TEXT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const nextChange = async () => {
    let formIsValid = true;
    if (formState.firstName.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.firstName.name,
        error: "input-red",
        payload: formState.firstName.value,
      });
      formState.firstName.error = "input-red";
      formIsValid = false;
    } else {
      formState.firstName.error = "";
    }

    if (formState.email.value === "" || !isValidEmail(formState.email.value)) {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.email.name,
        error: "input-red",
        payload: formState.email.value,
      });
      formState.email.error = "input-red";
      formIsValid = false;
    } else {
      formState.email.error = "";
    }

    if (formState.email.value !== formState.emailRepeat.value) {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        error: t("Email-i nuk përputhet"),
        payload: formState.emailMatch.value,
      });
      formState.emailMatch.error = t("Email-i nuk përputhet");
      formIsValid = false;
    } else {
      formState.emailMatch.error = "";
    }

    if (formState.telephone.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.telephone.name,
        error: "input-red",
        payload: formState.telephone.value,
      });
      formState.telephone.error = "input-red";
      formIsValid = false;
    } else {
      formState.telephone.error = "";
    }

    if (formState.lastName.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.lastName.name,
        error: "input-red",
        payload: formState.lastName.value,
      });
      formState.lastName.error = "input-red";
      formIsValid = false;
    } else {
      formState.lastName.error = "";
    }

    if (
      formState.emailRepeat.value === "" ||
      !isValidEmail(formState.emailRepeat.value)
    ) {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.emailRepeat.name,
        error: "input-red",
        payload: formState.emailRepeat.value,
      });
      formState.emailRepeat.error = "input-red";
      formIsValid = false;
    } else {
      formState.emailRepeat.error = "";
    }

    if (formIsValid) {
      if (
        formState.email.value !== "" &&
        formState.email.value === formState.emailRepeat.value
      ) {
        await CheckIfEmailAlreadyExist(formState.email.value).then((res) => {
          if (res.data.success) {
            formIsValid = true;
            setFormData({
              ...formData,
              firstName: formState.firstName.value,
              lastName: formState.lastName.value,
              email: formState.email.value,
              emailRepeat: formState.emailRepeat.value,
              telephone: formState.telephone.value,
              howDidYouHear: howDidYouHearSelect,
            });
            setData({
              email: formState.email.value,
            });

            if (window.innerWidth < 800) {
              setEnteredDataStep((prevStep) => prevStep + 1);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            } else {
              setEnteredDataStep((prevStep) => prevStep + 1);
            }
          } else {
            setFormData({
              ...formData,
              firstName: formState.firstName.value,
              lastName: formState.lastName.value,
              email: formState.email.value,
              emailRepeat: formState.emailRepeat.value,
              telephone: formState.telephone.value,
              howDidYouHear: howDidYouHearSelect,
            });
            setData({
              email: formState.email.value,
            });

            formIsValid = false;
            toast.error(res.data.message, {
              duration: 2000,
              id: "errorMessage",
            });
          }
        });
      }
    }
  };

  const prevchangeStep = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setEnteredDataStep((prevStep) => prevStep - 1);
  };
  return (
    <>
      <div className="entered-data-step1__content">
        <p className="entered-data-step1__p">{t("Personal Data")}</p>

        {window.innerWidth <= 1200 ? (
          <form className="entered-data-form" action="">
            <div className="entered-data-form-step2__input-section">
              <div className="entered-data-form__inputs">
                <label htmlFor="firstName">
                  {t("First name")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex="1"
                  type="text"
                  id="firstName"
                  placeholder={t("First name")}
                  name="firstName"
                  value={formState.firstName.value}
                  onChange={handleChangeText}
                  className={formState.firstName.error}
                />
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="lastName">
                  {t("Last name")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex="2"
                  type="text"
                  id="lastName"
                  placeholder={t("Last name")}
                  name="lastName"
                  value={formState.lastName.value}
                  onChange={handleChangeText}
                  className={formState.lastName.error}
                />
              </div>
            </div>
            <div className="entered-data-form-step2__input-section">
              <div className="entered-data-form__inputs">
                <label htmlFor="email">
                  E-mail <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex="3"
                  type="email"
                  id="registrationNumber"
                  placeholder="E-mail"
                  name="email"
                  value={formState.email.value}
                  onChange={handleChangeText}
                  className={formState.email.error}
                />
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="emailRepeat">
                  {t("Repeat your e-mail")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <input
                    tabIndex="4"
                    type="email"
                    id="emailRepeat"
                    placeholder={t("Repeat your e-mail")}
                    name="emailRepeat"
                    value={formState.emailRepeat.value}
                    onChange={handleChangeText}
                    className={formState.emailRepeat.error}
                  />
                  <p className="error-message">{formState.emailMatch.error}</p>
                </div>
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="telephone">
                  {t("Phone Number")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  maxLength={20}
                  tabIndex="5"
                  type="tel"
                  id="telephone"
                  placeholder="+1 (123) 456-7890"
                  name="telephone"
                  value={formState.telephone.value}
                  onChange={handleChangeText}
                  className={formState.telephone.error}
                />
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="country">
                  {t("How did you hear about us?")}
                </label>
                <HowDidYouHearSelect
                  tabIndex="6"
                  defaultValue={
                    formData.howDidYouHear === undefined
                      ? t("-- Please specify? --")
                      : howDidYouHearSelect
                  }
                  value={howDidYouHearSelect}
                  setHowDidYouHearSelect={setHowDidYouHearSelect}
                  activityName={"howDidYouHear"}
                  className={
                    howDidYouHearSelect === "Is company a car trader?" ||
                    howDidYouHearSelect === 0
                      ? "input-red"
                      : ""
                  }
                />
              </div>
            </div>
          </form>
        ) : (
          <form className="entered-data-form" action="">
            <div className="entered-data-form-step2__input-section">
              <div className="entered-data-form__inputs">
                <label htmlFor="firstName">
                  {t("First name")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex="1"
                  type="text"
                  id="firstName"
                  placeholder={t("First name")}
                  name="firstName"
                  value={formState.firstName.value}
                  onChange={handleChangeText}
                  className={formState.firstName.error}
                />
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="email">
                  E-mail <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex="3"
                  type="email"
                  id="registrationNumber"
                  placeholder="E-mail"
                  name="email"
                  value={formState.email.value}
                  onChange={handleChangeText}
                  className={formState.email.error}
                />
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="telephone">
                  {t("Phone Number")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  maxLength={20}
                  tabIndex="5"
                  type="tel"
                  id="telephone"
                  placeholder="+1 (123) 456-7890"
                  name="telephone"
                  value={formState.telephone.value}
                  onChange={handleChangeText}
                  className={formState.telephone.error}
                />
              </div>
            </div>
            <div className="entered-data-form-step2__input-section">
              <div className="entered-data-form__inputs">
                <label htmlFor="lastName">
                  {t("Last name")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex="2"
                  type="text"
                  id="lastName"
                  placeholder={t("Last name")}
                  name="lastName"
                  value={formState.lastName.value}
                  onChange={handleChangeText}
                  className={formState.lastName.error}
                />
              </div>

              <div className="entered-data-form__inputs">
                <label htmlFor="emailRepeat">
                  {t("Repeat your e-mail")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <input
                    tabIndex="4"
                    type="email"
                    id="emailRepeat"
                    placeholder={t("Repeat your e-mail")}
                    name="emailRepeat"
                    value={formState.emailRepeat.value}
                    onChange={handleChangeText}
                    className={formState.emailRepeat.error}
                  />
                  <p className="error-message">{formState.emailMatch.error}</p>
                </div>
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="country">
                  {t("How did you hear about us?")}
                </label>
                <HowDidYouHearSelect
                  tabIndex="6"
                  defaultValue={
                    formData.howDidYouHear === undefined
                      ? t("-- Please specify? --")
                      : howDidYouHearSelect
                  }
                  value={howDidYouHearSelect}
                  setHowDidYouHearSelect={setHowDidYouHearSelect}
                  activityName={"howDidYouHear"}
                  className={
                    howDidYouHearSelect === "Is company a car trader?" ||
                    howDidYouHearSelect === 0
                      ? "input-red"
                      : ""
                  }
                />
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="entered-data-step1__button">
        <button className="prev-button" onClick={prevchangeStep}>
          &lt;&lt; {t("Back")}
        </button>
        <button className="next-button" onClick={nextChange}>
          {t("Next")} &gt;&gt;
        </button>
      </div>
    </>
  );
}

export default EnterDataStep2;
