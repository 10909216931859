import Register from "../Register/Register";

function RegisterPage() {
  return (
    <>
      <Register />
    </>
  );
}

export default RegisterPage;
