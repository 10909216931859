const ClientDocumentType = {
  RegistrationDocument: 1,
  IdentificationDocument: 2,
  ConfirmationDocument: 3,
  VATRegistrationDocument: 4,
  IdentificationDocumentOfAuthorizedPerson: 5,
  AttorneyToActOnBehalfDocument: 6,
  PaymentProofProfature: 7,
};

const TypeOfActivity = {
  IsCarTrader: 1,
  IsNotCarTrader: 2,
};

const OpenedClosedAuctionEnum = {
  Opened: 1,
  Closed: -1,
};

const CarAuctionEnum = {
  UnderConsideration: 1,
  ConfirmedCars: 2,
  CancelledCars: 3,
  ReceivedCars: 4,
};

const AuctionStatusEnum = {
  Opened: 1,
  inPreview: 2,
};

export {
  ClientDocumentType,
  TypeOfActivity,
  OpenedClosedAuctionEnum,
  CarAuctionEnum,
  AuctionStatusEnum,
};
