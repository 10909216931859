import React, { useContext, useEffect } from "react";
import { UploadDocumentsContext } from "../../../Context/UploadDocumentsContext";

import { BsEye } from "react-icons/bs";
import { GrDocumentText } from "react-icons/gr";
import { useState } from "react";
import Modal from "./Modal";
import Backdrop from "../../../Backdrop";
import { useTranslation } from "react-i18next";

function UploadedDocuments() {
  const { uploadedFiles } = useContext(UploadDocumentsContext);
  const [selectedFileObj, setSelectedFileObj] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body element
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling on the body element
    }
  }, [showModal]);

  const companyRegistrationDocument = uploadedFiles.find(
    (el) => el.name === "companyRegistrationDocument"
  );
  const identificationDocumentOfCompany = uploadedFiles.find(
    (el) => el.name === "identificationDocumentOfCompany"
  );
  const invoicesOfPurchasedVehicles = uploadedFiles.find(
    (el) => el.name === "invoicesOfPurchasedVehicles"
  );
  const vatDocument = uploadedFiles.find((el) => el.name === "vatDocument");
  const identityDocumentOfAuthorizedPerson = uploadedFiles.find(
    (el) => el.name === "identityDocumentOfAuthorizedPerson"
  );
  const powerOfAttorney = uploadedFiles.find(
    (el) => el.name === "powerOfAttorney"
  );
  const paymentProofProfature = uploadedFiles.find(
    (el) => el.name === "paymentProofProfature"
  );

  const openModal = (index) => {
    setShowModal(true);
  };
  const closeModal = (index) => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <>
          <Modal
            type={selectedFileObj.type}
            file={selectedFileObj.url}
            name={selectedFileObj.name}
            closeModal={closeModal}
          />

          <Backdrop onClick={closeModal} />
        </>
      )}

      <div>
        {companyRegistrationDocument &&
          companyRegistrationDocument.files.length > 0 && (
            <div className="upload-document__content">
              <p style={{ fontWeight: "bold" }}>
                {t("Company registration document")}
                <span style={{ color: "red" }}> *</span>
              </p>
              <div className="upload-document-button__content">
                {companyRegistrationDocument.files.map((image, index) => {
                  return (
                    <>
                      <div className="upload-document-image__section">
                        <div key={image.id} className="image-item">
                          <GrDocumentText />
                          <span>{image.name}</span>
                        </div>
                        <div className="upload-document-image__icons">
                          <BsEye
                            className="eyeIcon"
                            onClick={() => {
                              setSelectedFileObj({
                                type: image.type,
                                url: image.url,
                                name: image.name,
                              });
                              openModal();
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
      </div>
      <div>
        {identificationDocumentOfCompany &&
          identificationDocumentOfCompany.files.length > 0 && (
            <div className="upload-document__content">
              <p style={{ fontWeight: "bold" }}>
                {t(
                  "Identification document of company manager/owner (passport/ID card)"
                )}
                * <span style={{ color: "red" }}> *</span>
              </p>
              <div className="upload-document-button__content">
                {identificationDocumentOfCompany.files.map((image, index) => {
                  return (
                    <>
                      <div className="upload-document-image__section">
                        <div key={image.id} className="image-item">
                          <GrDocumentText />
                          <span>{image.name}</span>
                        </div>
                        <div className="upload-document-image__icons">
                          <BsEye
                            className="eyeIcon"
                            onClick={() => {
                              setSelectedFileObj({
                                type: image.type,
                                url: image.url,
                                name: image.name,
                              });
                              openModal();
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
      </div>
      <div>
        {invoicesOfPurchasedVehicles &&
          invoicesOfPurchasedVehicles.files.length > 0 && (
            <div className="upload-document__content">
              <div className="invoice__content">
                <p style={{ fontWeight: "bold" }}>
                  {t("Documents, confirming, that the company is a car trader")}
                  <span style={{ color: "red" }}> *</span>
                </p>
                <p>
                  • {t("3 invoices of purchased vehicles in the last 6 months")}
                </p>
              </div>
              <div className="upload-document-button__content">
                {invoicesOfPurchasedVehicles.files.map((image, index) => {
                  return (
                    <>
                      <div className="upload-document-image__section">
                        <div key={image.id} className="image-item">
                          <GrDocumentText />
                          <span>{image.name}</span>
                        </div>
                        <div className="upload-document-image__icons">
                          <BsEye
                            className="eyeIcon"
                            onClick={() => {
                              setSelectedFileObj({
                                type: image.type,
                                url: image.url,
                                name: image.name,
                              });
                              openModal();
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
      </div>
      <div>
        {paymentProofProfature && paymentProofProfature.files.length > 0 && (
          <div className="upload-document__content">
            <p>{t("Proof of the payment of the profature")}</p>
            <div className="upload-document-button__content">
              {paymentProofProfature.files.map((image, index) => {
                return (
                  <>
                    <div className="upload-document-image__section">
                      <div key={image.id} className="image-item">
                        <GrDocumentText />
                        <span>{image.name}</span>
                      </div>
                      <div className="upload-document-image__icons">
                        <BsEye
                          className="eyeIcon"
                          onClick={() => {
                            setSelectedFileObj({
                              type: image.type,
                              url: image.url,
                              name: image.name,
                            });
                            openModal();
                          }}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div>
        {vatDocument && vatDocument.files.length > 0 && (
          <div className="upload-document__content">
            <p>{t("VAT registration document")}</p>
            <div className="upload-document-button__content">
              {vatDocument.files.map((image, index) => {
                return (
                  <>
                    <div className="upload-document-image__section">
                      <div key={image.id} className="image-item">
                        <GrDocumentText />
                        <span>{image.name}</span>
                      </div>
                      <div className="upload-document-image__icons">
                        <BsEye
                          className="eyeIcon"
                          onClick={() => {
                            setSelectedFileObj({
                              type: image.type,
                              url: image.url,
                              name: image.name,
                            });
                            openModal();
                          }}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div>
        {identityDocumentOfAuthorizedPerson &&
          identityDocumentOfAuthorizedPerson.files.length > 0 && (
            <div className="upload-document__content">
              <p>
                {t(
                  "Identity document of authorized person (passport/ID card) The document is required if representative is not manager of the company"
                )}
              </p>
              <div className="upload-document-button__content">
                {identityDocumentOfAuthorizedPerson.files.map(
                  (image, index) => {
                    return (
                      <>
                        <div className="upload-document-image__section">
                          <div key={image.id} className="image-item">
                            <GrDocumentText />
                            <span>{image.name}</span>
                          </div>
                          <div className="upload-document-image__icons">
                            <BsEye
                              className="eyeIcon"
                              onClick={() => {
                                setSelectedFileObj({
                                  type: image.type,
                                  url: image.url,
                                  name: image.name,
                                });
                                openModal();
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            </div>
          )}
      </div>
      <div>
        {powerOfAttorney && powerOfAttorney.files.length > 0 && (
          <div className="upload-document__content">
            <p>
              {t(
                "Power of attorney to act on behalf of the company The document is required if representative is not manager of the company"
              )}
            </p>
            <div className="upload-document-button__content">
              {powerOfAttorney.files.map((image, index) => {
                return (
                  <>
                    <div className="upload-document-image__section">
                      <div key={image.id} className="image-item">
                        <GrDocumentText />
                        <span>{image.name}</span>
                      </div>
                      <div className="upload-document-image__icons">
                        <BsEye
                          className="eyeIcon"
                          onClick={() => {
                            setSelectedFileObj({
                              type: image.type,
                              url: image.url,
                              name: image.name,
                            });
                            openModal();
                          }}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UploadedDocuments;
