import React from "react";
import { BsWalletFill } from "react-icons/bs";
import { FaCar } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./AuctionListContent.css";
import { IoMdLock } from "react-icons/io";
import { OpenedClosedAuctionEnum } from "../../Enums";
import { useTranslation } from "react-i18next";
import { BiAlarmExclamation } from "react-icons/bi";
import { Badge } from "antd";

function AuctionListContent({
  carNo,
  auctionId,
  country,
  endDate,
  countryFlag,
  toNavigate = true,
  status,
  startingDate = null,
}) {
  const navigate = useNavigate();
  const [day, month, year, hours, minutes] = endDate?.split(/[\s\/:]/);
  const endDate1 = new Date(year, month - 1, day, hours, minutes);

  const { t } = useTranslation();

  const currentTime = new Date();

  const handleClick = () => {
    if (toNavigate) {
      const statusSegment =
        status === OpenedClosedAuctionEnum.Closed ? "closed" : "opened";
      navigate(`/auctions/${statusSegment}/${auctionId}`);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const renderContent = (children) => {
    if (startingDate) {
      const [day1, month1, year1, hours1, minutes1] =
        startingDate?.split(/[\s\/:]/);
      const startingDate1 = new Date(year1, month1 - 1, day1, hours1, minutes1);

      if (startingDate1 > currentTime) {
        return children;
      }
    }

    return null;
  };

  function isGuid(value) {
    var regexGuid =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regexGuid?.test(value);
  }

  return (
    <div className="container">
      <Link
        style={{ textDecoration: "none", color: "#2b2b2b" }}
        to={`/auctions/opened/${auctionId}`}
        className="auction-list__content"
        // onClick={handleClick}
      >
        <div className="car-info">
          <div>
            <div className="car-info__icon">
              <FaCar />
              <span>{carNo}</span>
            </div>
            <span className="car-info__line"></span>
            <BsWalletFill />
            <span className="car-info__line"></span>
            <p>
              {t("Auction ID")}:{" "}
              {isGuid(auctionId) ? auctionId?.substring(0, 8) : auctionId}
            </p>
          </div>
          {endDate1 < currentTime && <IoMdLock />}
          {startingDate !== null && (
            <div>
              {renderContent(
                <Badge pill color="success" className={`me-1 inPreview-badge`}>
                  {t("In Preview")}
                </Badge>
              )}
            </div>
          )}
        </div>
        <div className="place-info" style={{ justifyContent: "space-between" }}>
          <div className="place-info__icon">
            {/* <span
              className="auction-country-flag"
              dangerouslySetInnerHTML={{
                __html: countryFlag,
              }}
            ></span>{" "} */}
            <div>
              {(() => {
                if (countryFlag?.toLowerCase() == "france") {
                  return (
                    <span
                      className={`flag-icon flag-icon-fr flex-shrink-0 mr-2`}
                    ></span>
                  );
                } else if (countryFlag?.toLowerCase() == "italy") {
                  return (
                    <span
                      className={`flag-icon flag-icon-it flex-shrink-0 mr-2`}
                    ></span>
                  );
                } else if (countryFlag?.toLowerCase() == "luxembourg") {
                  return (
                    <span
                      className={`flag-icon flag-icon-lu flex-shrink-0 mr-2`}
                    ></span>
                  );
                } else {
                  return (
                    <span
                      className={`flag-icon flag-icon-${countryFlag?.toLowerCase()} flex-shrink-0 mr-2`}
                    ></span>
                  );
                }
              })()}
            </div>
            <span>{country}</span>
          </div>
          <div className="place-info__time">
            <p>
              <BiAlarmExclamation />{" "}
              {startingDate != null &&
                renderContent(
                  <span>
                    {t("STARTS ON")} {startingDate} {t("AND")}{" "}
                  </span>
                )}
              {endDate1 < currentTime ? t("ENDED ON") : t("ENDS ON")} {endDate}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default AuctionListContent;
