import { useState } from "react";
// import Select from "react-select";
import "./EngineCapacity.css";
import { Select } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";

const startNumber = 800;
const endNumber = 5000;
const step = 200;

const numbers = Array.from(
  { length: (endNumber - startNumber) / step + 1 },
  (_, index) => ({
    value: startNumber + index * step,
    label: `${startNumber + index * step}`,
  })
);

const EngineCapacity = ({ searchValues, setSearchValues }) => {
  const [engineCapacityToOptions, setEngineCapacityToOptions] =
    useState(numbers);
  const { t } = useTranslation();

  const handleChangeSearchValue = (option, field) => {
    setSearchValues((prev) => ({ ...prev, [field]: option }));
    if (field == "EngineCapacityFrom" && option) {
      setEngineCapacityToOptions(numbers?.filter((e) => e.value >= option));
    } else {
      setEngineCapacityToOptions(numbers);
    }
    if (
      searchValues?.EngineCapacityTo &&
      searchValues.EngineCapacityTo < option
    ) {
      setSearchValues((prev) => ({ ...prev, EngineCapacityTo: null }));
    }
  };

  return (
    <div className="capacitySelect-content">
      <span>
        {t("Engine capacity from, to")} (
        <span style={{ whiteSpace: "nowrap" }}>
          cm<sup style={{ fontSize: "0.6rem" }}>3</sup>
        </span>
        )
      </span>

      <div className="capacitySelect">
        <div className="capacitySelect__from">
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            value={searchValues?.EngineCapacityFrom}
            onChange={(option) =>
              handleChangeSearchValue(option, "EngineCapacityFrom")
            }
            options={numbers}
          />
        </div>
        <div className="capacitySelect__to">
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            value={searchValues?.EngineCapacityTo}
            onChange={(option) =>
              setSearchValues((prev) => ({ ...prev, EngineCapacityTo: option }))
            }
            options={engineCapacityToOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default EngineCapacity;
