import { BsBorderAll } from "react-icons/bs";
import { IoMdUnlock, IoMdLock } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
import "./AuctionNav.css";
import { useContext } from "react";
import { AuctionsContext } from "../../Context/AuctionsContext";
import { useTranslation } from "react-i18next";

function AuctionNav() {
  const { openedAuctionsNo, closedAuctionsNo } = useContext(AuctionsContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="container">
        <div className="auction-nav animation-inBottom">
          {/* <div className="auction-nav__element">
            <BsBorderAll className="auction-nav__element-icon" />
            <Link className="auction-nav__element-link" to="/auctions">
              All auctions ({allAuctionsNo})
            </Link>
          </div> */}
          <div className="auction-nav__element">
            <IoMdUnlock className="auction-nav__element-icon" />
            <NavLink
              className="auction-nav__element-link"
              to="/auctions/opened"
            >
              {t("Opened auctions")} ({openedAuctionsNo})
            </NavLink>
          </div>
          {/* <div className="auction-nav__element">
            <IoMdLock className="auction-nav__element-icon" />
            <NavLink
              className="auction-nav__element-link"
              to="/auctions/closed"
            >
              Closed auctions ({closedAuctionsNo})
            </NavLink>
          </div> */}
          {/* <div className="auction-nav__element">
            <BiEuro className="auction-nav__element-icon" />
            <Link className="auction-nav__element-link" to="">
              Buy now auctions (2)
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default AuctionNav;
