import React, { useEffect, useState } from "react";
import { CgSandClock } from "react-icons/cg";

const CountDownTimer = ({ finishingDateTime }) => {
  const auctionEndTime = new Date(finishingDateTime).getTime();
  const [timeRemaining, setTimeRemaining] = useState(
    Math.max(auctionEndTime - Date.now(), 0)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = Date.now();
      const remaining = Math.max(auctionEndTime - currentTime, 0);
      if (remaining <= 0) {
        clearInterval(timer);
      }
      setTimeRemaining(remaining);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [auctionEndTime]);

  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  const getClassByTimeUnit = () => {
    if (days !== 0) return "blue-clockdown-timer";
    if (hours !== 0) return "green-clockdown-timer";
    if (minutes !== 0) return "orange-clockdown-timer";
    if (seconds !== 0) return "red-clockdown-timer";
    return "";
  };

  return (
    <>
      <span className={`car-list-clock-down ${getClassByTimeUnit()}`}>
        <span
          style={{
            display:
              days == 0 && hours == 0 && minutes == 0 && seconds == 0
                ? "none"
                : "",
          }}
        >
          <CgSandClock />
          {days > 0 ? `${days}d ` : ""}
          {hours > 0 ? `${hours}h ` : ""}
          {days === 0 && minutes > 0 ? `${minutes}m ` : ""}
          {days === 0 && hours === 0 && seconds > 0 ? `${seconds}s` : ""}
        </span>
      </span>
    </>
  );
};

export default CountDownTimer;
