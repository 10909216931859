import "./UploadDocumentsStep1.css";

import FileInput from "./FileInput";
import { useContext, useState } from "react";
import { ClientDocumentType } from "../../../Enums";
import { UploadDocumentsContext } from "../../../Context/UploadDocumentsContext";
import { useTranslation } from "react-i18next";
import { ConfirmDocuments } from "../../../endpoints";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

function UploadDocumentStep1Private({ setUploadDocumentStep }) {
  const uploadCtx = useContext(UploadDocumentsContext);
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");

  const { token } = useParams();

  function handleFormSubmit(e) {
    e.preventDefault();

    const identificationDocumentOfCompany = uploadCtx.uploadedFiles.find(
      (el) => el.name === "identificationDocumentOfCompany"
    );
    if (!identificationDocumentOfCompany?.files.length > 0) {
      setErrorMessage("Please upload all required documents.");
      return;
    }

    ConfirmDocuments(token).then((res) => {
      if (res.data.success) {
        setUploadDocumentStep(2);
      } else {
        toast.error(res.data.message, {
          duration: 3000,
          id: "bidupdatefailed",
        });
      }
    });
  }

  return (
    <>
      <form className="upload-document" onSubmit={handleFormSubmit}>
        <h3 className="upload-document__title">{t("Upload documents")}</h3>
        <p className="upload-document__paragraph1">
          {t(
            "Document formats: PDF, JPG, PNG. The maximum document size -10MB."
          )}
        </p>
        <div className="upload-document-required__section">
          <p className="upload-document__paragraph2">
            <span style={{ color: "red" }}> *</span> {t("Required documents")}
          </p>
          <p style={{ color: "red" }}>{errorMessage}</p>
        </div>
        <span className="upload-document-line"></span>
        <div className="upload-document__content-section">
          <div className="upload-document__content">
            <p style={{ fontWeight: "bold" }}>
              Dokument identifikimi (pasaportë/kartë identiteti)
              <span style={{ color: "red" }}> *</span>
            </p>
            <FileInput
              id={"identificationDocumentOfCompany"}
              documentType={ClientDocumentType.IdentificationDocument}
            />
          </div>
          <span className="upload-document-line"></span>
          <div className="upload-document__content">
            <p style={{ fontWeight: "bold" }}>
              Deshmia e pageses se profatures
            </p>
            <FileInput
              id={"paymentProofProfature"}
              documentType={ClientDocumentType.PaymentProofProfature}
            />
          </div>
          <span className="upload-document-line"></span>
        </div>
        <button className="upload-document__btn">
          {t("Upload documents")} &gt;
        </button>
      </form>
    </>
  );
}

export default UploadDocumentStep1Private;
