import { useTranslation } from "react-i18next";
import "./ForgotPasswordConfirmation.css";

function ForgotPasswordConfirmation() {
  const { t } = useTranslation();
  return (
    <>
      <div className="login-section">
        <div className="container">
          <div className="login">
            <div className="login__content forgot-password-confirmation">
              <p className="login__content-p">
                {t("Forgot Password Confirmation")}
              </p>

              <span className="forgot-password__text">
                {t("Please check your email to reset your password.")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordConfirmation;
