import React, { createContext, useState } from "react";

export const FilterContext = createContext({
  searchValues1: "",
  showList: "",
  setSearchValues1: () => {},
  setShowList: () => {},
});

export const FilterContextProvider = ({ children }) => {
  const [searchValues1, setSearchValues1] = useState({
    brands: [],
    yearFrom: null,
    yearTo: null,
    PowerFrom: null,
    PowerTo: null,
    EngineCapacityFrom: null,
    EngineCapacityTo: null,
    MileageFrom: null,
    MileageTo: null,
    Fuel: null,
    Gearbox: null,
    EuroStandard: null,
    registrationNumber: null,
  });
  const [showList, setShowList] = useState(false);


  return (
    <FilterContext.Provider
      value={{
        searchValues1,
        showList,
        setSearchValues1,
        setShowList,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
