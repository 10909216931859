import { useTranslation } from "react-i18next";
import { TbError404 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <>
      <div className="page-not-found__error">
        <p className="page-not-found__text">
          <TbError404 className="icon-404" /> {t("Page Not Found!")}
        </p>
        <button className="page-not-found__button" onClick={handleNavigate}>
          {t("Back to the Home Page")}{" "}
        </button>
      </div>
    </>
  );
}

export default ErrorPage;
