import { Button, Modal } from "antd";
import React, { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ExpiredTokenModal = () => {
  const { setIsLoggedIn, showExpirySessionModal, setShowExpirySessionModal } =
    useContext(AuthContext);
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Modal
      className="expiredtoken-modal"
      title={t("Session Expired")}
      centered
      open={showExpirySessionModal}
      width={400}
      closable={false}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            setShowExpirySessionModal(false);
            setIsLoggedIn(false);
            navigate("/login");
          }}
        >
          OK
        </Button>,
      ]}
    >
      <p style={{ marginBottom: "0.5rem" }}>{t("Your session has expired.")}</p>
      <p style={{ borderBottom: "1px solid #80808021", paddingBottom: "1rem" }}>
        {t("Please login again!")}
      </p>
    </Modal>
  );
};

export default ExpiredTokenModal;
