import group34 from "../../assets/images/Group34-icon-ks.png";
import group35 from "../../assets/images/Group35-icon-ks.png";
import group36 from "../../assets/images/Group36-icon-ks.png";
import group37 from "../../assets/images/Group37-icon-ks.png";

import "./AboutAuctionKs.css";

const AboutAuctionKs = () => {
  return (
    <>
      <div style={{ background: "#014175" }}>
        <section className="container about-action-ks-container">
          <div className="about-auction-ks">
            <span>
              <img src={group34} alt="" />
            </span>
            <h3>Perzgjedhje e gjerë</h3>
            <p>
              Mijëra vetura të ndryshme çdo javë. Vetura të përdorura nga
              egjithë Europa. Vetura dhe automjete komerciale. Vetura në
              gjendjeë rregullt, pas aksidenteve, me kilometrazh të ulët dhe të
              lartë.
            </p>
          </div>
          <div className="about-auction-ks">
            <span>
              <img src={group35} alt="" />
            </span>
            <h3>Garancia e çmimit më të ulët</h3>
            <p>
              Pa tarifa shtesë - shitje të dorës së parë. Çmimi i qartë - nuk ka
              surpriza. Ju paguani vetëm për veturën, transportin e saj dhe
              përgatitjen e dokumenteve.
            </p>
          </div>
          <div className="about-auction-ks">
            <span>
              <img src={group36} alt="" />
            </span>
            <h3>Ankandet e veturave online</h3>
            <p>
              Regjistrim i shpejtë dhe falas. Kërkim i përshtatshëm i veturave.
              Mjete të përshtatshme dhe intuitive për përdorimin e platformës.
              Ankandet e veturave në dispozicion online 24 orë në ditë.
            </p>
          </div>
          <div className="about-auction-ks">
            <span>
              <img src={group37} alt="" />
            </span>
            <h3>Shërbime logjistike</h3>
            <p>
              Transportues të besueshëm. Afate rigoroze. Derë më derë.
              Logjistika e dokumenteve. Monitorimi i procesit të transportit.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutAuctionKs;
