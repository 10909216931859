import { useTranslation } from "react-i18next";
import "./UploadDocumentsStep2.css";

import { GrNext } from "react-icons/gr";

import { useNavigate } from "react-router-dom";
import UploadedPrivateDocuments from "./UploadedPrivateDocuments";

function UploadDocumentsStep2Private({ setStep }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickNavigate = () => {
    navigate("/auctions");
  };

  const nextChange = (e) => {
    e.preventDefault();
    setStep(4);
  };

  return (
    <>
      <form className="upload-document">
        <h3 className="upload-document__title">
          {t("Documents uploaded successfully")}
        </h3>
        <p className="upload-document__paragraph1">
          {t(
            "Document verification is usually done in 1 working day. After checking the documents we will contact you via email. While we are checking your uploaded documents find the most suitable car for you."
          )}
        </p>
        <button
          className="uploaded-document__button"
          onClick={handleClickNavigate}
        >
          {t("Auction List")} <GrNext className="uploaded-next-icon" />
        </button>
        <span className="upload-document-line"></span>
        <UploadedPrivateDocuments />
        <div className="entered-data-step1__button">
          <button className="next-button" onClick={nextChange}>
            {t("Next")} &gt;&gt;
          </button>
        </div>
      </form>
    </>
  );
}

export default UploadDocumentsStep2Private;
