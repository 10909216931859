import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { ClientChangePassword } from "../../endpoints";
import { AuthContext } from "../../Context/AuthContext";
import Protector from "../Protector/Protector";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ChangePassword() {
  const data = useContext(AuthContext);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dataForm, setDataForm] = useState({
    currentPassword: "",
    password: "",
    confirmNewPassword: "",
  });

  const [currentPasswordIsValid, setCurrentPasswordIsValid] = useState({
    valid: true,
  });

  const [newPasswordIsValid, setNewPasswordIsValid] = useState({
    valid: true,
  });

  const [confirmNewPasswordIsValid, setConfirmNewPasswordIsValid] = useState({
    valid: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "currentPassword") {
      setCurrentPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: t("Current password cannot be empty"),
      }));
    }

    if (name === "currentPassword") {
      setCurrentPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: !(value.trim().length < 6),
        message: t("Current password must be at least 6 characters"),
      }));
    }

    if (name === "password") {
      setNewPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: t("Please enter a valid password"),
      }));
    }

    if (name === "password") {
      setNewPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: !(value.trim().length < 6),
        message: t("Password must be at least 6 characters"),
      }));
    }

    if (name === "confirmNewPassword") {
      setConfirmNewPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: t("Please enter a valid confirm password"),
      }));

      setConfirmNewPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: dataForm.password === value,
        message: t("Password do not match"),
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (dataForm.currentPassword.trim().length === 0) {
      setCurrentPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Current password cannot be empty"),
        };
      });
    } else if (dataForm.currentPassword.trim().length < 6) {
      setCurrentPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Current password must be at least 6 characters"),
        };
      });
    } else {
      setCurrentPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (dataForm.password.trim().length === 0) {
      setNewPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Please enter a valid password"),
        };
      });
    } else if (dataForm.password.trim().length < 6) {
      setNewPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Password must be at least 6 characters"),
        };
      });
    } else {
      setNewPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (dataForm.confirmNewPassword.trim().length === 0) {
      setConfirmNewPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Please enter a valid confirm password"),
        };
      });
    } else if (dataForm.confirmNewPassword !== dataForm.password) {
      setConfirmNewPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Password do not match"),
        };
      });
    } else {
      setConfirmNewPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }
    if (
      dataForm.currentPassword.trim() === "" ||
      dataForm.password.trim() === "" ||
      dataForm.confirmNewPassword.trim() === ""
    ) {
      toast.error(t("Please fill in all the required fields!"), {
        id: "requiredFields",
      });
    } else if (
      dataForm.currentPassword.trim().length < 6 ||
      dataForm.password.trim().length < 6
    ) {
      toast.error(t("Passwords must be at least 6 characters!"), {
        id: "passwordAtLeast6Char",
      });
    } else if (dataForm.password !== dataForm.confirmNewPassword) {
      toast.error(t("Passwords do not match!"), {
        id: "passwordDoNotMatch",
      });
    } else {
      ClientChangePassword({
        id: data.data.id,
        currentPassword: dataForm.currentPassword,
        password: dataForm.password,
        confirmNewPassword: dataForm.confirmNewPassword,
      }).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            id: "passwordChangedSuccessfuly",
          });
          navigate("/profile");
        } else {
          toast.error(res.data.message, {
            duration: 2000,
            id: "wrongPassword",
          });
        }
      });
    }
  };

  return (
    <>
      <Protector
        isLoggedIn={data.isLoggedIn}
        error={
          <div className="my-bids-error">
            {t("You don't have access to this path!")}
          </div>
        }
      >
        <div className="login-section">
          <div className="container">
            <div className="login">
              <form className="login__content" onSubmit={handleSubmit}>
                <p className="login__content-p">{t("Change Password")}</p>
                <div className="login-input__container">
                  <input
                    className="login__input"
                    type="password"
                    placeholder={t("Current password")}
                    value={dataForm.currentPassword}
                    onChange={handleChange}
                    name="currentPassword"
                    autoComplete="current-password"
                  />
                  {!currentPasswordIsValid.valid && (
                    <p className="error-message">
                      {currentPasswordIsValid.message}
                    </p>
                  )}
                </div>
                <div className="login-input__container">
                  <input
                    className="login__input"
                    type="password"
                    placeholder={t("New password")}
                    value={dataForm.password}
                    onChange={handleChange}
                    name="password"
                    autoComplete="new-password"
                  />
                  {!newPasswordIsValid.valid && (
                    <p className="error-message">
                      {newPasswordIsValid.message}
                    </p>
                  )}
                </div>
                <div className="login-input__container">
                  <input
                    className="login__input"
                    type="password"
                    placeholder={t("Confirm new password")}
                    value={dataForm.confirmNewPassword}
                    onChange={handleChange}
                    name="confirmNewPassword"
                    autoComplete="new-password"
                  />
                  {!confirmNewPasswordIsValid.valid && (
                    <p className="error-message">
                      {confirmNewPasswordIsValid.message}
                    </p>
                  )}
                </div>
                <div className="login-line"></div>
                <button className="reset-password__link">{t("Submit")}</button>
              </form>
            </div>
          </div>
        </div>
      </Protector>
    </>
  );
}

export default ChangePassword;
