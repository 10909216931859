import "./EnterDataStep1.css";
import "./EnterDataStep3.css";

import { useState } from "react";
import { Button, Modal, Spin } from "antd";
import { BiRefresh } from "react-icons/bi";

import { LoadingOutlined } from "@ant-design/icons";

import randomstring from "randomstring";
import { Link } from "react-router-dom";
import { UploadData } from "../../../endpoints";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

function EnterDataStep3({
  setStep,
  setEnteredDataStep,
  setFormData,
  formData,
}) {
  const [code, setCode] = useState(generateConfirmationCode());

  const [dataForm, setDataForm] = useState({
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });

  const [error, setError] = useState("");

  function handleGenerateCode() {
    setCode(generateConfirmationCode());
  }

  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [checkboxErr, setCheckboxErr] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const [preLoader, setPreLoader] = useState(false);
  const { t } = useTranslation();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const [passwordIsValid, setPasswordIsValid] = useState({
    valid: true,
  });

  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState({
    valid: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "password") {
      setPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: t("Please enter a valid password."),
      }));
    }

    if (name === "password") {
      setPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: !(value.trim().length < 6),
        message: t("Password must be at least 6 characters"),
      }));
    }

    if (name === "confirmPassword") {
      setConfirmPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: t("Please enter a valid confirm password"),
      }));

      setConfirmPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: dataForm.password === value,
        message: t("Password do not match"),
      }));
    }
  };

  function generateConfirmationCode() {
    return randomstring.generate({
      length: 4,
      charset: "alphanumeric",
      capitalization: "uppercase",
    });
  }

  const nextStep = () => {
    let formIsValid = true;
    if (dataForm.password.trim().length === 0) {
      setError("input-red");
      setPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Please enter a valid password"),
        };
      });
      formIsValid = false;
    } else if (dataForm.password.trim().length < 6) {
      setPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Password must be at least 6 characters"),
        };
      });
      formIsValid = false;
    } else {
      setPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (dataForm.confirmPassword.trim().length === 0) {
      setError("input-red");
      setConfirmPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Please enter a valid confirm password"),
        };
      });
      formIsValid = false;
    } else if (dataForm.confirmPassword !== dataForm.password) {
      setConfirmPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Password do not match"),
        };
      });
      formIsValid = false;
    } else {
      setConfirmPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (!isChecked || !isChecked2) {
      formIsValid = false;
      setCheckboxErr("text-error");
    } else {
      setCheckboxErr("");
    }

    if (
      dataForm.confirmationCode === "" ||
      dataForm.confirmationCode !== code
    ) {
      formIsValid = false;
      setError("input-red");
    } else {
      setError("");
    }
    if (formIsValid) {
      setFormData({
        ...formData,
        password: dataForm.password,
        confirmPassword: dataForm.confirmPassword,
      });

      setPreLoader(true);
      UploadData({
        ...formData,
        password: dataForm.password,
        confirmPassword: dataForm.confirmPassword,
        name:
          formData.radioValue === 1
            ? formData.name
            : formData.firstName + "" + formData.lastName,
        clientType: formData.radioValue ? formData.radioValue : 1,
      })
        .then((response) => {
          if (response.data.success) {
            setStep(2);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          } else {
            toast.error(t("Registration failed. Please try again."), {
              duration: 3000,
              id: "bidupdatefailed",
            });
          }
        })
        .finally(() => {
          setPreLoader(false);
        });
    }
  };

  const prevchangeStep = () => {
    if (formData.radioValue === 1) {
      setEnteredDataStep((prevStep) => prevStep - 1);
    } else {
      setEnteredDataStep((prevStep) => prevStep - 2);
    }
  };

  const showModal = () => {
    if (!isChecked) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
      setIsChecked(false);
    }
  };

  const showModal2 = () => {
    if (!isChecked2) {
      setIsModalOpen2(true);
    } else {
      setIsModalOpen2(false);
      setIsChecked2(false);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsChecked(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
    setIsChecked2(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  return (
    <>
      <div className="entered-data-step1__content">
        <p className="entered-data-step1__p">
          {t("Choose your password for login")}
        </p>

        <form className="entered-data-form-step3" action="">
          <div className="entered-data-form__inputs entered-data-form__inputs--width">
            <label className="label-password-width" htmlFor="password">
              {t("Password")}{" "}
              <span className="required">{t("(required)")}</span>
            </label>
            <input
              type="password"
              id="password"
              placeholder={t("Password")}
              name="password"
              onChange={handleChange}
              className={dataForm.password === "" && error}
              value={dataForm.password}
            />
            {!passwordIsValid.valid && (
              <p className="error-message">{passwordIsValid.message}</p>
            )}
          </div>
          <div className="entered-data-form__inputs entered-data-form__inputs--width">
            <label className="label-password-width" htmlFor="tel">
              {t("Confirm password")}{" "}
              <span className="required">{t("(required)")}</span>
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder={t("Confirm password")}
              onChange={handleChange}
              className={dataForm.confirmPassword === "" && error}
              value={dataForm.confirmPassword}
            />
            {!confirmPasswordIsValid.valid && (
              <p className="error-message">{confirmPasswordIsValid.message}</p>
            )}
          </div>
          <div className="entered-data-form__checkboxes">
            <div className="checkbox">
              <input
                type="checkbox"
                id="termOfUse"
                name="termOfUse"
                checked={isChecked}
                onClick={showModal}
                defaultChecked={isChecked}
                style={{ cursor: "pointer" }}
              />
              <label
                htmlFor="termOfUse"
                className={!isChecked && checkboxErr}
                style={{ cursor: "pointer" }}
              >
                <span className="red"> *</span>{" "}
                {t("I have read and agree to the Term of Use")}
              </label>
              <Modal
                title={t("General Terms and Conditions of CARMAX")}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered={true}
                footer={null}
              >
                <div className="modal-content">
                  <p>
                    {t(
                      "Welcome to CARMAX! These Terms of Use govern your use of our website, products, and services. Please read these Terms of Use carefully before accessing or using our platform."
                    )}
                  </p>
                  <div className="modal-content__rules">
                    <span>1. {t("Acceptance of Terms")}</span>
                    <p>
                      {t(
                        "By accessing or using our platform, you agree to be bound by these Terms of Use and any additional terms and conditions that may apply to specific sections of our platform or services. If you do not agree with these terms, please do not use our platform."
                      )}
                    </p>
                  </div>
                  <div className="modal-content__rules">
                    <span>2. {t("Use of Our Platform")}</span>
                    <p>
                      a.{" "}
                      {t(
                        "Eligibility: You must be at least 18 years old to access or use our platform. By using our platform, you represent and warrant that you are of legal age to form a binding contract. b. User Account: To access certain features of our platform, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. c. Prohibited Activities: You agree not to engage in any prohibited activities, including but not limited to: violating any applicable laws, infringing intellectual property rights, interfering with the functionality of our platform, or transmitting malicious code."
                      )}
                    </p>
                  </div>
                  <div className="modal-content__rules">
                    <span>3. {t("Intellectual Property")}</span>
                    <p>
                      a.{" "}
                      {t(
                        "Ownership: All intellectual property rights in our platform, including but not limited to text, graphics, logos, and software, are owned by or licensed to us. b. Limited License: We grant you a limited, non-exclusive, non-transferable license to access and use our platform for personal and non-commercial purposes. You may not copy, modify, distribute, or create derivative works based on our platform without our prior written consent."
                      )}
                    </p>
                  </div>
                  <div className="modal-content__rules">
                    <span>4. {t("User Content")}</span>
                    <p>
                      a.{" "}
                      {t(
                        "User Responsibility: You are solely responsible for any content you submit or contribute to our platform. By submitting content, you grant us a worldwide, royalty-free, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, and display such content. b. Prohibited Content: You agree not to submit any content that is unlawful, defamatory, infringing, obscene, or otherwise objectionable. c. Monitoring and Removal: We reserve the right to monitor user content and remove any content that violates these Terms of Use or is otherwise inappropriate."
                      )}
                    </p>
                  </div>
                  <Button className="modal-button" onClick={handleOk}>
                    {t("I have read and agree to the Terms of Use")}
                  </Button>
                </div>
              </Modal>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="privacyPolicy"
                name="privacyPolicy"
                checked={isChecked2}
                defaultChecked={isChecked2}
                onClick={showModal2}
                style={{ cursor: "pointer" }}
              />
              <label
                htmlFor="privacyPolicy"
                style={{ cursor: "pointer" }}
                className={!isChecked2 && checkboxErr}
              >
                <span className="red"> *</span>{" "}
                {t("I have read and agree to the Privacy Policy")}
              </label>
              <Modal
                title="Privacy Policy of CARMAX"
                open={isModalOpen2}
                onOk={handleOk2}
                onCancel={handleCancel2}
                centered={true}
                footer={null}
              >
                <div className="modal-content">
                  <p>
                    {t(
                      "Welcome to CARMAX! These Terms of Use govern your use of our website, products, and services. Please read these Terms of Use carefully before accessing or using our platform."
                    )}
                  </p>
                  <div className="modal-content__rules">
                    <span>1. {t("Acceptance of Terms")}</span>
                    <p>
                      {t(
                        "By accessing or using our platform, you agree to be bound by these Terms of Use and any additional terms and conditions that may apply to specific sections of our platform or services. If you do not agree with these terms, please do not use our platform."
                      )}
                    </p>
                  </div>
                  <div className="modal-content__rules">
                    <span>2. {t("Use of Our Platform")}</span>
                    <p>
                      a.{" "}
                      {t(
                        "Eligibility: You must be at least 18 years old to access or use our platform. By using our platform, you represent and warrant that you are of legal age to form a binding contract. b. User Account: To access certain features of our platform, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. c. Prohibited Activities: You agree not to engage in any prohibited activities, including but not limited to: violating any applicable laws, infringing intellectual property rights, interfering with the functionality of our platform, or transmitting malicious code."
                      )}
                    </p>
                  </div>
                  <div className="modal-content__rules">
                    <span>3. {t("Intellectual Property")}</span>
                    <p>
                      a.{" "}
                      {t(
                        "Ownership: All intellectual property rights in our platform, including but not limited to text, graphics, logos, and software, are owned by or licensed to us. b. Limited License: We grant you a limited, non-exclusive, non-transferable license to access and use our platform for personal and non-commercial purposes. You may not copy, modify, distribute, or create derivative works based on our platform without our prior written consent."
                      )}
                    </p>
                  </div>
                  <div className="modal-content__rules">
                    <span>4. {t("User Content")}</span>
                    <p>
                      a.{" "}
                      {t(
                        "User Responsibility: You are solely responsible for any content you submit or contribute to our platform. By submitting content, you grant us a worldwide, royalty-free, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, and display such content. b. Prohibited Content: You agree not to submit any content that is unlawful, defamatory, infringing, obscene, or otherwise objectionable. c. Monitoring and Removal: We reserve the right to monitor user content and remove any content that violates these Terms of Use or is otherwise inappropriate."
                      )}
                    </p>
                  </div>
                  <Button className="modal-button" onClick={handleOk2}>
                    {t("I have read and agree to the Privacy Policy")}
                  </Button>
                </div>
              </Modal>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="receive"
                name="receive"
                value="Bike"
                style={{ cursor: "pointer" }}
              />
              <label htmlFor="receive" style={{ cursor: "pointer" }}>
                {t("I want to receive news from CARMAX.")}
              </label>
            </div>
          </div>
          <div className="enter-data-step3__confirmation-code entered-data-form__inputs entered-data-form__inputs--width">
            <p>
              {t("Confirmation code")} <span className="red"> *</span>
            </p>
            <div className="enter-data-step3__code">{code}</div>
            <Link
              className="enter-data-step3__link"
              to=""
              onClick={handleGenerateCode}
            >
              <BiRefresh className="enter-data-step3__icon" />{" "}
              {t("Generate new code")}
            </Link>
            <input
              type="text"
              placeholder={t("Enter confirmation code")}
              name="confirmationCode"
              value={dataForm.confirmationCode}
              className={
                dataForm.confirmationCode === "" ||
                dataForm.confirmationCode !== code
                  ? error
                  : ""
              }
              onChange={handleChange}
            />
          </div>
        </form>
      </div>

      <div className="entered-data-step1__button">
        <button className="prev-button" onClick={prevchangeStep}>
          &lt;&lt; {t("Back")}
        </button>
        <button className="next-button" onClick={nextStep} disabled={preLoader}>
          {preLoader ? <Spin indicator={antIcon} /> : t("Register")}
        </button>
      </div>
    </>
  );
}

export default EnterDataStep3;
