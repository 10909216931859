import { Link } from "react-router-dom";
import "./RegisterNow.css";

import { BsPersonPlus } from "react-icons/bs";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { toast } from "react-hot-toast";
import { RxInfoCircled } from "react-icons/rx";
import { useTranslation } from "react-i18next";

function RegisterNow() {
  const isLoggedIn = useContext(AuthContext);
  const { t } = useTranslation();

  const handleScrollClick = () => {
    if (isLoggedIn.isLoggedIn) {
      toast(
        (y) => (
          <span className="toast-info">
            <RxInfoCircled className="info-icon" />
            {t("You are already Logged In")}!
          </span>
        ),
        {
          id: "alreadyLoggedIn",
        }
      );
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="register-now">
        <BsPersonPlus className="register-now__icon" />
        <h3 className="register-now__title">{t("Ready to get started?")}</h3>
        <p className="register-now__paragraph">
          {t(
            "Register a free user account and start bidding on your dream car right now!"
          )}
        </p>
        <Link
          to={!isLoggedIn.isLoggedIn ? "../registration/0" : ""}
          className="register-now__btn"
          onClick={handleScrollClick}
        >
          {t("Register now")}
        </Link>
      </div>
    </>
  );
}

export default RegisterNow;
