import React, { useEffect } from "react";
import { Select } from "antd";

import "./EnterDataStep1.css";
import { TypeOfActivity } from "../../../Enums";
import { useTranslation } from "react-i18next";

function TypeOfActivitySelect({
  defaultValue,
  value,
  setSelectedActivity,
  className,
  tabIndex,
  activityName,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedActivity(defaultValue);
  }, [defaultValue, setSelectedActivity]);

  const options = [
    {
      label: t("Company is a car trader"),
      value: TypeOfActivity.IsCarTrader,
    },
    {
      label: t("Company is not a car trader"),
      value: TypeOfActivity.IsNotCarTrader,
    },
  ];

  return (
    <>
      <Select
        tabIndex={tabIndex}
        name={activityName}
        className={className}
        id="country"
        options={options}
        value={value}
        defaultValue={defaultValue}
        onChange={(selectedOption) => setSelectedActivity(selectedOption)}
      />
    </>
  );
}

export default TypeOfActivitySelect;
