import MyBidsNav from "./MyBidNav/MyBidsNav";

function MyBids() {
  return (
    <>
      <MyBidsNav />
    </>
  );
}

export default MyBids;
