import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { CgClose } from "react-icons/cg";
import "./GalleryCarousel.css";

const DamagesCarousel = ({ sliderImages, showSlider, setShowSlider }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [indexBoard, setIndexBoard] = useState(false);
  const [index, setIndex] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    document.documentElement.style.setProperty("--carousel-z-index", 99999);
    carouselRef.current.maximize();
    setIsMinimized(true);
  }, [carouselIndex]);

  useEffect(() => {
    const carouselGalleryElements =
      document.getElementsByClassName("_1_Dg2 _3q7r8");
    for (let i = 0; i < carouselGalleryElements.length; i++) {
      carouselGalleryElements[i].classList.add("carousel-gallery");
    }
  }, [carouselIndex]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        carouselRef.current.minimize();
        setIsMinimized(false);
        setCarouselIndex(99);
        setShowSlider(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isMinimized]);

  const onIndexChangeHandler = ({ curIndex, curIndexForDisplay }) => {
    setIndex(curIndex);
  };

  const carouselRef = useRef(null);

  const images = sliderImages?.map((url) => {
    return {
      src: url,
    };
  });

  const handleMinimizeClick = () => {
    carouselRef.current.minimize();
    setIsMinimized(false);
    setCarouselIndex(99);
    setShowSlider(false);
  };

  const handleMaximizeClick = () => {
    setIsMinimized(true);
    setIndexBoard(true);
    carouselRef.current.maximize();
    setCarouselIndex(99999);
  };

  const carouselStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    width: showSlider ? "100%" : "0",
    height: showSlider ? "100vh" : "0",
    overflow: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    transition: "0.3s",
    zIndex: showSlider ? "99999" : "0",
    display: "flex",
    alignItems: "center",
  };

  return (
    <div style={carouselStyle}>
      <Carousel
        ref={carouselRef}
        images={images}
        index={index}
        className="galleryCarousel"
        hasMediaButton={false}
        hasSizeButton={false}
        onTap={handleMaximizeClick}
        transitionSpeed={6}
        hasLeftButton={isMinimized ? "centerLeft" : false}
        hasRightButton={isMinimized ? "centerRight" : false}
        hasIndexBoard={isMinimized ? "bottomCenter" : false}
        onIndexChange={onIndexChangeHandler}
      />
      {isMinimized ? (
        <button
          className="gallery-carousel__button"
          onClick={handleMinimizeClick}
        >
          <CgClose />
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default DamagesCarousel;
