import { useState } from "react";
import UploadDocumentsStep1 from "./UploadDocumentsStep1";
import UploadDocumentsStep2 from "./UploadDocumentsStep2";

function UploadDocumentsSteps({ setStep }) {
  const [uploadDocumentStep, setUploadDocumentStep] = useState(1);

  return (
    <>
      {uploadDocumentStep === 1 && (
        <UploadDocumentsStep1 setUploadDocumentStep={setUploadDocumentStep} />
      )}
      {uploadDocumentStep === 2 && <UploadDocumentsStep2 setStep={setStep} />}
    </>
  );
}

export default UploadDocumentsSteps;
