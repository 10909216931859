import React, { useContext, useEffect } from "react";
import { UploadDocumentsContext } from "../../../Context/UploadDocumentsContext";

import { BsEye } from "react-icons/bs";
import { GrDocumentText } from "react-icons/gr";
import { useState } from "react";
import Modal from "./Modal";
import Backdrop from "../../../Backdrop";
import { useTranslation } from "react-i18next";

function UploadedPrivateDocuments() {
  const { uploadedFiles } = useContext(UploadDocumentsContext);
  const [selectedFileObj, setSelectedFileObj] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body element
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling on the body element
    }
  }, [showModal]);

  const paymentProofProfature = uploadedFiles.find(
    (el) => el.name === "paymentProofProfature"
  );
  const identificationDocumentOfCompany = uploadedFiles.find(
    (el) => el.name === "identificationDocumentOfCompany"
  );

  const openModal = (index) => {
    setShowModal(true);
  };
  const closeModal = (index) => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <>
          <Modal
            type={selectedFileObj.type}
            file={selectedFileObj.url}
            name={selectedFileObj.name}
            closeModal={closeModal}
          />

          <Backdrop onClick={closeModal} />
        </>
      )}

      <div>
        {identificationDocumentOfCompany &&
          identificationDocumentOfCompany.files.length > 0 && (
            <div className="upload-document__content">
              <p style={{ fontWeight: "bold" }}>
                Dokument identifikimi (pasaportë/kartë identiteti) 
                <span style={{ color: "red" }}> *</span>

              </p>
              <div className="upload-document-button__content">
                {identificationDocumentOfCompany.files.map((image, index) => {
                  return (
                    <>
                      <div className="upload-document-image__section">
                        <div key={image.id} className="image-item">
                          <GrDocumentText />
                          <span>{image.name}</span>
                        </div>
                        <div className="upload-document-image__icons">
                          <BsEye
                            className="eyeIcon"
                            onClick={() => {
                              setSelectedFileObj({
                                type: image.type,
                                url: image.url,
                                name: image.name,
                              });
                              openModal();
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
      </div>

      <div>
        {paymentProofProfature &&
          paymentProofProfature.files.length > 0 && (
            <div className="upload-document__content">
              <p style={{ fontWeight: "bold" }}>
                Deshmia e pageses se profatures
              </p>
              <div className="upload-document-button__content">
                {paymentProofProfature.files.map((image, index) => {
                  return (
                    <>
                      <div className="upload-document-image__section">
                        <div key={image.id} className="image-item">
                          <GrDocumentText />
                          <span>{image.name}</span>
                        </div>
                        <div className="upload-document-image__icons">
                          <BsEye
                            className="eyeIcon"
                            onClick={() => {
                              setSelectedFileObj({
                                type: image.type,
                                url: image.url,
                                name: image.name,
                              });
                              openModal();
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    </>
  );
}

export default UploadedPrivateDocuments;
