import "./Card.css";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { toast } from "react-hot-toast";
import { RxInfoCircled } from "react-icons/rx";
import { useTranslation } from "react-i18next";

function Card({
  title,
  price,
  countryCode,
  standardPassenger,
  minivan,
  suvOffRoad,
}) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const isLoggedIn = useContext(AuthContext);

  const handleNavigateClick = () => {
    if (isLoggedIn.isLoggedIn) {
      toast(
        (y) => (
          <span className="toast-info">
            <RxInfoCircled className="info-icon" />
            {t("You are already Logged In")}!
          </span>
        ),
        {
          id: "alreadyLoggedIn",
        }
      );
    } else {
      navigate("/registration/0");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="card1">
        <div className="card-title">
          <div className="card-title__img">
            <ReactCountryFlag countryCode={countryCode} svg />
          </div>
          <h3>{title}</h3>
        </div>
        <span>{price}</span>
        <ul className="card-list">
          <li>{standardPassenger} Standard passenger car</li>
          <li>{minivan} Minivan</li>
          <li>{suvOffRoad} SUV / off-road</li>
        </ul>
        <button className="card__button" onClick={handleNavigateClick}>
          {t("Register")}
        </button>
      </div>
    </>
  );
}

export default Card;
