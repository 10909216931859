import React, { useContext, useState } from "react";

import { AiOutlineUser } from "react-icons/ai";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { FiLogOut, FiUser } from "react-icons/fi";
import { TfiUnlock } from "react-icons/tfi";

import "./UserDropDown.css";
import { useNavigate } from "react-router-dom";
import Backdrop2 from "../Backdropt2";
import { AuthContext } from "../Context/AuthContext";
import { useTranslation } from "react-i18next";

const UserDropDown = () => {
  const ctx = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClickProfileNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("profile");
    setIsOpen(false);
  };

  const handleClickChangePasswordNavigate = () => {
    navigate("change-password");
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropDown = () => {
    setIsOpen(false);
  };

  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    ctx.setIsLoggedIn(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/");
  };

  return (
    <>
      <div className="dropdown-menu1">
        <div className="dropdown-menu__icons" onClick={toggleDropdown}>
          <AiOutlineUser />
          {!isOpen ? <RiArrowDropDownLine /> : <RiArrowDropUpLine />}
        </div>
        {isOpen && (
          <>
            <ul className="dropdown-menu__list">
              <div
                className="list-content"
                onClick={handleClickProfileNavigate}
              >
                <FiUser className="list-content__icon" />
                <li>{t("Profile")}</li>
              </div>
              <div className="dropdown-line"></div>
              <div
                className="list-content"
                onClick={handleClickChangePasswordNavigate}
              >
                <TfiUnlock className="list-content__icon" />
                <li>{t("Change Password")}</li>
              </div>
              <div className="dropdown-line"></div>
              <div className="list-content" onClick={handleLogOut}>
                <FiLogOut className="list-content__icon logout-icon" />
                <li className="list-content__logout">{t("Log Out")}</li>
              </div>
            </ul>
            <Backdrop2 onClick={closeDropDown} />
          </>
        )}
      </div>
    </>
  );
};

export default UserDropDown;
