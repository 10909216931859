import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { GetCurrentClient, instance } from "../endpoints";
import axios from "axios";
const currentUrl = window.location.href.toString().toLocaleLowerCase();
const isCarmax = () => {
  if (!currentUrl.includes("carmax-kos")) {
    return "1C8D5905-DF62-4AD1-9730-EAA3FD80919A";
  } else {
    return "506A16B9-1397-4EB7-BB87-AABFE9E8E560";
  }
};
// Create the context
export const AuthContext = createContext({
  data: "",
  setData: () => {},
  fetchCurrentClient: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  showExpirySessionModal: false,
  setShowExpirySessionModal: () => {},
});

const baseUrl = process.env.REACT_APP_API_URL;
let refreshingFunc = undefined;
async function refreshAccessToken() {
  const token = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  if (!token || !refreshToken) {
    throw new Error("No token or refreshToken");
  }
  const response = await axios.post(
    `${baseUrl}Tokens/getClientRefreshToken`,
    {
      refreshToken: refreshToken,
      token: token,
    },
    {
      headers: {
        Tenant: isCarmax(),
      },
    }
  );
  const newToken = response.data.token;
  const newRefreshToken = response.data.refreshToken;
  return [newToken, newRefreshToken];
}

// Create a provider component
export const AuthContextProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [showExpirySessionModal, setShowExpirySessionModal] = useState(false);

  useLayoutEffect(() => {
    const responseInterceptor = instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalConfig = error.config;
        ///if token does not exist or if error is not 401(unauthorized) or if there were no  network errors
        const token = localStorage.getItem("accessToken");
        if (
          !token ||
          !(error.code === "ERR_NETWORK" || error.response.status === 401)
        ) {
          return Promise.reject(error);
        }
        try {
          if (!refreshingFunc) refreshingFunc = refreshAccessToken();
          const [newToken, newRefreshToken] = await refreshingFunc;
          localStorage.setItem("accessToken", newToken);
          localStorage.setItem("refreshToken", newRefreshToken);
          originalConfig.headers.Authorization = `Bearer ${newToken}`;

          try {
            return await axios.request(originalConfig);
          } catch (innerError) {
            if (innerError.response.status === 401) {
              throw innerError;
            }
          }
        } catch (err) {
          // toast.error("Session Expired Please Login Again!", {
          //   toastId: "Unauthorized",
          // });
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          setShowExpirySessionModal(true);
        } finally {
          refreshingFunc = undefined;
        }
      }
    );
    return () => instance.interceptors.response.eject(responseInterceptor);
  }, []);

  const fetchCurrentClient = () => {
    GetCurrentClient().then((res) => {
      setData(res.data);
      if (res.data.success) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  };

  useEffect(() => {
    fetchCurrentClient();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        data: data,
        setData: setData,
        fetchCurrentClient: fetchCurrentClient,
        isLoggedIn: isLoggedIn,
        setIsLoggedIn: setIsLoggedIn,
        showExpirySessionModal,
        setShowExpirySessionModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
