import { useTranslation } from "react-i18next";
import "./ReviewAbout.css";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaShippingFast } from "react-icons/fa";
import { RiAuctionLine } from "react-icons/ri";

function ReviewAbout() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container">
        <div className="review-about">
          <div className="review-about__content">
            <BiMoneyWithdraw className="review-about__icons" />
            <h3 className="review-about__title">{t("100% Secure Deposits")}</h3>
            <p className="review-about__paragraph">
              {t(
                "All deposits are made via our SSL secure payment gateway. Your payment details are never stored on our servers ensuring your personal information is completely private and secure. Deposits are 100% refundable should you decide not to make a vehicle purchase with us."
              )}
            </p>
          </div>
          <div className="review-about__line"></div>
          <div className="review-about__content">
            <FaShippingFast className="review-about__icons" />
            <h3 className="review-about__title">
              {t("Easy Shipping & Export")}
            </h3>
            <p className="review-about__paragraph">
              {t(
                "When you win an auction we can arrange low cost shipping of your vehicle anywhere in the United States or internationally. If you prefer to arrange your own shipping we can also accommodate you as well. We’ve shipped 1000s of cars to dozens of countries around the world."
              )}
            </p>
          </div>
          <div className="review-about__line"></div>

          <div className="review-about__content">
            <RiAuctionLine className="review-about__icons" />
            <h3 className="review-about__title">
              {t("Live Vehicle Auctions")}
            </h3>
            <p className="review-about__paragraph">
              {t(
                "With a database consisting of over 200,000 auction vehicles at any one time we are the largest live vehicle auction marketplace online. We connect to every salvage and insurance vehicle auction house so you can shop them all at once from any internet connected device anywhere in the world."
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewAbout;
