import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import SocialMedia from "../../Social/SocialMedia";
import { Toaster } from "react-hot-toast";
import { FloatButton } from "antd";

import { BiUpArrowAlt } from "react-icons/bi";
import ExpiredTokenModal from "../Modals/ExpiredTokenModal";
import { useContext, useState } from "react";
import { AuthContext } from "../../Context/AuthContext";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { webControls } from "../../endpoints";

function RootLayout() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <>
      {isLoggedIn && <ExpiredTokenModal />}
      <ScrollToTop />
      <Toaster position="top-right" reverseOrder={false} />
      <SocialMedia />
      <Navbar />
      <main>
        <Outlet />
      </main>
      {webControls.isCarmax && (
        <FloatButton.BackTop shape={"square"} icon={<BiUpArrowAlt />} />
      )}
      <Footer />
    </>
  );
}

export default RootLayout;
