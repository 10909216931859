import { useTranslation } from "react-i18next";
import PageHeading from "../PageHeading/PageHeading";
import Services from "../ServicesMain/Services";

function ServicesPage() {
  const { t } = useTranslation();
  return (
    <>
      <PageHeading
        title={t("Services")}
        thisPage={t("Services")}
        paragraph={t(
          "Our team is dedicated to providing you with a transparent and enjoyable car-buying experience"
        )}
      />
      <Services />
    </>
  );
}

export default ServicesPage;
