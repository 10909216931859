import { useTranslation } from "react-i18next";
import Contact from "../Contact/Contact";
import PageHeading from "../PageHeading/PageHeading";

function ContactPage() {
  const { t } = useTranslation();
  return (
    <>
      <PageHeading
        title={t("Contact")}
        thisPage={t("Contact")}
        paragraph={t(
          "Contact us to learn more about our services and how we can help you find your dream car at an affordable price."
        )}
      />
      <Contact />
    </>
  );
}

export default ContactPage;
