import Slider from "react-slick";
import "./Slider.css";
import slider1 from "../../assets/images/slider1.png";
import slider2 from "../../assets/images/slider2.png";
import slider3 from "../../assets/images/slider3.png";
import slider4 from "../../assets/images/slider4.png";
import slider5 from "../../assets/images/slider5.png";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { useTranslation } from "react-i18next";

function SlickSlider() {
  const { t } = useTranslation();
  let settings = {
    dots: true,
    appendDots: (dots) => (
      <div className="custom-dots-container">
        <ul className="custom-dots">{dots}</ul>
      </div>
    ),
    transition: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    prevArrow: (
      <button className="slick-prev">
        <GrPrevious />{" "}
      </button>
    ),
    nextArrow: (
      <button className="slick-next">
        <GrNext />
      </button>
    ),
  };

  return (
    <>
      <div className="container">
        <Slider {...settings}>
          <div className="slider-page">
            <div className="slider-page__icon">
              <img className="slider-page__img" src={slider1} alt="" />
              <br />
              <span className="slider-page__icon-title">
                {t("Wide Selection")}
              </span>
            </div>
            <div className="slider-page__content">
              <ul className="slider-page__content-list">
                <li>{t("Thousands of different cars every week")}.</li>
                <li>{t("Used cars from all over Europe")}.</li>
                <li>{t("Cars and commercial vehicles")}.</li>
                <li>
                  {t(
                    "Cars in good condition, after accidents, with low and high mileage"
                  )}
                  .
                </li>
              </ul>
            </div>
          </div>
          <div className="slider-page">
            <div className="slider-page__icon">
              <img className="slider-page__img" src={slider2} alt="" />
              <br />
              <span className="slider-page__icon-title">
                {t("Lowest price guarantee")}
              </span>
            </div>
            <div className="slider-page__content">
              <ul className="slider-page__content-list">
                <li>{t("No additional fees – first-hand sales")}.</li>
                <li>{t("Clear pricing - there are no surprises")}.</li>
                <li>
                  {t(
                    "You pay only for the car, its transportation and preparation of documents"
                  )}
                  .
                </li>
              </ul>
            </div>
          </div>
          <div className="slider-page">
            <div className="slider-page__icon">
              <img className="slider-page__img" src={slider3} alt="" />
              <br />
              <span className="slider-page__icon-title">
                {t("Lowest price guarantee Safe and reliable")}
              </span>
            </div>
            <div className="slider-page__content">
              <ul className="slider-page__content-list">
                <li>{t("25+ years of experience in the car market")}.</li>
                <li>
                  {t(
                    "10+ years of experience in the online car auction market"
                  )}
                  .
                </li>
                <li>
                  {t("The mileage of all sold cars is checked and validated")}.
                </li>
                <li>{t("Each car receives a VIN number")}.</li>
                <li>
                  {t(
                    "All cars are inspected by certified independent EU experts"
                  )}
                  .
                </li>
                <li>{t("Data storage according to EU standards")}.</li>
              </ul>
            </div>
          </div>
          <div className="slider-page">
            <div className="slider-page__icon">
              <img className="slider-page__img" src={slider4} alt="" />
              <br />
              <span className="slider-page__icon-title">
                {t("Car auctions online - convenient and fast")}
              </span>
            </div>
            <div className="slider-page__content">
              <ul className="slider-page__content-list">
                <li>{t("Fast and free registration")}.</li>
                <li>{t("Convenient car search")}.</li>
                <li>
                  {t("Convenient and intuitive tools for using the platform")}.
                </li>
                <li>
                  {t(
                    "Clear procedures when paying for a car, preparing its documents and picking it up"
                  )}
                  .
                </li>
                <li>{t("Car auctions available online 24 hours a day")}. </li>
              </ul>
            </div>
          </div>
          <div className="slider-page">
            <div className="slider-page__icon">
              <img className="slider-page__img" src={slider5} alt="" />
              <br />
              <span className="slider-page__icon-title">
                {t("Logistic services")}
              </span>
            </div>
            <div className="slider-page__content">
              <ul className="slider-page__content-list">
                <li>{t("Reliable carriers")}.</li>
                <li>{t("Strict deadlines")}.</li>
                <li>{t("Door-to-door")}.</li>
                <li>{t("Document logistics")}.</li>
                <li>{t("Transportation process monitoring")}. </li>
              </ul>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default SlickSlider;
