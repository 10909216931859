import "./MileageFromTo.css";
import { Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";

const startNumber = 0;
const endNumber = 300000;
const step = 30000;

const numbers = Array.from(
  { length: (endNumber - startNumber) / step + 1 },
  (_, index) => {
    const value = startNumber + index * step;
    const label = `${value?.toLocaleString()}`;
    return { value, label };
  }
);

const MileageFromTo = ({ searchValues, setSearchValues }) => {
  const [mileageToOptions, setMileageToOptions] = useState(numbers);
  const { t } = useTranslation();

  const handleChangeSearchValue = (option, field) => {
    setSearchValues((prev) => ({ ...prev, [field]: option }));
    if (field == "MileageFrom" && option) {
      setMileageToOptions(numbers?.filter((e) => e.value >= option));
    } else {
      setMileageToOptions(numbers);
    }
    if (searchValues.MileageTo && searchValues.MileageTo < option) {
      setSearchValues((prev) => ({ ...prev, MileageTo: null }));
    }
  };

  return (
    <div className="milageSelect-content">
      <span>{t("Mileage from, to")} (km)</span>
      <div className="milageSelect">
        <div className="milageSelect__from">
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            value={searchValues?.MileageFrom}
            onChange={(option) =>
              handleChangeSearchValue(option, "MileageFrom")
            }
            options={numbers}
          />
        </div>
        <div className="milageSelect__to">
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            value={searchValues?.MileageTo}
            onChange={(option) => {
              setSearchValues((prev) => ({ ...prev, MileageTo: option }));
            }}
            options={mileageToOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default MileageFromTo;
