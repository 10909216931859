import React from 'react'
import CarDetails from '../Auctions/CarDetails/CarDetails'

function CarDetailsPage() {
  return (
    <>
    <CarDetails />
    </>
  )
}

export default CarDetailsPage