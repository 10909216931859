import "./ViewCurrentAuctionsKS.css";
import vector from "../../assets/images/vector-ks-icon.png";
import exam from "../../assets/images/exam -ks-icon.png";
import cars from "../../assets/images/cars-ks-icon.png";
import bid from "../../assets/images/bid-ks-icon.png";
import success from "../../assets/images/success -ks-icon.png";

const ViewCurrentAuctionsKS = () => {
  return (
    <>
      <section className="container vectors-section">
        <h1 className="vectors-section-title">
          Është e lehtë për të blerë vetura në ankande!
        </h1>
        <div className="vectors">
          <div className="vector-container vector-1">
            <img src={vector} alt="" />
            <div className="vector-content">
              <span className="vector-number">01</span>
              <span>
                <img src={exam} alt="" />
              </span>
              <h3>Regjistrohu</h3>
            </div>
          </div>
          <div className="vector-container vector-2">
            <img src={vector} alt="" />
            <div className="vector-content">
              <span className="vector-number">02</span>
              <span>
                <img src={cars} alt="" />
              </span>
              <h3>ZGJIDH</h3>
            </div>
          </div>
          <div className="vector-container vector-3">
            <img src={vector} alt="" />
            <div className="vector-content">
              <span className="vector-number">03</span>
              <span>
                <img src={bid} alt="" />
              </span>
              <h3>Ofro</h3>
            </div>
          </div>
          <div className="vector-container vector-4">
            <img src={vector} alt="" />
            <div className="vector-content">
              <span className="vector-number">04</span>
              <span>
                <img src={success} alt="" />
              </span>
              <h3>Fito</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewCurrentAuctionsKS;
