import { useState } from "react";
// import Select from "react-select";
import "./PowerFromTo.css";
import { Select } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";

const maxHorsepower = 600;
const horsepowerStep = 20;
const horsepowerOptions = Array.from(
  { length: Math.ceil(maxHorsepower / horsepowerStep) },
  (_, index) => ({
    value: index * horsepowerStep,
    label: `${index * horsepowerStep}`,
  })
);

const PowerFromTo = ({ searchValues, setSearchValues }) => {
  const { t } = useTranslation();
  const [powerToOptions, setPowerToOptions] = useState(horsepowerOptions);

  const handleChangeSearchValue = (option, field) => {
    setSearchValues((prev) => ({ ...prev, [field]: option }));
    if (field == "PowerFrom" && option) {
      setPowerToOptions(horsepowerOptions?.filter((e) => e.value >= option));
    } else {
      setPowerToOptions(horsepowerOptions);
    }
    if (searchValues.PowerTo && searchValues.PowerTo < option) {
      setSearchValues((prev) => ({ ...prev, PowerTo: null }));
    }
  };

  return (
    <div className="horsepowerSelect-content">
      <span>{t("Power from, to")} (KW)</span>
      <div className="horsepowerSelect">
        <div className="horsepowerSelect__from">
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            value={searchValues?.PowerFrom}
            options={horsepowerOptions}
            onChange={(option) => handleChangeSearchValue(option, "PowerFrom")}
          />
        </div>
        <div className="horsepowerSelect__to">
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            value={searchValues?.PowerTo}
            onChange={(option) =>
              setSearchValues((prev) => ({ ...prev, PowerTo: option }))
            }
            options={powerToOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default PowerFromTo;
