import { Link } from "react-router-dom";
import "./RegisterNowKs.css";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { RxInfoCircled } from "react-icons/rx";

import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const RegisterNowKs = () => {
  const isLoggedIn = useContext(AuthContext);

  const { t } = useTranslation();

  function handleRegister() {
    if (isLoggedIn.isLoggedIn) {
      toast(
        (y) => (
          <span className="toast-info">
            <RxInfoCircled className="info-icon" />
            {t("You are already Logged In")}!
          </span>
        ),
        {
          id: "alreadyLoggedIn",
        }
      );
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <section className="container">
      <div className="register-now-ks-container">
        <div className="register-now-ks-content">
          <h2>Regjistrohuni tani</h2>
          <p>
            Regjistroni një llogari falas dhe filloni të bëni oferta për veturën
            tuaj të ëndrrave tani!
          </p>
        </div>
        <Link
          to={!isLoggedIn.isLoggedIn && "registration/0"}
          className="register__btn-ks"
          onClick={handleRegister}
        >
          Regjistrohu
        </Link>
      </div>
    </section>
  );
};

export default RegisterNowKs;
