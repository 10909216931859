import "./UploadDocumentsStep1.css";

import FileInput from "./FileInput";
import { useContext, useState } from "react";
import { ClientDocumentType } from "../../../Enums";
import { UploadDocumentsContext } from "../../../Context/UploadDocumentsContext";
import { useTranslation } from "react-i18next";
import { ConfirmDocuments } from "../../../endpoints";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

function UploadDocumentsStep1({ setUploadDocumentStep }) {
  const uploadCtx = useContext(UploadDocumentsContext);
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");

  const { token } = useParams();

  function handleFormSubmit(e) {
    e.preventDefault();

    const companyRegistrationDocument = uploadCtx.uploadedFiles.find(
      (el) => el.name === "companyRegistrationDocument"
    );
    const identificationDocumentOfCompany = uploadCtx.uploadedFiles.find(
      (el) => el.name === "identificationDocumentOfCompany"
    );
    const invoicesOfPurchasedVehicles = uploadCtx.uploadedFiles.find(
      (el) => el.name === "invoicesOfPurchasedVehicles"
    );

    if (
      !companyRegistrationDocument?.files.length > 0 ||
      !identificationDocumentOfCompany?.files.length > 0 ||
      !invoicesOfPurchasedVehicles?.files.length > 0
    ) {
      setErrorMessage("Please upload all required documents.");
      return;
    }

    ConfirmDocuments(token).then((res) => {
      if (res.data.success) {
        setUploadDocumentStep(2);
      } else {
        toast.error(res.data.message, {
          duration: 3000,
          id: "bidupdatefailed",
        });
      }
    });
  }

  return (
    <>
      <form className="upload-document" onSubmit={handleFormSubmit}>
        <h3 className="upload-document__title">{t("Upload documents")}</h3>
        <p className="upload-document__paragraph1">
          {t(
            "Document formats: PDF, JPG, PNG. The maximum document size -10MB."
          )}
        </p>
        <div className="upload-document-required__section">
          <p className="upload-document__paragraph2">
            <span style={{ color: "red" }}> *</span> {t("Required documents")}
          </p>
          <p style={{ color: "red" }}>{errorMessage}</p>
        </div>
        <span className="upload-document-line"></span>
        <div className="upload-document__content-section">
          <div className="upload-document__content">
            <p style={{ fontWeight: "bold" }}>
              {t("Company registration document")}
              <span style={{ color: "red" }}> *</span>
            </p>
            <FileInput
              id={"companyRegistrationDocument"}
              documentType={ClientDocumentType.RegistrationDocument}
            />
          </div>
          <span className="upload-document-line"></span>
          <div className="upload-document__content">
            <p style={{ fontWeight: "bold" }}>
              {t(
                "Identification document of company manager/owner (passport/ID card)"
              )}{" "}
              <span style={{ color: "red" }}> *</span>
            </p>
            <FileInput
              id={"identificationDocumentOfCompany"}
              documentType={ClientDocumentType.IdentificationDocument}
            />
          </div>
          <span className="upload-document-line"></span>
          <div className="upload-document__content">
            <div className="invoice__content">
              <p style={{ fontWeight: "bold" }}>
                {t("Documents, confirming, that the company is a car trader")}
                <span style={{ color: "red" }}> *</span>
              </p>

              <p>
                • {t("3 invoices of purchased vehicles in the last 6 months")}
              </p>
            </div>
            <FileInput
              id={"invoicesOfPurchasedVehicles"}
              documentType={ClientDocumentType.ConfirmationDocument}
            />
          </div>
          <span className="upload-document-line"></span>
          <div className="upload-document__content">
            <p>{t("Proof of the payment of the profature")}</p>
            <FileInput
              id={"paymentProofProfature"}
              documentType={ClientDocumentType.PaymentProofProfature}
            />
          </div>
          <span className="upload-document-line"></span>
          <div className="upload-document__content">
            <p>{t("VAT registration document")}</p>
            <FileInput
              id={"vatDocument"}
              documentType={ClientDocumentType.VATRegistrationDocument}
            />
          </div>
          <span className="upload-document-line"></span>
          <div className="upload-document__content">
            <p>
              {t(
                "Identity document of authorized person (passport/ID card) The document is required if representative is not manager of the company"
              )}
            </p>
            <FileInput
              id={"identityDocumentOfAuthorizedPerson"}
              documentType={
                ClientDocumentType.IdentificationDocumentOfAuthorizedPerson
              }
            />
          </div>
          <span className="upload-document-line"></span>
          <div className="upload-document__content">
            <p>
              {t(
                "Power of attorney to act on behalf of the company The document is required if representative is not manager of the company"
              )}
            </p>
            <FileInput
              id={"powerOfAttorney"}
              documentType={ClientDocumentType.AttorneyToActOnBehalfDocument}
            />
          </div>
          <span className="upload-document-line"></span>
        </div>
        <button className="upload-document__btn">
          {t("Upload documents")} &gt;
        </button>
      </form>
    </>
  );
}

export default UploadDocumentsStep1;
