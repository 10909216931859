import { useState } from "react";
// import Select from "react-select";
import "../YearFromTo/YearFromTo.css";
import { Select } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 20 }, (_, index) => ({
  value: currentYear - index,
  label: `${currentYear - index}`,
}));

const YearFromToDropdown = ({ searchValues, setSearchValues }) => {
  const { t } = useTranslation();
  const [yearToOptions, setYearToOptions] = useState(years);

  const handleChangeSearchValue = (option, field) => {
    setSearchValues((prev) => ({ ...prev, [field]: option }));
    if (field == "yearFrom" && option) {
      setYearToOptions(years?.filter((year) => year.value >= option));
    } else {
      setYearToOptions(years);
    }
    if (searchValues.yearTo && searchValues.yearTo < option) {
      setSearchValues((prev) => ({ ...prev, yearTo: null }));
    }
  };

  return (
    <div className="yearSelect-content">
      <span>{t("Year from, to")}</span>
      <div className="yearSelect">
        <div className="yearSelectFrom">
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            value={searchValues?.yearFrom}
            onChange={(option) => handleChangeSearchValue(option, "yearFrom")}
            options={years}
          />
        </div>
        <div className="yearSelectTo">
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            value={searchValues?.yearTo}
            onChange={(option) =>
              setSearchValues((prev) => ({ ...prev, yearTo: option }))
            }
            options={yearToOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default YearFromToDropdown;
