import Slider from "react-slick";
import "./Slider.css";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";

import "./HomeSearchAuctionCarsKs.css";
import { useContext, useRef } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

function HomeSearchAuctionCarsKs({
  cars,
  setCars,
  paginationState,
  setPaginationState,
  searchValues,
}) {
  const sliderRef = useRef();

  const { isLoggedIn } = useContext(AuthContext);

  const navigate = useNavigate();

  let settings = {
    dots: false,
    transition: false,
    infinite: false,
    speed: 500,
    loop: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    // fade: true,
    prevArrow: false,
    nextArrow: false,
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const hanldeViewCar = (id) => {
    navigate(`/car/${id}`);
  };

  const renderCarBoxes = (carSize) => {
    const carChunks = chunkArray(cars, carSize);
    return carChunks.map((chunk, index) => (
      <div key={index} className="slider-page">
        {chunk.map((car, carIndex) => (
          <div key={carIndex} className="home-search-car-box-ks">
            <img src={car.carMainImage} alt="" />
            <div className="home-search-car-box-content-ks">
              <h3 onClick={() => hanldeViewCar(car.id)}>{car.name}</h3>
              <div
                onClick={() => hanldeViewCar(car.id)}
                className="home-search-car-box-content-ks-stats"
              >
                <span>{car.registrationDate}</span>
                <span>|</span>
                <span>{car.petrol}</span>
                <span>|</span>
                <span>{car.transmission}</span>
                <span>|</span>
                <span>{car.km.toLocaleString()} km</span>
                <span>|</span>
                <span>{car.hp} KW</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  const getNumberOfCarBoxes = () => {
    if (window.innerWidth < 850) {
      return 1;
    } else if (window.innerWidth < 1300) {
      return 2;
    } else {
      return 3;
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <button
          className="home-search-auction-prev"
          onClick={() => sliderRef?.current?.slickPrev()}
        >
          <GrPrevious />
        </button>

        <Slider
          arrows={false}
          ref={sliderRef}
          {...settings}
          onReInit={sliderRef?.current?.slickPrev()}
          beforeChange={(prev, next) => {
            if (
              prev + next !== 0 &&
              prev === next &&
              paginationState.hasNextPage
            ) {
              setPaginationState((prev) => ({
                ...prev,
                pageNumber: prev.pageNumber + 1,
              }));
            }
          }}
        >
          {renderCarBoxes(getNumberOfCarBoxes())}
        </Slider>
        <button
          className="home-search-auction-next"
          onClick={() => sliderRef?.current?.slickNext()}
        >
          <GrNext />
        </button>
      </div>
    </>
  );
}

export default HomeSearchAuctionCarsKs;
