import { Button, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PreviewFileModal = ({ showModal, closeModal, type, file, name }) => {
  const isPdf = type === "application/pdf" ? true : false;
  const { t } = useTranslation();

  return (
    <div className="documents-container-width">
      <Modal
        className="previewdoc-modal"
        title={name}
        open={showModal}
        onCancel={closeModal}
        closable={true}
        centered={true}
        footer={[<Button onClick={closeModal}>{t("Close")}</Button>]}
      >
        {isPdf ? (
          <iframe
            src={file}
            frameBorder="0"
            title="iframe"
            width="70vw"
            height="70vh"
            className="iframe-pdf"
          ></iframe>
        ) : (
          <img className="iframe__image" src={file} alt="img" />
        )}
      </Modal>
    </div>
  );
};

export default PreviewFileModal;
