import "./ViewCurrentAuctions.css";

import auctionsImage from "../../assets/images/auctions-image.png";
import auction1Responsive from "../../assets/images/auction1-responsive.png";
import auction2Responsive from "../../assets/images/auction2-responsive.png";
import auction3Responsive from "../../assets/images/auction3-responsive.png";
import auction4Responsive from "../../assets/images/auction4-responsive.png";

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useTranslation } from "react-i18next";

function ViewCurrentAuctions() {
  const navigate = useNavigate();

  const isLoggedIn = useContext(AuthContext);
  const { t } = useTranslation();

  const handleNavigate = () => {
    navigate("/auctions/opened");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="auctions-section">
        <div className="auctions-section__content">
          <h3 className="auctions-section__title">
            {t("Its easy to buy vehicles at auctions")}
          </h3>
          <div className="auction-section-responsive">
            <div className="auction-section-responsive__content">
              <div className="auction-section-responsive__elements">
                <img src={auction1Responsive} alt="" />
                <span>{t("REGISTER")}</span>
              </div>
              <div className="auction-section-responsive__elements">
                <img src={auction2Responsive} alt="" />
                <span>{t("SELECT")}</span>
              </div>
            </div>
            <div className="auction-section-responsive__content">
              <div className="auction-section-responsive__elements">
                <img src={auction3Responsive} alt="" />
                <span>{t("BID")}</span>
              </div>
              <div className="auction-section-responsive__elements">
                <img src={auction4Responsive} alt="" />
                <span>{t("WIN")}</span>
              </div>
            </div>
          </div>
          <div className="auction-section__content-images">
            <div>
              <img className="auction-image" src={auctionsImage} alt="" />
            </div>
            <div className="auctions-section__text">
              <p className="text-1">{t("REGISTER")}</p>
              <p className="text-2">{t("SELECT")}</p>
              <p className="text-3">{t("BID")}</p>
              <p className="text-4">{t("WIN")}</p>
            </div>
          </div>
          {isLoggedIn.isLoggedIn && (
            <button className="auctions-section__btn" onClick={handleNavigate}>
              {t("View current auctions")}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewCurrentAuctions;
