import React from "react";
import image from "../../assets/images/EmailCampaign.svg";
import { useTranslation } from "react-i18next";

function BuyCars() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        gap: "1.3rem",
        flexDirection: "column",
      }}
    >
      <img
        src={image}
        alt=""
        style={{ height: "13rem", objectFit: "contain" }}
      />
      <p style={{ fontWeight: "600", fontSize: "1.3rem", color: "#26313c" }}>
        {t(
          "Approval from an administrator is required for car purchases through your account"
        )}
      </p>
      <span style={{ fontSize: "14px" }}>
        {t("You will receive an email if and when your request is approved")}
      </span>
    </div>
  );
}

export default BuyCars;
