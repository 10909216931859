import React, { useContext, useEffect, useState } from "react";
import AuctionCarList from "../Auctions/AuctionCarList";
import AuctionListContent from "../Auctions/AuctionListContent";
import CarFilter from "../Auctions/CarFilter/CarFilter";
import Protector from "../Protector/Protector";
import { AuthContext } from "../../Context/AuthContext";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  GetAuctionCarsList,
  GetAuctionDetails,
  webControls,
} from "../../endpoints";
import { Pagination, Skeleton } from "antd";
import { OpenedClosedAuctionEnum } from "../../Enums";
import { useTranslation } from "react-i18next";

function AuctionsListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isLoggedIn = useContext(AuthContext);
  const [isReady, setIsReady] = useState(false);
  const [areAuctionDetailsReady, setAreAuctionDetailsReady] = useState(false);
  const [auctionDetails, setAuctionDetails] = useState();
  const [auctionCarsList, setAuctionCarsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 1
  );
  const [totalItems, setTotalItems] = useState();
  const [showList, setShowList] = useState(true);
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const location = useLocation();
  const { t } = useTranslation();
  const status = location?.pathname?.includes("closed")
    ? OpenedClosedAuctionEnum.Closed
    : OpenedClosedAuctionEnum.Opened;

  const [searchValues, setSearchValues] = useState({
    brands: [],
    yearFrom: null,
    yearTo: null,
    PowerFrom: null,
    PowerTo: null,
    EngineCapacityFrom: null,
    EngineCapacityTo: null,
    MileageFrom: null,
    MileageTo: null,
    Fuel: null,
    Gearbox: null,
    EuroStandard: null,
  });

  const { id } = useParams();


  useEffect(() => {
    const carId = searchParams.get("carId");
    if (carId) {
      const element = document.getElementById(carId);
      if (element && auctionCarsList) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 500); 
      }
    }
  }, [searchParams, isRequestBeingMade]);


  useEffect(() => {
    GetAuctionDetails(id).then((res) => {
      const auctionDetails = res.data;

      const finishingDateTime = new Date(auctionDetails.finishingDateTime);

      const updatedFinishingDateTime = `${String(
        finishingDateTime.getDate()
      ).padStart(2, "0")}/${String(finishingDateTime.getMonth() + 1).padStart(
        2,
        "0"
      )}/${finishingDateTime.getFullYear()} ${String(
        finishingDateTime.getHours()
      ).padStart(2, "0")}:${String(finishingDateTime.getMinutes()).padStart(
        2,
        "0"
      )}`;

      const startingDateTime = new Date(auctionDetails.startingDateTime);

      const updatedStartingDateTime = `${String(
        startingDateTime.getDate()
      ).padStart(2, "0")}/${String(startingDateTime.getMonth() + 1).padStart(
        2,
        "0"
      )}/${startingDateTime.getFullYear()} ${String(
        startingDateTime.getHours()
      ).padStart(2, "0")}:${String(startingDateTime.getMinutes()).padStart(
        2,
        "0"
      )}`;

      const updatedAuctionDetails = {
        ...auctionDetails,
        finishingDateTime: updatedFinishingDateTime,
        startingDateTime:
          auctionDetails.startingDateTime == null
            ? auctionDetails.startingDateTime
            : updatedStartingDateTime,
      };

      setAuctionDetails(updatedAuctionDetails);
      setAreAuctionDetailsReady(true);
    });
  }, []);

  useEffect(() => {
    const pageNum = searchParams.get("page");
    setIsRequestBeingMade(true);
    GetAuctionCarsList(currentPage, searchValues, id, status).then((res) => {
      setAuctionCarsList(res.data.auctionCars);
      setTotalItems(res.data.total);
      if((res.data.total / 10) < pageNum){
        setSearchParams((prev) => {
          return {
            page: 1,
            carId: prev.get("carId"),
          };
        });
      }
      setIsReady(true);
      setIsRequestBeingMade(false);
    });
  }, [currentPage, searchValues, searchParams, setSearchParams]);


  

  const handlePaginationChange = (page) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(page);
    setSearchParams({ page });
  };

  return (
    <Protector
      isLoggedIn={webControls.isCarmax ? isLoggedIn.isLoggedIn : true}
      error={
        <div className="my-bids-error">
          {t("You don't have access to this path!")}
        </div>
      }
    >
      {isReady && areAuctionDetailsReady ? (
        <>
          <div style={{ margin: "3rem auto" }}>
            <AuctionListContent
              carNo={auctionDetails?.numberOfCars}
              auctionId={auctionDetails?.auction}
              country={auctionDetails?.country}
              endDate={auctionDetails?.finishingDateTime}
              countryFlag={auctionDetails?.countryFlag}
              toNavigate={false}
              startingDate={auctionDetails?.startingDateTime}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
              marginBottom: "3rem",
            }}
          >
            <CarFilter
              searchValues={searchValues}
              setSearchValues={setSearchValues}
              showList={showList}
              setShowList={setShowList}
              setCurrentPage={setCurrentPage}
              status={status}
              isRequestBeingMade={isRequestBeingMade}
            />
            {auctionCarsList?.length > 0 && (
              <>
                {auctionCarsList?.map((data) => {
                  return (
                    <div key={data.id}>
                      <AuctionCarList
                        id={data.id}
                        hp={data.hp}
                        km={data.km}
                        name={data.name}
                        petrol={data.petrol}
                        transmission={data.transmission}
                        registrationDate={data.registrationDate}
                        finishingDateTime={data.finishingDateTime}
                        registrationNumber={data.registrationNumber}
                        country={data.country}
                        vehicleCategory={data.vehicleCategory}
                        isFavorite={data.isFavorite}
                        src={data.carMainImage}
                        setCars={() => {}}
                        setCarsNo={() => {}}
                        startingPrice={data.startingPrice}
                        showBidInput={true}
                        highestBidAmount={data.bidAmount}
                        startingDateTime={data.startDate || null}
                        registrationMonth={data.registrationMonth}
                      />
                    </div>
                  );
                })}
                <Pagination
                  showSizeChanger={false}
                  defaultCurrent={currentPage}
                  total={totalItems}
                  onChange={handlePaginationChange}
                  defaultPageSize={20}
                />
              </>
            )}
            {auctionCarsList?.length == 0 && (
              <div
                className="description__title"
                style={{ textAlign: "center" }}
              >
                {t("Sorry, no results were found.")}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="container auction-skeleton-containers ">
          <div className="auction-list__content" style={{ padding: "2rem 0" }}>
            <Skeleton
              active
              paragraph={{
                rows: 3,
              }}
              title={false}
            />
            <span className="car-list-content__line"></span>
            <div>
              <Skeleton
                avatar={{ size: 128, shape: "square" }}
                active
                paragraph={{
                  rows: 4,
                }}
                title={false}
              />
              <Skeleton
                avatar={{ size: 128, shape: "square" }}
                active
                paragraph={{
                  rows: 4,
                }}
                title={false}
              />
              <Skeleton
                avatar={{ size: 128, shape: "square" }}
                active
                paragraph={{
                  rows: 4,
                }}
                title={false}
              />
            </div>
          </div>
        </div>
      )}
    </Protector>
  );
}

export default AuctionsListPage;
