import "./WhyChooseUsKSSection.css";
import worldwide from "../../assets/images/worldwide-ks-icon.png";
import bubleChat from "../../assets/images/bubble-chat-ks-icon.png";
import handshake from "../../assets/images/handshake-ks-icon.png";
import choice from "../../assets/images/choice-ks-icon.png";

const WhyChooseUsKSSection = () => {
  return (
    <>
      <section className="why-choose-us-ks-section">
        <div
          className="container why-choose-us-ks-container"
        >
          <h1 className="why-choose-us-ks-title">PSE TË NA ZGJEDHNI NEVE?</h1>
          <div className="why-choose-us-boxes-container">
            <div className="why-choose-us-ks-box">
              <span>
                <img src={worldwide} alt="Worldwide Icon" />
              </span>
              <h3>Treg global</h3>
              <p>
                Eksploroni mundësi unike të automobilave në platformën tonë
                dinamike globale
              </p>
            </div>
            <div className="why-choose-us-ks-box">
              <span>
                <img src={bubleChat} alt="Worldwide Icon" />
              </span>
              <h3>Udhëzime eksperti</h3>
              <p>
                Merrni njohuri dhe mbështetje nga ekipi ynë, duke udhëhequr
                përvojën tuaj
              </p>
            </div>
          </div>
          <div className="why-choose-us-boxes-container">
            <div className="why-choose-us-ks-box">
              <span>
                <img src={handshake} alt="Worldwide Icon" />
              </span>
              <h3>Marrëveshje te qarta</h3>
              <p>
                Zgjidhni transaksione transparente për vendime të informuara mbi
                detajet e automjetit, nga historia në gjendje
              </p>
            </div>
            <div className="why-choose-us-ks-box">
              <span>
                <img src={choice} alt="Worldwide Icon" />
              </span>
              <h3>Përzgjedhje e ndryshme</h3>
              <p>
                Eksploroni një gamë të larmishme produktesh premium, të
                përshtatura për të përmbushur nevojat dhe preferencat tuaja
                unike
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseUsKSSection;
