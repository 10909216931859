import React, { useContext, useEffect, useState } from "react";
import "./ConfirmedCars.css";
import Protector from "../../Protector/Protector";
import { AuthContext } from "../../../Context/AuthContext";
import { BidsContext } from "../../../Context/BidsContext";
import Preloader from "../../Preloader/Preloader";
import { CarAuctionEnum } from "../../../Enums";
import AuctionCarList from "../../Auctions/AuctionCarList";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";

function ConfirmedCarsPage() {
  const isLoggedIn = useContext(AuthContext);
  const { isReady, getBidsByStatus, bidsByStatus, confirmedCarsNo } =
    useContext(BidsContext);
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getBidsByStatus(CarAuctionEnum.ConfirmedCars, currentPage);
  }, [currentPage]);

  const handlePaginationChange = (page) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(page);
  };

  return (
    <>
      <Protector
        isLoggedIn={isLoggedIn.isLoggedIn}
        error={
          <div className="my-bids-error">
            {t("You don't have access to this path!")}
          </div>
        }
      >
        <Preloader isReady={isReady}>
          <div style={{ margin: "5rem auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2.5rem",
                marginTop: "5rem",
              }}
              className="bids-list"
            >
              {Array.isArray(bidsByStatus) && bidsByStatus.length > 0 ? (
                <>
                  {bidsByStatus?.map((data) => {
                    return (
                      <div key={data.id}>
                        <AuctionCarList
                          id={data.id}
                          hp={data.hp}
                          km={data.km}
                          name={data.name}
                          petrol={data.petrol}
                          transmission={data.transmission}
                          registrationDate={data.registrationDate}
                          finishingDateTime={data.finishingDateTime}
                          registrationNumber={data.registrationNumber}
                          country={data.country}
                          vehicleCategory={data.vehicleCategory}
                          isFavorite={data.isFavorite}
                          src={data.carMainImage}
                          setCars={() => {}}
                          setCarsNo={() => {}}
                          bidAmount={data?.bidAmount}
                          showInvoiceButton={true}
                          registrationMonth={data.registrationMonth}
                        />
                      </div>
                    );
                  })}
                  <Pagination
                    showSizeChanger={false}
                    defaultCurrent={currentPage}
                    total={confirmedCarsNo}
                    onChange={handlePaginationChange}
                  />
                </>
              ) : (
                <div
                  className="description__title"
                  style={{ textAlign: "center" }}
                >
                  {t("There is currently no bid available.")}
                </div>
              )}
            </div>
          </div>
        </Preloader>
      </Protector>
    </>
  );
}

export default ConfirmedCarsPage;
