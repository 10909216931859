import { Link, useLocation } from "react-router-dom";
import "./MyBidNav.css";
import { useContext, useEffect, useState } from "react";
import { FavoriteCarsContext } from "../../../Context/FavoriteCarsContext";
import { BidsContext } from "../../../Context/BidsContext";
import { useTranslation } from "react-i18next";

function MyBidsNav() {
  const [activeLink, setActiveLink] = useState("");
  const { favoriteCarsNo } = useContext(FavoriteCarsContext);
  const { t } = useTranslation();

  const {
    underConsiderationCarsNo,
    confirmedCarsNo,
    cancelledCarsNo,
    receivedCarsNo,
  } = useContext(BidsContext);

  const handleScrollClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div style={{ background: "var(--primaryColor)" }}>
        <div className="container">
          <nav className="my-bid-nav">
            <ul className="my-bid-nav__list">
              <Link
                className={`my-bid__link ${
                  activeLink === "/my-bids/under-consideration" ? "active" : ""
                }`}
                to="/my-bids/under-consideration"
                onClick={() => {
                  setActiveLink("/my-bids/under-consideration");
                  handleScrollClick();
                }}
              >
                <li>
                  {t("Current bids")} ({underConsiderationCarsNo})
                </li>
              </Link>
              <Link
                className={`my-bid__link  ${
                  activeLink === "/my-bids/my-favorites" ? "active" : ""
                }`}
                to="/my-bids/my-favorites"
                onClick={() => {
                  setActiveLink("/my-bids/my-favorites");
                  handleScrollClick();
                }}
              >
                <li>
                  {t("My favorites")} ({favoriteCarsNo})
                </li>
              </Link>
              <Link
                className={`my-bid__link ${
                  activeLink === "/my-bids/confirmed-cars" ? "active" : ""
                }`}
                to="/my-bids/confirmed-cars"
                onClick={() => {
                  setActiveLink("/my-bids/confirmed-cars");
                  handleScrollClick();
                }}
              >
                <li>
                  {t("Confirmed cars")} ({confirmedCarsNo})
                </li>
              </Link>
              <Link
                className={`my-bid__link ${
                  activeLink === "/my-bids/cancelled-cars" ? "active" : ""
                }`}
                to="/my-bids/cancelled-cars"
                onClick={() => {
                  setActiveLink("/my-bids/cancelled-cars");
                  handleScrollClick();
                }}
              >
                <li>
                  {t("Cancelled cars")} ({cancelledCarsNo})
                </li>
              </Link>
              <Link
                className={`my-bid__link ${
                  activeLink === "/my-bids/received-cars" ? "active" : ""
                }`}
                to="/my-bids/received-cars"
                onClick={() => {
                  setActiveLink("/my-bids/received-cars");
                  handleScrollClick();
                }}
              >
                <li>
                  {t("Received cars")} ({receivedCarsNo})
                </li>
              </Link>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default MyBidsNav;
