import { ArrowRightOutlined } from "@ant-design/icons";

import "./SoldCars.css";
import { Link, useNavigate } from "react-router-dom";

const SoldCars = ({soldCars}) => {
    const navigate = useNavigate();
  return (
    <>
      <div className="sold-cars-bg">
        <section
          style={{ flexDirection: "column", height: "100%", paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container"
        >
          <div className="sold-cars-content">
            <div className="sold-cars-titles">
              <h2>Veturat e shitura</h2>
              <p>Exploroni të gjitha veturat e shitura në Carmax Kosova</p>
            </div>
            <Link to="sold-cars" className="sold-cars-content__link">
              Shfaq të gjitha{" "}
              <ArrowRightOutlined style={{ fontSize: "14px" }} />
            </Link>
          </div>
          <div className="sold-cars-container">
            {soldCars &&
              soldCars.map((soldCar, index) => (
                <img
                  onClick={() => navigate("sold-cars")}
                  key={index}
                  src={soldCar.base64}
                  alt={`Sold Car ${index}`}
                />
              ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default SoldCars;
