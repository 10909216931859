import "./PageHeading.css";

import { Link } from "react-router-dom";

import { BiRadioCircleMarked } from "react-icons/bi";
import { useTranslation } from "react-i18next";

function PageHeading({ title, paragraph, thisPage }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="page-heading-bg">
        <div className="container">
          <div className="page-heading">
            <h1 className="page-heading__title">{title}</h1>
            <span className="page-heading__line"></span>
            <p className="page-heading__paragraph">{paragraph}</p>
            <div className="page-heading__links">
              <Link className="home-link" to="/">
                {t("Home")}
              </Link>
              <BiRadioCircleMarked className="dot-icon" />
              <span className="thisPage-link">{thisPage}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHeading;
