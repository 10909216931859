import { GrDocumentText } from "react-icons/gr";
import { MdMarkEmailRead } from "react-icons/md";
import { TfiWrite } from "react-icons/tfi";
import { GiTakeMyMoney } from "react-icons/gi";
import { IoCheckmark } from "react-icons/io5";

import "./RegistersIcons.css";
import { useTranslation } from "react-i18next";

function RegistersIcons({ step }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="register-icons-bg">
        <div className="container">
          <div className="register-icons-content">
            <div className="register-icons-content__data">
              <div className={step >= 1 ? "data" : "data disabled"}>
                <div
                  className={step >= 1 ? "data__icon" : "data__icon disabled"}
                >
                  <TfiWrite />
                </div>
                <div
                  className={step >= 1 ? "data__text" : "data__text disabled"}
                >
                  <span>{t("Enter")}</span> <span>{t("data")}</span>
                </div>
              </div>
              <div className={step >= 2 ? "data" : "data disabled"}>
                <div
                  className={step >= 2 ? "data__icon" : "data__icon disabled"}
                >
                  <MdMarkEmailRead />
                </div>
                <div
                  className={step >= 2 ? "data__text" : "data__text disabled"}
                >
                  <span>{t("Activate")}</span> <span>{t("account")}</span>
                </div>
              </div>
              <div className={step >= 3 ? "data" : "data disabled"}>
                <div
                  className={step >= 3 ? "data__icon" : "data__icon disabled"}
                >
                  <GrDocumentText />
                </div>
                <div
                  className={step >= 3 ? "data__text" : "data__text disabled"}
                >
                  <span>{t("Upload")}</span> <span>{t("documents")}</span>
                </div>
              </div>
              <div className={step >= 4 ? "data" : "data disabled"}>
                <div
                  className={step >= 4 ? "data__icon" : "data__icon disabled"}
                >
                  <GiTakeMyMoney />
                </div>
                <div
                  className={step >= 4 ? "data__text" : "data__text disabled"}
                >
                  <span>{t("Buy")}</span> <span>{t("cars")}</span>
                </div>
              </div>
            </div>

            <div className="register-icons-content__icons">
              <IoCheckmark
                className={
                  step > 1
                    ? "done-icons"
                    : step === 1
                    ? "done-icons inProgress"
                    : "done-icons disabled-icons"
                }
              />
              <IoCheckmark
                className={
                  step > 2
                    ? "done-icons"
                    : step === 2
                    ? "done-icons inProgress"
                    : "done-icons disabled-icons"
                }
              />
              <IoCheckmark
                className={
                  step > 3
                    ? "done-icons"
                    : step === 3
                    ? "done-icons inProgress"
                    : "done-icons disabled-icons"
                }
              />
              <IoCheckmark
                className={
                  step > 4
                    ? "done-icons"
                    : step === 4
                    ? "done-icons inProgress"
                    : "done-icons disabled-icons"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegistersIcons;
