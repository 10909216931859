import "./Modal.css";

import { RiCloseFill } from "react-icons/ri";

function Modal({ file, type, name, closeModal }) {
  const isPdf = type === "application/pdf" ? true : false;

  return (
    <>
      <div className="modal">
        {isPdf ? (
          <iframe className="iframe__pdf" src={file} title="iframe"></iframe>
        ) : (
          <img className="iframe__image" src={file} alt="img" />
        )}

        <div className="modal__close-btn">
          <span>{name}</span>
          <RiCloseFill onClick={closeModal} />
        </div>
      </div>
    </>
  );
}

export default Modal;
