import "./HowWeOperate.css";

import { VscDashboard, VscWorkspaceTrusted } from "react-icons/vsc";
import { SlPeople } from "react-icons/sl";
import { BsPeace } from "react-icons/bs";
import { GiSandsOfTime } from "react-icons/gi";
import { useTranslation } from "react-i18next";

function HowWeOperate() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container">
        <div className="how-we-operate">
          <p className="how-we-operate__paragraph">{t("OUR VALUES")}</p>
          <h1 className="how-we-operate__title">{t("How we operate")}</h1>
          <div className="how-we-operate__content">
            <div className="content">
              <span className="content__image">
                <VscWorkspaceTrusted />
              </span>
              <h3 className="content__title">{t("Trust & Transparency")}</h3>
              <p className="content__paragraph">
                {t(
                  "We provide trust and transparency in everything we do. We empower our people to make decisions that benefit our customers and the Company"
                )}
                .
              </p>
            </div>
            <div className="content">
              <span className="content__image">
                <SlPeople />
              </span>
              <h3 className="content__title">{t("People First")}</h3>
              <p className="content__paragraph">
                {t(
                  "Our foundation is our people. We respect and listen to our people, and we care for their well-being."
                )}
              </p>
            </div>
            <div className="content">
              <span className="content__image">
                <VscDashboard />
              </span>
              <h3 className="content__title">{t("Positive Experiences")}</h3>
              <p className="content__paragraph">
                {t(
                  "We create positive experiences for all of our customers, striving every day to be famous for our service"
                )}
              </p>
            </div>
          </div>
          <div className="how-we-operate__content">
            <div className="content">
              <span className="content__image">
                <BsPeace />
              </span>
              <h3 className="content__title">{t("Calm Persistence")}</h3>
              <p className="content__paragraph">
                {t(
                  " Calm persistence is how we operate, showing resilience and persistence in the face of adversity."
                )}
              </p>
            </div>
            <div className="content">
              <span className="content__image">
                <GiSandsOfTime />
              </span>
              <h3 className="content__title">{t("Never Settling")}</h3>
              <p className="content__paragraph">
                {t(
                  "We never settle for where we are today. Continually evolving is part of our DNA."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowWeOperate;
