import React, { useEffect, useState } from "react";
import { Select } from "antd";

import "./EnterDataStep1.css";
import { GetCountries, webControls } from "../../../endpoints";

function CountrySelect({
  defaultValue,
  value,
  setSelectedCountry,
  countryName,
  className,
  tabIndex,
}) {
  const [countries, setCountries] = useState([]);

  const carmaxKosovaCountries = [
    { value: "AL", text: "Albania" },
    { value: "XK", text: "Republic of Kosovo" },
    { value: "MK", text: "North Macedonia" },
    { value: "ME", text: "Montenegro" },
  ];

  useEffect(() => {
    GetCountries().then((res) => {
      if (webControls.isCarmax) {
        setCountries(res.data.countries);
      } else {
        setCountries(carmaxKosovaCountries);
      }
      setSelectedCountry(defaultValue);
    });
  }, [defaultValue, setSelectedCountry]);

  return (
    <>
      <Select
        tabIndex={tabIndex}
        name={countryName}
        className={className}
        showSearch={true}
        id="country"
        options={countries.map((country) => ({
          value: country.value,
          label: country.text,
        }))}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        value={value}
        defaultValue={defaultValue}
        onChange={(selectedOption) => setSelectedCountry(selectedOption)}
      />
    </>
  );
}

export default CountrySelect;
