import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { GetClientToken } from "../../endpoints";
import { AuthContext } from "../../Context/AuthContext";
import { useTranslation } from "react-i18next";

function Login() {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();

  const [preLoader, setPreLoader] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isEmailValid, setIsEmailValid] = useState({
    valid: true,
  });

  const [isPasswordValid, setIsPasswordValid] = useState({
    valid: true,
  });

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "email") {
      setIsEmailValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: t("Please enter a valid email address!"),
      }));
    }

    if (name === "password") {
      setIsPasswordValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: t("Please enter a valid password"),
      }));
    }

    if (name === "password") {
      setIsPasswordValid((prevIsValid) => ({
        ...prevIsValid,
        valid: !(value.trim().length < 6),
        message: t("Password must be at least 6 characters"),
      }));
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (formData.email.trim().length === 0) {
      setIsEmailValid((prev) => {
        return {
          valid: false,
          message: t("Please enter a valid email address!"),
        };
      });
    } else {
      setIsEmailValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }
    if (formData.password.trim().length === 0) {
      setIsPasswordValid((prev) => {
        return {
          valid: false,
          message: t("Please enter a valid password"),
        };
      });
    } else if (formData.password.trim().length < 6) {
      setIsPasswordValid((prev) => {
        return {
          valid: false,
          message: t("Password must be at least 6 characters"),
        };
      });
    } else {
      setIsPasswordValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }
  }

  const handleClick = () => {
    if (
      formData.email.trim() !== "" &&
      formData.password.trim() !== "" &&
      formData.password.trim().length > 5
    ) {
      GetClientToken(formData)
        .then((res) => {
          localStorage.setItem("accessToken", res.data.token);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          if (res.data.success) {
            setPreLoader(true);
            authContext.fetchCurrentClient();
            navigate("/");
            authContext.setShowExpirySessionModal(false);
            toast.success(t("Successfuly logged in"), {
              duration: 2000,
              id: "successfulyLoggedIn",
            });
          } else {
            toast.error(res.data.message, {
              duration: 3000,
              id: "emailOrPasswordIsWrong",
            });
          }
        })
        .finally(() => {
          setPreLoader(false);
        });
    }
  };

  return (
    <>
      <div className="login-section">
        <div className="container">
          <div className="login">
            {!authContext.isLoggedIn || authContext.isLoggedIn != undefined ? (
              <form className="login__content" onSubmit={handleSubmit}>
                <p className="login__content-p">{t("Log In")}</p>
                <div className="login-input__container">
                  <input
                    className="login__input"
                    type="text"
                    placeholder={t("Email Address")}
                    onChange={handleChange}
                    value={formData.email}
                    name="email"
                  />
                  {!isEmailValid.valid && (
                    <p className="error-message">{isEmailValid.message}</p>
                  )}
                </div>
                <div className="login-input__container">
                  <input
                    className="login__input"
                    type="password"
                    placeholder={t("Password")}
                    onChange={handleChange}
                    value={formData.password}
                    name="password"
                    autoComplete="current-password"
                  />
                  {!isPasswordValid.valid && (
                    <p className="error-message">{isPasswordValid.message}</p>
                  )}
                </div>
                <button
                  className="login__button"
                  onClick={handleClick}
                  disabled={preLoader}
                >
                  {t("Log In")}
                </button>
                <div className="login__checkbox">
                  <Link
                    className="forgot-pw__link"
                    to="forgot-password"
                    onClick={handleScroll}
                  >
                    {t("Forgot password?")}
                  </Link>
                </div>
                <div className="login-line"></div>
                <Link
                  className="login-register__btn"
                  to="../registration/0"
                  onClick={handleScroll}
                >
                  {t("Register for an account")}
                </Link>
              </form>
            ) : (
              <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
                {t("You are already Logged In")}!
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
