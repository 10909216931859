import { useContext } from "react";
import PageHeading from "../PageHeading/PageHeading";
import Protector from "../Protector/Protector";
import SearchNow from "../SearchNow/SearchNow";
import { AuthContext } from "../../Context/AuthContext";
import { useTranslation } from "react-i18next";
import { webControls } from "../../endpoints";

function SearchPage() {
  const isLoggedIn = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <>
      <Protector
        isLoggedIn={webControls.isCarmax ? isLoggedIn.isLoggedIn : true}
        error={
          <div className="my-bids-error">
            {t("You don't have access to this path!")}
          </div>
        }
      >
        <PageHeading
          title={t("Search now")}
          thisPage={t("Search now")}
          paragraph={t(
            "Start your search now and see how our filters can help you find the perfect result"
          )}
        />
        <SearchNow />
      </Protector>
    </>
  );
}

export default SearchPage;
