import "./EnterDataStep1.css";

import React, { useState, useReducer, useContext, useEffect } from "react";
import CountrySelect from "./CountrySelect";
import TypeOfActivitySelect from "./TypeOfActivitySelect";
import { useTranslation } from "react-i18next";
import { CheckIfEmailAlreadyExist, webControls } from "../../../endpoints";
import HowDidYouHearSelect from "./HowDidYouHearSelect";
import toast from "react-hot-toast";
import { AuthContext } from "../../../Context/AuthContext";
import { Radio } from "antd";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT__TYPE__TEXT": {
      const { field, payload } = action;
      const errorMessage = payload.trim() === "" ? "input-red" : "";
      return {
        ...state,
        [field]: { value: payload, error: errorMessage },
      };
    }

    default:
      return state;
  }
};

function EnterDataStep1({ setEnteredDataStep, setFormData, formData }) {
  const [selectedCountry, setSelectedCountry] = useState(formData.countryCode);
  const [selectedActivity, setSelectedActivity] = useState(
    formData.typeOfActivity
  );
  const [howDidYouHearSelect, setHowDidYouHearSelect] = useState(
    formData.howDidYouHear
  );
  const { setData } = useContext(AuthContext);
  const [countryError, setCountryError] = useState("");

  const [radioValue, setRadioValue] = useState(1);

  useEffect(() => {
    if (formData.radioValue) {
      setRadioValue(formData.radioValue);
    } else {
      setRadioValue(1);
    }
  }, [formData.radioValue]);

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const { t } = useTranslation();

  const initialFormState = {
    name: {
      error: "",
      value: formData.name || "",
    },
    typeOfActivity: {
      error: "",
      value: formData.typeOfActivity || "",
    },
    companyRegistrationNumber: {
      error: "",
      value: formData.companyRegistrationNumber || "",
    },

    VATRegistrationNumber: {
      error: "",
      value: formData.VATRegistrationNumber || "",
    },
    address: {
      error: "",
      value: formData.address || "",
    },
    countryCode: {
      error: "",
      value: formData.countryCode || "",
    },
    city: {
      error: "",
      value: formData.city || "",
    },
    telephone: {
      error: "",
      value: formData.telephone || "",
    },
    postCode: {
      error: "",
      value: formData.postCode || "",
    },
    firstName: {
      error: "",
      value: formData.firstName || "",
    },
    lastName: {
      error: "",
      value: formData.lastName || "",
    },
    email: {
      error: "",
      value: formData.email || "",
    },
    emailRepeat: {
      error: "",
      value: formData.emailRepeat || "",
    },
    emailMatch: {
      error: "",
      value: formData.emailMatch || "",
    },
  };

  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  const handleChangeText = (e) => {
    dispatch({
      type: "INPUT__TYPE__TEXT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const nextChangeKs = async () => {
    let formIsValid = true;
    if (formState.firstName.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.firstName.name,
        error: "input-red",
        payload: formState.firstName.value,
      });
      formState.firstName.error = "input-red";
      formIsValid = false;
    } else {
      formState.firstName.error = "";
    }

    if (formState.email.value === "" || !isValidEmail(formState.email.value)) {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.email.name,
        error: "input-red",
        payload: formState.email.value,
      });
      formState.email.error = "input-red";
      formIsValid = false;
    } else {
      formState.email.error = "";
    }

    if (formState.email.value !== formState.emailRepeat.value) {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        error: t("Email-i nuk përputhet"),
        payload: formState.emailMatch.value,
      });
      formState.emailMatch.error = t("Email-i nuk përputhet");
      formIsValid = false;
    } else {
      formState.emailMatch.error = "";
    }

    if (formState.telephone.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.telephone.name,
        error: "input-red",
        payload: formState.telephone.value,
      });
      formState.telephone.error = "input-red";
      formIsValid = false;
    } else {
      formState.telephone.error = "";
    }

    if (selectedCountry === t("Choose Country")) {
      setCountryError("input-empty-red");
      formIsValid = false;
    } else {
      setCountryError("");
    }

    if (formState.companyRegistrationNumber.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.companyRegistrationNumber.name,
        error: "input-red",
        payload: formState.companyRegistrationNumber.value,
      });
      formState.companyRegistrationNumber.error = "input-red";
      formIsValid = false;
    } else {
      formState.companyRegistrationNumber.error = "";
    }

    if (formState.lastName.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.lastName.name,
        error: "input-red",
        payload: formState.lastName.value,
      });
      formState.lastName.error = "input-red";
      formIsValid = false;
    } else {
      formState.lastName.error = "";
    }

    if (formState.address.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.address.name,
        error: "input-red",
        payload: formState.address.value,
      });
      formState.address.error = "input-red";
      formIsValid = false;
    } else {
      formState.address.error = "";
    }

    if (formState.postCode.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.postCode.name,
        error: "input-red",
        payload: formState.postCode.value,
      });
      formState.postCode.error = "input-red";
      formIsValid = false;
    } else {
      formState.postCode.error = "";
    }

    if (formState.city.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.city.name,
        error: "input-red",
        payload: formState.city.value,
      });
      formState.city.error = "input-red";
      formIsValid = false;
    } else {
      formState.city.error = "";
    }

    if (
      formState.emailRepeat.value === "" ||
      !isValidEmail(formState.emailRepeat.value)
    ) {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.emailRepeat.name,
        error: "input-red",
        payload: formState.emailRepeat.value,
      });
      formState.emailRepeat.error = "input-red";
      formIsValid = false;
    } else {
      formState.emailRepeat.error = "";
    }

    if (formIsValid) {
      if (
        formState.email.value !== "" &&
        formState.email.value === formState.emailRepeat.value
      ) {
        await CheckIfEmailAlreadyExist(formState.email.value).then((res) => {
          if (res.data.success) {
            formIsValid = true;
            setFormData({
              ...formData,
              firstName: formState.firstName.value,
              lastName: formState.lastName.value,
              companyRegistrationNumber:
                formState.companyRegistrationNumber.value,
              email: formState.email.value,
              city: formState.city.value,
              address: formState.address.value,
              emailRepeat: formState.emailRepeat.value,
              telephone: formState.telephone.value,
              postCode: formState.postCode.value,
              countryCode: selectedCountry,
              howDidYouHear: howDidYouHearSelect,
              radioValue: radioValue,
            });
            setData({
              email: formState.email.value,
            });

            if (window.innerWidth < 800) {
              setEnteredDataStep(3);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            } else {
              setEnteredDataStep(3);
            }
          } else {
            setFormData({
              ...formData,
              firstName: formState.firstName.value,
              lastName: formState.lastName.value,
              companyRegistrationNumber:
                formState.companyRegistrationNumber.value,
              email: formState.email.value,
              city: formState.city.value,
              address: formState.address.value,
              emailRepeat: formState.emailRepeat.value,
              telephone: formState.telephone.value,
              postCode: formState.postCode.value,
              countryCode: selectedCountry,
              howDidYouHear: howDidYouHearSelect,
              radioValue: radioValue,
            });
            setData({
              email: formState.email.value,
            });
            formIsValid = false;
            toast.error(res.data.message, {
              duration: 2000,
              id: "errorMessage",
            });
          }
        });
      }
    }
  };

  const nextChange = () => {
    let formIsValid = true;

    if (formState.name.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.name.name,
        error: "input-red",
        payload: formState.name.value,
      });
      formState.name.error = "input-red";
      formIsValid = false;
    } else {
      formState.name.error = "";
    }

    if (formState.companyRegistrationNumber.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.companyRegistrationNumber.name,
        error: "input-red",
        payload: formState.companyRegistrationNumber.value,
      });
      formState.companyRegistrationNumber.error = "input-red";
      formIsValid = false;
    } else {
      formState.companyRegistrationNumber.error = "";
    }

    if (formState.address.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.address.name,
        error: "input-red",
        payload: formState.address.value,
      });
      formState.address.error = "input-red";
      formIsValid = false;
    } else {
      formState.address.error = "";
    }

    if (selectedCountry === t("Choose Country")) {
      setCountryError("input-empty-red");
      formIsValid = false;
    } else {
      setCountryError("");
    }

    if (
      selectedActivity === t("Is company a car trader?") ||
      selectedActivity === 0
    ) {
      setCountryError("input-empty-red");
      formIsValid = false;
    } else {
      setCountryError("");
    }

    if (formState.city.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.city.name,
        error: "input-red",
        payload: formState.city.value,
      });
      formState.city.error = "input-red";
      formIsValid = false;
    } else {
      formState.city.error = "";
    }

    if (formState.postCode.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: formState.postCode.name,
        error: "input-red",
        payload: formState.postCode.value,
      });
      formState.postCode.error = "input-red";
      formIsValid = false;
    } else {
      formState.postCode.error = "";
    }

    if (formIsValid) {
      setFormData({
        ...formData,
        name: formState.name.value,
        typeOfActivity: selectedActivity,
        companyRegistrationNumber: formState.companyRegistrationNumber.value,
        VATRegistrationNumber: formState.VATRegistrationNumber.value,
        address: formState.address.value,
        city: formState.city.value,
        postCode: formState.postCode.value,
        countryCode: selectedCountry,
        radioValue: radioValue,
      });
      if (window.innerWidth < 800) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setEnteredDataStep((prevStep) => prevStep + 1);
      } else {
        setEnteredDataStep((prevStep) => prevStep + 1);
      }
    }
  };

  return (
    <>
      <div className="entered-data-step1__content">
        {radioValue === 1 ? (
          <p className="entered-data-step1__p">
            {t("Information about the company")}
          </p>
        ) : (
          <p className="entered-data-step1__p">Të dhënat personale</p>
        )}

        {!webControls.isCarmax && (
          <Radio.Group
            style={{ marginBottom: "1rem" }}
            onChange={handleRadioChange}
            value={radioValue}
          >
            <Radio value={1}>Kompani</Radio>
            <Radio value={2}>Individë</Radio>
          </Radio.Group>
        )}

        {window.innerWidth <= 1200 ? (
          <form className="entered-data-form" action="">
            <div className="entered-data-form-inputs__section">
              {webControls.isCarmax && radioValue === 1 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="company">
                    {t("Company")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="1"
                    type="text"
                    id="company"
                    placeholder={t("Company")}
                    name="name"
                    value={formState.name.value}
                    onChange={handleChangeText}
                    className={formState.name.error}
                  />
                </div>
              ) : !webControls.isCarmax && radioValue === 2 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="firstName">
                    {t("First name")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="1"
                    type="text"
                    id="firstName"
                    placeholder={t("First name")}
                    name="firstName"
                    value={formState.firstName.value}
                    onChange={handleChangeText}
                    className={formState.firstName.error}
                  />
                </div>
              ) : (
                <div className="entered-data-form__inputs">
                  <label htmlFor="company">
                    {t("Company")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="1"
                    type="text"
                    id="company"
                    placeholder={t("Company")}
                    name="name"
                    value={formState.name.value}
                    onChange={handleChangeText}
                    className={formState.name.error}
                  />
                </div>
              )}
              {!webControls.isCarmax && radioValue === 2 && (
                <div className="entered-data-form__inputs">
                  <label htmlFor="lastName">
                    {t("Last name")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="2"
                    type="text"
                    id="lastName"
                    placeholder={t("Last name")}
                    name="lastName"
                    value={formState.lastName.value}
                    onChange={handleChangeText}
                    className={formState.lastName.error}
                  />
                </div>
              )}
              <div className="entered-data-form__inputs">
                <label htmlFor="registrationNumber">
                  {webControls.isCarmax || radioValue === 1
                    ? t("Company registration number")
                    : "Numri personal"}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex="2"
                  type="text"
                  id="registrationNumber"
                  placeholder={
                    webControls.isCarmax
                      ? t("Company registration number")
                      : "Numri personal"
                  }
                  onChange={handleChangeText}
                  name="companyRegistrationNumber"
                  value={formState.companyRegistrationNumber.value}
                  className={formState.companyRegistrationNumber.error}
                />
              </div>
              {!webControls.isCarmax && radioValue === 2 && (
                <div className="entered-data-form__inputs">
                  <label htmlFor="telephone">
                    {t("Phone Number")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    maxLength={20}
                    tabIndex="3"
                    type="tel"
                    id="telephone"
                    placeholder="+1 (123) 456-7890"
                    name="telephone"
                    value={formState.telephone.value}
                    onChange={handleChangeText}
                    className={formState.telephone.error}
                  />
                </div>
              )}
              {webControls.isCarmax && radioValue === 1 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="VAT">{t("VAT registration number")}</label>
                  <input
                    tabIndex="3"
                    type="text"
                    id="VAT"
                    placeholder={t("VAT registration number")}
                    value={formState.VATRegistrationNumber.value}
                    name="VATRegistrationNumber"
                    onChange={handleChangeText}
                  />
                </div>
              ) : !webControls.isCarmax && radioValue === 2 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="email">
                    E-mail <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="5"
                    type="email"
                    id="email"
                    placeholder="E-mail"
                    name="email"
                    value={formState.email.value}
                    onChange={handleChangeText}
                    className={formState.email.error}
                  />
                </div>
              ) : (
                <div className="entered-data-form__inputs">
                  <label htmlFor="VAT">{t("VAT registration number")}</label>
                  <input
                    tabIndex="3"
                    type="text"
                    id="VAT"
                    placeholder={t("VAT registration number")}
                    value={formState.VATRegistrationNumber.value}
                    name="VATRegistrationNumber"
                    onChange={handleChangeText}
                  />
                </div>
              )}

              {webControls.isCarmax && radioValue === 1 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="country">
                    {t("Type of activity")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <TypeOfActivitySelect
                    tabIndex="4"
                    defaultValue={
                      formData.typeOfActivity === undefined
                        ? t("Is company a car trader?")
                        : selectedActivity
                    }
                    value={selectedActivity}
                    setSelectedActivity={setSelectedActivity}
                    activityName={"typeOfActivity"}
                    className={
                      selectedActivity === t("Is company a car trader?") ||
                      selectedActivity === 0
                        ? countryError
                        : ""
                    }
                  />
                </div>
              ) : !webControls.isCarmax && radioValue === 2 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="emailRepeat">
                    {t("Repeat your e-mail")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <input
                      tabIndex="6"
                      type="email"
                      id="emailRepeat"
                      placeholder={t("Repeat your e-mail")}
                      name="emailRepeat"
                      value={formState.emailRepeat.value}
                      onChange={handleChangeText}
                      className={formState.emailRepeat.error}
                    />
                    <p className="error-message">
                      {formState.emailMatch.error}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="entered-data-form__inputs">
                  <label htmlFor="country">
                    {t("Type of activity")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <TypeOfActivitySelect
                    tabIndex="6"
                    defaultValue={
                      formData.typeOfActivity === undefined
                        ? t("Is company a car trader?")
                        : selectedActivity
                    }
                    value={selectedActivity}
                    setSelectedActivity={setSelectedActivity}
                    activityName={"typeOfActivity"}
                    className={
                      selectedActivity === t("Is company a car trader?") ||
                      selectedActivity === 0
                        ? countryError
                        : ""
                    }
                  />
                </div>
              )}

              <div className="entered-data-form__inputs">
                <label htmlFor="country">
                  {t("Country")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <CountrySelect
                  tabIndex="7"
                  defaultValue={
                    formData.countryCode === undefined
                      ? t("Choose Country")
                      : selectedCountry
                  }
                  value={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  countryName={"countryCode"}
                  className={
                    selectedCountry === t("Choose Country") ? countryError : ""
                  }
                />
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="city">
                  {t("City")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex="6"
                  type="text"
                  id="city"
                  placeholder="City"
                  onChange={handleChangeText}
                  name="city"
                  value={formState.city.value}
                  className={formState.city.error}
                />
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="address">
                  {t("Address")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex="9"
                  type="text"
                  id="address"
                  placeholder={t("Address")}
                  onChange={handleChangeText}
                  name="address"
                  value={formState.address.value}
                  className={formState.address.error}
                />
              </div>
            </div>
            <div className="entered-data-form-inputs__section">
              <div className="entered-data-form__inputs">
                <label htmlFor="postCode">
                  {t("Post code")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  maxLength={10}
                  tabIndex="10"
                  type="text"
                  id="postCode"
                  placeholder={t("Post code")}
                  onChange={handleChangeText}
                  name="postCode"
                  value={formState.postCode.value}
                  className={formState.postCode.error}
                />
              </div>
            </div>
            {!webControls.isCarmax && radioValue === 2 && (
              <div className="entered-data-form__inputs">
                <label htmlFor="country">
                  {t("How did you hear about us?")}
                </label>
                <HowDidYouHearSelect
                  tabIndex="11"
                  defaultValue={
                    formData.howDidYouHear === undefined
                      ? t("-- Please specify? --")
                      : howDidYouHearSelect
                  }
                  value={howDidYouHearSelect}
                  setHowDidYouHearSelect={setHowDidYouHearSelect}
                  activityName={"howDidYouHear"}
                  className={
                    howDidYouHearSelect === "Is company a car trader?" ||
                    howDidYouHearSelect === 0
                      ? "input-red"
                      : ""
                  }
                />
              </div>
            )}
          </form>
        ) : (
          <form
            style={{
              alignItems: webControls.isCarmax ? "center" : "start",
              marginTop: webControls.isCarmax ? "0" : "1rem",
            }}
            className="entered-data-form"
            action=""
          >
            <div className="entered-data-form-inputs__section">
              {webControls.isCarmax && radioValue === 1 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="company">
                    {t("Company")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="1"
                    type="text"
                    id="company"
                    placeholder={t("Company")}
                    name="name"
                    value={formState.name.value}
                    onChange={handleChangeText}
                    className={formState.name.error}
                  />
                </div>
              ) : !webControls.isCarmax && radioValue === 2 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="firstName">
                    {t("First name")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="1"
                    type="text"
                    id="firstName"
                    placeholder={t("First name")}
                    name="firstName"
                    value={formState.firstName.value}
                    onChange={handleChangeText}
                    className={formState.firstName.error}
                  />
                </div>
              ) : (
                <div className="entered-data-form__inputs">
                  <label htmlFor="company">
                    {t("Company")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="1"
                    type="text"
                    id="company"
                    placeholder={t("Company")}
                    name="name"
                    value={formState.name.value}
                    onChange={handleChangeText}
                    className={formState.name.error}
                  />
                </div>
              )}

              {!webControls.isCarmax && radioValue === 2 && (
                <div className="entered-data-form__inputs">
                  <label htmlFor="telephone">
                    {t("Phone Number")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    maxLength={20}
                    tabIndex="3"
                    type="tel"
                    id="telephone"
                    placeholder="+1 (123) 456-7890"
                    name="telephone"
                    value={formState.telephone.value}
                    onChange={handleChangeText}
                    className={formState.telephone.error}
                  />
                </div>
              )}

              {webControls.isCarmax && radioValue === 1 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="VAT">{t("VAT registration number")}</label>
                  <input
                    tabIndex="3"
                    type="text"
                    id="VAT"
                    placeholder={t("VAT registration number")}
                    value={formState.VATRegistrationNumber.value}
                    name="VATRegistrationNumber"
                    onChange={handleChangeText}
                  />
                </div>
              ) : !webControls.isCarmax && radioValue === 2 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="email">
                    E-mail <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="5"
                    type="email"
                    id="email"
                    placeholder="E-mail"
                    name="email"
                    value={formState.email.value}
                    onChange={handleChangeText}
                    className={formState.email.error}
                  />
                </div>
              ) : (
                <div className="entered-data-form__inputs">
                  <label htmlFor="VAT">{t("VAT registration number")}</label>
                  <input
                    tabIndex="3"
                    type="text"
                    id="VAT"
                    placeholder={t("VAT registration number")}
                    value={formState.VATRegistrationNumber.value}
                    name="VATRegistrationNumber"
                    onChange={handleChangeText}
                  />
                </div>
              )}

              <div className="entered-data-form__inputs">
                <label htmlFor="country">
                  {t("Country")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <CountrySelect
                  tabIndex={radioValue === 1 ? "5" : "7"}
                  defaultValue={
                    formData.countryCode === undefined
                      ? t("Choose Country")
                      : selectedCountry
                  }
                  value={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  countryName={"countryCode"}
                  className={
                    selectedCountry === t("Choose Country") ? countryError : ""
                  }
                />
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="address">
                  {t("Address")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex={radioValue === 1 ? "7" : "9"}
                  type="text"
                  id="address"
                  placeholder={t("Address")}
                  onChange={handleChangeText}
                  name="address"
                  value={formState.address.value}
                  className={formState.address.error}
                />
              </div>
              {!webControls.isCarmax && radioValue === 2 && (
                <div className="entered-data-form__inputs">
                  <label htmlFor="country">
                    {t("How did you hear about us?")}
                  </label>
                  <HowDidYouHearSelect
                    tabIndex="11"
                    defaultValue={
                      formData.howDidYouHear === undefined
                        ? t("-- Please specify? --")
                        : howDidYouHearSelect
                    }
                    value={howDidYouHearSelect}
                    setHowDidYouHearSelect={setHowDidYouHearSelect}
                    activityName={"howDidYouHear"}
                    className={
                      howDidYouHearSelect === "Is company a car trader?" ||
                      howDidYouHearSelect === 0
                        ? "input-red"
                        : ""
                    }
                  />
                </div>
              )}
            </div>
            <div className="entered-data-form-inputs__section">
              {!webControls.isCarmax && radioValue === 2 && (
                <div className="entered-data-form__inputs">
                  <label htmlFor="lastName">
                    {t("Last name")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <input
                    tabIndex="2"
                    type="text"
                    id="lastName"
                    placeholder={t("Last name")}
                    name="lastName"
                    value={formState.lastName.value}
                    onChange={handleChangeText}
                    className={formState.lastName.error}
                  />
                </div>
              )}
              <div className="entered-data-form__inputs">
                <label htmlFor="registrationNumber">
                  {webControls.isCarmax || radioValue === 1
                    ? t("Company registration number")
                    : "Numri personal"}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex={radioValue === 1 ? "2" : "4"}
                  type="text"
                  id="registrationNumber"
                  placeholder={
                    webControls.isCarmax
                      ? t("Company registration number")
                      : "Numri personal"
                  }
                  onChange={handleChangeText}
                  name="companyRegistrationNumber"
                  value={formState.companyRegistrationNumber.value}
                  className={formState.companyRegistrationNumber.error}
                />
              </div>

              {webControls.isCarmax && radioValue === 1 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="country">
                    {t("Type of activity")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <TypeOfActivitySelect
                    tabIndex="4"
                    defaultValue={
                      formData.typeOfActivity === undefined
                        ? t("Is company a car trader?")
                        : selectedActivity
                    }
                    value={selectedActivity}
                    setSelectedActivity={setSelectedActivity}
                    activityName={"typeOfActivity"}
                    className={
                      selectedActivity === t("Is company a car trader?") ||
                      selectedActivity === 0
                        ? countryError
                        : ""
                    }
                  />
                </div>
              ) : !webControls.isCarmax && radioValue === 2 ? (
                <div className="entered-data-form__inputs">
                  <label htmlFor="emailRepeat">
                    {t("Repeat your e-mail")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <input
                      tabIndex="6"
                      type="email"
                      id="emailRepeat"
                      placeholder={t("Repeat your e-mail")}
                      name="emailRepeat"
                      value={formState.emailRepeat.value}
                      onChange={handleChangeText}
                      className={formState.emailRepeat.error}
                    />
                    <p className="error-message">
                      {formState.emailMatch.error}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="entered-data-form__inputs">
                  <label htmlFor="country">
                    {t("Type of activity")}{" "}
                    <span className="required">{t("(required)")}</span>
                  </label>
                  <TypeOfActivitySelect
                    tabIndex="4"
                    defaultValue={
                      formData.typeOfActivity === undefined
                        ? t("Is company a car trader?")
                        : selectedActivity
                    }
                    value={selectedActivity}
                    setSelectedActivity={setSelectedActivity}
                    activityName={"typeOfActivity"}
                    className={
                      selectedActivity === t("Is company a car trader?") ||
                      selectedActivity === 0
                        ? countryError
                        : ""
                    }
                  />
                </div>
              )}

              <div className="entered-data-form__inputs">
                <label htmlFor="city">
                  {t("City")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  tabIndex={radioValue === 1 ? "6" : "8"}
                  type="text"
                  id="city"
                  placeholder={t("City")}
                  onChange={handleChangeText}
                  name="city"
                  value={formState.city.value}
                  className={formState.city.error}
                />
              </div>
              <div className="entered-data-form__inputs">
                <label htmlFor="postCode">
                  {t("Post code")}{" "}
                  <span className="required">{t("(required)")}</span>
                </label>
                <input
                  maxLength={10}
                  tabIndex={radioValue === 1 ? "8" : "10"}
                  type="text"
                  id="postCode"
                  placeholder={t("Post code")}
                  onChange={handleChangeText}
                  name="postCode"
                  value={formState.postCode.value}
                  className={formState.postCode.error}
                />
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="entered-data-step1__button">
        <button
          className="next-button"
          onClick={
            webControls.isCarmax || radioValue === 1 ? nextChange : nextChangeKs
          }
        >
          {t("Next")} &gt;&gt;
        </button>
      </div>
    </>
  );
}

export default EnterDataStep1;
