import { Link } from "react-router-dom";
import "./Footer.css";

import { AiFillCaretRight } from "react-icons/ai";
import { FaLinkedin, FaMapMarkerAlt } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import { FaRegEnvelope } from "react-icons/fa";
import { RiFacebookCircleFill, RiInstagramLine } from "react-icons/ri";
import { FiTwitter } from "react-icons/fi";
import { TfiGoogle } from "react-icons/tfi";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { ContactContext } from "../../Context/ContactInfoContext";
import { webControls } from "../../endpoints";
import { useTranslation } from "react-i18next";

import carmaxLogoKs from "../../assets/images/carmax_new.png";

function Footer() {
  const isLoggedIn = useContext(AuthContext);

  const { data } = useContext(ContactContext);
  const { t } = useTranslation();

  const getAddressValueByKey = (key) => {
    const addressData = data.find((item) => item.key === key);
    if (addressData) {
      return addressData.value;
    }
    return null;
  };

  const getTelValueByKey = (key) => {
    const keyData = data.find((item) => item.key === key);
    if (keyData) {
      return keyData.value;
    }
    return null;
  };

  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <>
      <div className="footer-bg">
        <div className="container">
          <footer className="footer">
            <div className="footer__informations">
              {webControls.isCarmax ? (
                <span className="footer__informations-logo">CARMAX</span>
              ) : (
                <img style={{ width: "130px" }} src={carmaxLogoKs} alt="" />
              )}
              <p className="footer__informations-p grayish">
                {t("Experience the excitement of car auctions with")}{" "}
                {webControls.isCarmax ? "Carmax" : "CARMAX-KS"}.{" "}
                {t("For inquiries, contact us")}.
              </p>
              <div className="footer__informations-data">
                <div className="informations__icon">
                  {" "}
                  <FaMapMarkerAlt className="grayish" />
                </div>
                <p className="grayish small">
                  {getAddressValueByKey("Address")}
                </p>
              </div>
              <div className="footer__informations-data">
                <div className="informations__icon">
                  <HiPhone className=" grayish" />
                </div>
                <p className="grayish small">{getTelValueByKey("Tel")}</p>
              </div>
              <div className="footer__informations-data">
                <div className="informations__icon">
                  {" "}
                  <FaRegEnvelope className=" grayish" />
                </div>
                <Link
                  to="mailto:info@carmaxlive.com "
                  className="mailto-link grayish small"
                >
                  {getTelValueByKey("Email")}
                </Link>
              </div>
            </div>
            <div
              className="footer__links"
              style={{ marginBottom: !isLoggedIn.isLoggedIn ? "31px" : "" }}
            >
              <h3 className="footer__titles">{t("FEATURED LINKS")}</h3>
              <div className="footer__lists">
                <ul>
                  <li className="grayish">
                    <Link
                      className="grayish page-nav-links"
                      to="/"
                      onClick={handleClick}
                    >
                      <AiFillCaretRight className="right-icon" /> {t("Home")}
                    </Link>
                  </li>
                  {isLoggedIn.isLoggedIn && (
                    <li className="grayish">
                      <Link
                        className="grayish page-nav-links"
                        to="search"
                        onClick={handleClick}
                      >
                        <AiFillCaretRight className="right-icon" />{" "}
                        {t("Search now")}
                      </Link>
                    </li>
                  )}
                  <li className="grayish">
                    <Link
                      className="grayish page-nav-links"
                      to="services"
                      onClick={handleClick}
                    >
                      <AiFillCaretRight className="right-icon" />{" "}
                      {t("Services")}
                    </Link>
                  </li>
                  <li className="grayish">
                    <Link
                      className="grayish page-nav-links"
                      to="about"
                      onClick={handleClick}
                    >
                      <AiFillCaretRight className="right-icon" />{" "}
                      {t("About Us")}
                    </Link>
                  </li>
                  <li className="grayish">
                    <Link
                      className="grayish page-nav-links"
                      to="contact"
                      onClick={handleClick}
                    >
                      <AiFillCaretRight className="right-icon" /> {t("Contact")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__connect">
              <h3 className="footer__titles">{t("CONNECT WITH US")}</h3>
              <div className="footer__connect-links">
                <Link
                  className="grayish"
                  to={
                    webControls.isCarmax
                      ? "https://www.facebook.com/carmaxslovenia"
                      : "https://www.facebook.com/profile.php?id=61554240477849"
                  }
                  target="_blank"
                >
                  <RiFacebookCircleFill /> Facebook
                </Link>
                <Link className="grayish" to="#">
                  <RiInstagramLine /> Instagram
                </Link>
                <Link className="grayish" to="#">
                  <FaLinkedin /> LinkedIn
                </Link>
                <Link className="grayish" to="#">
                  <FiTwitter /> Twitter
                </Link>
                <Link className="grayish" to="#">
                  <TfiGoogle /> Google+
                </Link>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="sub-footer-bg">
        <div className="container">
          <div className="sub-footer">
            <p className="sub-footer__p grayish">
              Copyrights &copy;{" "}
              <span className="sub-footer__company-name">
                {" "}
                {webControls.isCarmax ? "Carmax" : "Carmax.ks"}.
              </span>{" "}
              {t("Developed by")}{" "}
              <Link
                className="sub-footer__dev"
                to="https://tretek.io/"
                target="_blank"
              >
                Tretek
              </Link>
            </p>
            <ul className="sub-footer__list">
              <Link
                className="sub-footer__list-link"
                to="/"
                onClick={handleClick}
              >
                <li className="grayish small">{t("Home")}</li>
              </Link>
              {isLoggedIn.isLoggedIn && (
                <>
                  <Link
                    className="sub-footer__list-link"
                    to="search"
                    onClick={handleClick}
                  >
                    <li className="grayish small">{t("Search")}</li>
                  </Link>
                  <Link
                    className="sub-footer__list-link"
                    to="auctions"
                    onClick={handleClick}
                  >
                    <li className="grayish small">{t("Auctions")}</li>
                  </Link>

                  <Link
                    className="sub-footer__list-link"
                    to="my-bids/my-favorites"
                    onClick={handleClick}
                  >
                    <li className="grayish small">{t("My bids")}</li>
                  </Link>
                </>
              )}
              <Link
                className="sub-footer__list-link"
                to="services"
                onClick={handleClick}
              >
                <li className="grayish small">{t("Services")}</li>
              </Link>
              <Link
                className="sub-footer__list-link"
                to="about"
                onClick={handleClick}
              >
                <li className="grayish small">{t("About Us")}</li>
              </Link>
              <Link
                className="sub-footer__list-link"
                to="contact"
                onClick={handleClick}
              >
                <li className="grayish small">{t("Contact")}</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
