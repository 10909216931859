import AuctionCarList from "../../Auctions/AuctionCarList";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import Protector from "../../Protector/Protector";
import Preloader from "../../Preloader/Preloader";
import {
  FavoriteCarsContext,
  FavoriteCarsContextProvider,
} from "../../../Context/FavoriteCarsContext";
import { useTranslation } from "react-i18next";

function MyFavorites() {
  const isLoggedIn = useContext(AuthContext);
  const {
    favoriteCars,
    getClientFavorites,
    isReady,
    setFavoriteCars,
    setFavoriteCarsNo,
  } = useContext(FavoriteCarsContext);
  const { t } = useTranslation();

  useEffect(() => {
    getClientFavorites();
  }, []);

  return (
    <Protector
      isLoggedIn={isLoggedIn.isLoggedIn}
      error={
        <div className="my-bids-error">
          {t("You don't have access to this path!")}
        </div>
      }
    >
      <Preloader isReady={isReady}>
        <div style={{ margin: "5rem auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
              marginTop: "5rem",
            }}
            className="bids-list"
          >
            {Array.isArray(favoriteCars) && favoriteCars.length > 0 ? (
              favoriteCars?.map((data) => {
                return (
                  <div key={data.id}>
                    <AuctionCarList
                      id={data.id}
                      hp={data.hp}
                      km={data.km}
                      name={data.name}
                      petrol={data.petrol}
                      transmission={data.transmission}
                      registrationDate={data.registrationDate}
                      finishingDateTime={data.finishingDateTime}
                      registrationNumber={data.registrationNumber}
                      country={data.country}
                      vehicleCategory={data.vehicleCategory}
                      isFavorite={data.isFavorite}
                      src={data.carMainImage}
                      setCars={setFavoriteCars}
                      setCarsNo={setFavoriteCarsNo}
                      startingPrice={data.startingPrice}
                      showBidInput={true}
                      highestBidAmount={data.bidAmount}
                      registrationMonth={data.registrationMonth}
                    />
                  </div>
                );
              })
            ) : (
              <div
                className="description__title"
                style={{ textAlign: "center" }}
              >
                {t("There is no available favorite car.")}
              </div>
            )}
          </div>
        </div>
      </Preloader>
    </Protector>
  );
}

export default MyFavorites;
