import { useState } from "react";
import "./CarFilter.css";
import Filter from "./Filter";
import { VscListSelection } from "react-icons/vsc";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useTranslation } from "react-i18next";

function CarFilter({
  searchValues,
  setSearchValues,
  isFilterOpened,
  showList,
  setShowList,
  setCurrentPage,
  status,
  isRequestBeingMade,
}) {
  const [showFilter, setShowFilter] = useState(isFilterOpened || false);
  const { t } = useTranslation();

  const handleClick = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <div>
        <Filter
          showFilter={showFilter}
          setShowList={setShowList}
          showList={showList}
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          setCurrentPage={setCurrentPage}
          status={status}
          isRequestBeingMade={isRequestBeingMade}
        />

        <button onClick={handleClick} className="filter__button">
          <VscListSelection />

          {!showFilter ? t("Expand filter") : t("Minimize filter")}
          {!showFilter ? <MdExpandMore /> : <MdExpandLess />}
        </button>
      </div>
    </>
  );
}

export default CarFilter;
