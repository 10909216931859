import AboutUs from "./AboutUs";
import HowWeOperate from "./HowWeOperate";
import RegisterNow from "./RegisterNow";
import ReviewAbout from "./ReviewAbout";

function AboutMain() {
  return (
    <>
      <AboutUs />
      <HowWeOperate />
      <RegisterNow />
      <ReviewAbout />
    </>
  );
}

export default AboutMain;
