import { useEffect, useRef, useState } from "react";
import { GetAuctionCarsList, GetCarBrands } from "../../endpoints";
import { OpenedClosedAuctionEnum } from "../../Enums";
import { Checkbox } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import HomeSearchAuctionCarsKs from "./HomeSearchAuctionCarsKs";
import { Link } from "react-router-dom";
import Preloader from "../Preloader/Preloader";

const CarmaxKsHomeCarSearch = () => {
  const [brands, setBrands] = useState([]);
  const [searchValues1, setSearchValues1] = useState({
    brands: ["Mercedes"],
  });
  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    hasNextPage: true,
  });

  const [isReady, setIsReady] = useState(false);

  const [cars, setCars] = useState([]);
  const containerRef = useRef(null);
  const scrollStep = 0.2;
  let isDragging = false;
  let startPosition = 0;

  useEffect(() => {
    GetCarBrands("", OpenedClosedAuctionEnum.Opened, true).then((res) => {
      setBrands(res?.data?.brandsWithCounts);
    });
  }, []);

  useEffect(() => {
    GetAuctionCarsList(
      paginationState.pageNumber,
      searchValues1,
      "",
      OpenedClosedAuctionEnum.Opened
    ).then((res) => {
      if (res.data.success) {
        setCars((prev) => [...prev, ...res?.data?.auctionCars]);
        setPaginationState((prev) => ({
          ...prev,
          hasNextPage: res.data.hasNextPage,
        }));
        setIsReady(true);
      }
    });
  }, [searchValues1, paginationState.pageNumber]);

  const handleBrandCheckboxChange = (checkedValues) => {
    setPaginationState({
      pageNumber: 1,
      hasNextPage: true,
    });
    setSearchValues1((prevValues) => ({
      ...prevValues,
      brands: checkedValues,
    }));
    setCars([]);
  };

  const handleScrollRight = () => {
    const container = containerRef.current;
    const maxScrollLeft = container.scrollWidth - container.clientWidth;
    const newScrollPosition = Math.min(
      container.scrollLeft + container.clientWidth * scrollStep,
      maxScrollLeft
    );
    container.scrollTo({ left: newScrollPosition, behavior: "smooth" });
  };

  const handleScrollLeft = () => {
    const container = containerRef.current;
    const newScrollPosition = Math.max(
      container.scrollLeft - container.clientWidth * scrollStep,
      0
    );
    container.scrollTo({ left: newScrollPosition, behavior: "smooth" });
  };

  const handleMouseDown = (e) => {
    isDragging = true;
    startPosition = e.clientX;
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      e.preventDefault();
      const container = containerRef.current;
      const delta = e.clientX - startPosition;
      container.scrollLeft -= delta;
      startPosition = e.clientX;
    }
  };

  const handleMouseUp = () => {
    isDragging = false;
  };

  const handleTouchStart = (e) => {
    isDragging = true;
    startPosition = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      e.preventDefault();
      const container = containerRef.current;
      const delta = e.touches[0].clientX - startPosition;
      container.scrollLeft -= delta;
      startPosition = e.touches[0].clientX;
    }
  };

  const handleTouchEnd = () => {
    isDragging = false;
  };

  return (
    <>
      <div
        style={{
          background: "#014175",
          position: "relative",
          height: "80px",
          display: "flex",
        }}
      >
        <section
          className="container"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <ArrowLeftOutlined
            style={{ color: "#fff", marginRight: "2rem" }}
            onClick={handleScrollLeft}
          />
          <div
            className={`${
              brands?.length > 0 && "carFilter-checkbox__content"
            } `}
            style={{ width: "100%", overflowX: "hidden", border: "none" }}
          >
            <Checkbox.Group
              ref={containerRef}
              style={{ flexWrap: "nowrap", overflowX: "hidden" }}
              className="checkboxes checkbox-border"
              onChange={handleBrandCheckboxChange}
              value={searchValues1.brands}
            >
              {brands?.map((brand, index) => {
                return (
                  <Checkbox
                    style={{ color: "#fff" }}
                    value={brand.brand}
                    key={index}
                  >
                    {brand.brand} ({brand.count})
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </div>

          <ArrowRightOutlined
            style={{ color: "#fff", marginLeft: "2rem" }}
            onClick={handleScrollRight}
          />
        </section>
        <Link
          style={{
            display: "block",
            fontSize: "14px",
            position: "absolute",
            bottom: "-37px",
            left: "50%",
            right: "50%",
            textAlign: "center",
            maxWidth: "max-content",
            transform: "translate(-50%, -50%)",
          }}
          to="search"
          className="register__btn-ks"
        >
          Kërkim i avancuar
        </Link>
      </div>

      <Preloader isReady={isReady}>
        <HomeSearchAuctionCarsKs
          searchValues={searchValues1}
          setPaginationState={setPaginationState}
          paginationState={paginationState}
          cars={cars}
          setCars={setCars}
        />
      </Preloader>
    </>
  );
};

export default CarmaxKsHomeCarSearch;
