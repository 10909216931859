import { toast } from "react-hot-toast";
import { ClientResetPassword } from "../../endpoints";
import "./ResetPassword.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ResetPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dataForm, setDataForm] = useState({
    token: "",
    password: "",
    confirmNewPassword: "",
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const t = urlParams.get("token");
    const tokenWithPlus = t?.replace(/ /g, "+");

    setDataForm((prevData) => ({
      ...prevData,
      token: tokenWithPlus,
    }));
  }, []);

  const [passwordIsValid, setPasswordIsValid] = useState({
    valid: true,
  });

  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState({
    valid: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "password") {
      setPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: t("Please enter a valid password"),
      }));
    }

    if (name === "password") {
      setPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: !(value.trim().length < 6),
        message: t("Password must be at least 6 characters"),
      }));
    }

    if (name === "confirmNewPassword") {
      setConfirmPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: t("Please enter a valid confirm password"),
      }));

      setConfirmPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: dataForm.password === value,
        message: t("Password do not match"),
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (dataForm.password.trim().length === 0) {
      setPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Please enter a valid password"),
        };
      });
    } else if (dataForm.password.trim().length < 6) {
      setPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Password must be at least 6 characters"),
        };
      });
    } else {
      setPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (dataForm.confirmNewPassword.trim().length === 0) {
      setConfirmPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Please enter a valid confirm password"),
        };
      });
    } else if (dataForm.confirmNewPassword !== dataForm.password) {
      setConfirmPasswordIsValid((prev) => {
        return {
          valid: false,
          message: t("Password do not match"),
        };
      });
    } else {
      ClientResetPassword(dataForm).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            duration: 3000,
            id: "passwordReseted",
          });
          navigate("/login");
        } else {
          toast.error(res.data.message, {
            duration: 3000,
            id: "resetError",
          });
        }
      });
      setConfirmPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }
  };

  return (
    <>
      <div className="login-section">
        <div className="container">
          <div className="login">
            <form className="login__content" onSubmit={handleSubmit}>
              <p className="login__content-p">{t("Reset password")}</p>
              <div className="login-input__container">
                <input
                  className="login__input"
                  type="password"
                  placeholder={t("Password")}
                  value={dataForm.password}
                  onChange={handleChange}
                  name="password"
                />
                {!passwordIsValid.valid && (
                  <p className="error-message">{passwordIsValid.message}</p>
                )}
              </div>
              <div className="login-input__container">
                <input
                  className="login__input"
                  type="password"
                  placeholder={t("Confirm password")}
                  value={dataForm.confirmNewPassword}
                  onChange={handleChange}
                  name="confirmNewPassword"
                />
                {!confirmPasswordIsValid.valid && (
                  <p className="error-message">
                    {confirmPasswordIsValid.message}
                  </p>
                )}
              </div>
              <div className="login-line"></div>
              <button className="reset-password__link">{t("Reset")}</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
