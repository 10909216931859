import React from "react";
import ForgotPasswordConfirmation from "../Login/ForgotPasswordConfirmation";

function ForgotPasswordConfirmationPage() {
  return (
    <>
      <ForgotPasswordConfirmation />
    </>
  );
}

export default ForgotPasswordConfirmationPage;
