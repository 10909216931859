import { FaCar, FaGasPump, FaRoad } from "react-icons/fa";
import "./AuctionCarList.css";
import { GiGearStickPattern } from "react-icons/gi";
import { MdSpeed } from "react-icons/md";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useState } from "react";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import Protector from "../Protector/Protector";
import { AuthContext } from "../../Context/AuthContext";
import CountDownTimer from "./CountDownTimer/CountDownTimer";
import {
  AddFavorite,
  AddNewBid,
  RemoveFavorite,
  webControls,
} from "../../endpoints";
import { FaFileInvoiceDollar } from "react-icons/fa";
import InvoiceDetailsModal from "../Modals/InvoiceDetailsModal";
import { useTranslation } from "react-i18next";
import { Input, Spin } from "antd";
import { MdOutlineEuroSymbol } from "react-icons/md";
import { GoInfo } from "react-icons/go";
import toast from "react-hot-toast";
import { LoadingOutlined } from "@ant-design/icons";

// const baseImgUrl = process.env.REACT_APP_IMAGE_URL;
function AuctionCarList({
  id,
  hp,
  km,
  name,
  petrol,
  transmission,
  registrationDate,
  finishingDateTime,
  registrationNumber,
  country,
  vehicleCategory,
  isFavorite,
  src,
  setCars = () => {},
  setCarsNo = () => {},
  bidAmount = 0,
  showInvoiceButton = false,
  startingPrice = 0,
  showBidInput = false,
  highestBidAmount = null,
  registrationMonth = null,
  startingDateTime = null,
  IsOutOfBids = undefined,
  highestBidAmountValue,
}) {
  const [outOfBids, setOutOfBids] = useState(IsOutOfBids);
  const carMainImage = `${src}`;
  const [searchParams, setSearchParams] = useSearchParams();
  const isLoggedIn = useContext(AuthContext);


  const navigate = useNavigate();
  const [isFavState, setIsFavState] = useState(isFavorite);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [imageError, setImageError] = useState(false);
  const finishingDateTime1 = new Date(finishingDateTime);

  const updatedDay = String(finishingDateTime1.getDate()).padStart(2, "0");
  const updatedMonth = String(finishingDateTime1.getMonth() + 1).padStart(
    2,
    "0"
  );
  const updatedYear = finishingDateTime1.getFullYear();
  const updatedHours = String(finishingDateTime1.getHours()).padStart(2, "0");
  const updatedMinutes = String(finishingDateTime1.getMinutes()).padStart(
    2,
    "0"
  );

  const updatedFinishingDateTime = `${updatedMonth}/${updatedDay}/${updatedYear} ${updatedHours}:${updatedMinutes}`;
  const currentTime = new Date();
  const formatedFinishingDate = new Date(updatedFinishingDateTime);

  const [bidValue, setBidValue] = useState(0);
  const [inputError, setInputError] = useState();
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);
  const [newHighestValue, setHighestValue] = useState(
    highestBidAmount || bidAmount
  );

  const { t } = useTranslation();

  const handleFavToggleClick = (value) => {
    if (!webControls.isCarmax && !isLoggedIn.isLoggedIn) {
      return;
    } else if (value) {
      RemoveFavorite(id).then((res) => {
        if (res.data.success) {
          setCars((cars) => cars.filter((data) => data.id != id));
          setIsFavState(false);
          setCarsNo((prev) => prev - 1);
        }
      });
    } else {
      AddFavorite(id).then((res) => {
        if (res.data.success) {
          setIsFavState(true);
        }
      });
    }
  };

  const detailsNavigate = () => {
    navigate(`/car/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const handleChangeBidValue = (e) => {
    const inputValue = e.target.value;
    const parsedValue = parseInt(inputValue) || 0;
    setBidValue(parsedValue);
  };

  const handleSubmitBid = (e) => {
    e.preventDefault();
    // if (
    //   isLoggedIn?.data?.maxOpenBids != null &&
    //   isLoggedIn?.data?.clientBids >= isLoggedIn?.data?.maxOpenBids
    // ) {
    //   toast.error(
    //     `${t("The maximum bid amount allowed for you is ")}${
    //       isLoggedIn?.data?.maxOpenBids
    //     }. ${t("You cannot place a bid exceeding this limit.")}`,
    //     {
    //       duration: 3000,
    //       id: "bidupdatefailed",
    //     }
    //   );
    // } else
    if (bidValue < parseInt(highestBidAmount) + 100) {
      toast.error(
        t(
          "Please enter a bid that is at least €100 more than the previous bid"
        ),
        {
          duration: 3000,
          id: "bidupdatefailed",
        }
      );
    } else if (bidValue < parseInt(bidAmount)) {
      toast.error(
        t(
          "Please enter a bid that is at least €100 more than the previous bid"
        ),
        {
          duration: 3000,
          id: "bidupdatefailed",
        }
      );
    } else if (startingPrice && bidValue <= startingPrice) {
      toast.error(
        `${t("Bid must exceed")} €${startingPrice}. ${t(
          "Please enter a higher amount."
        )}`,
        {
          duration: 3000,
          id: "bidupdatefailed",
        }
      );
    } else if (bidValue == 0) {
      toast.error(t("Bid should be more than €0!"), {
        duration: 3000,
        id: "bidupdatefailed",
      });
    } else {
      try {
        setIsRequestBeingMade(true);
        AddNewBid(id, bidValue).then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              id: "bidadded",
            });
            setBidValue(0);
            setHighestValue(bidValue);
            if (IsOutOfBids != undefined) {
              setOutOfBids(false);
            }
            // isLoggedIn.setData((prev) => ({
            //   ...prev,
            //   clientBids: prev.clientBids + 1,
            // }));
          } else {
            if (
              res.data.message.toLowerCase().includes("bid should be higher")
            ) {
              toast.error(t("Bid should be higher"), {
                duration: 3000,
                id: "bidaddedfailed",
              });
            } else {
              toast.error(res.data.message, {
                duration: 3000,
                id: "bidaddedfailed",
              });
            }
          }
          setIsRequestBeingMade(false);
        });
      } catch (error) {
        console.error("Error adding bid:", error);
      }
    }
  };

  return (
    <Protector
      isLoggedIn={webControls.isCarmax ? isLoggedIn.isLoggedIn : true}
      error={
        <div className="my-bids-error">
          {t("You don't have access to this path!")}
        </div>
      }
    >
      <InvoiceDetailsModal
        key={id}
        showInvoiceModal={showInvoiceModal}
        carId={id}
        setShowInvoiceModal={setShowInvoiceModal}
      />

      <div className="container" id={id} style={{ scrollMargin: 300 }}>
        <Link
          style={{ textDecoration: "none", color: "#2b2b2b" }}
          to={`/car/${id}`}
          onClick={() => {
            setSearchParams((prev) => {
              return {
                page: prev.get("page") || 1,
                carId: id,
              };
            });
          }}
        >
          <div
            className="car-list"
            style={{
              backgroundColor:
                outOfBids !== undefined
                  ? outOfBids
                    ? "rgb(255 0 0 / 5%)"
                    : "rgb(40 167 69 / 7%)"
                  : "unset",
              position: outOfBids !== undefined && "relative",
              // border: outOfBids && "2px solid #ff000075",
            }}
          >
            {outOfBids !== undefined && outOfBids && (
              <div class="ribbon-2">{t("You've been outbid")}. {t("Current Bid")}: {highestBidAmountValue}€</div>
            )}
            {outOfBids !== undefined && !outOfBids && (
              <div class="ribbon-1">{t("You are the highest bid")}</div>
            )}

            <div className="car-list__image">
              <img
                src={
                  imageError || !carMainImage
                    ? "https://remktg.arval.com/buyer/assets/images/motortrade/motortrade-no-photo.svg"
                    : carMainImage
                }
                alt="main-car-image"
                style={{
                  height: showBidInput ? "100%" : "237px",
                  maxHeight: "300px",
                  width: "385px",
                  objectFit: "cover",
                  padding: (imageError || !carMainImage) && "6%",
                  borderRight:
                    (imageError || !carMainImage) && "1px solid #eeeeee",
                }}
                onError={handleImageError}
              />
            </div>
            <div className="car-list-content">
              <div className="car-list-content__name">
                <h3>{name}</h3>
                <div
                  className="car-list-content__fav-icon"
                  onClick={(event) => event.preventDefault()}
                >
                  {" "}
                  {isFavState ? (
                    <>
                      <BsHeartFill
                        className="filled"
                        onClick={() => handleFavToggleClick(true)}
                      />
                    </>
                  ) : (
                    <>
                      <BsHeart
                        className=""
                        onClick={() => handleFavToggleClick(false)}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="car-list-content__paragraph">
                <span className="car-list-content__line"></span>
                <div className="car-list-content-registation">
                  <span>{registrationNumber}</span>
                  <span>{vehicleCategory}</span>
                  <CountDownTimer
                    finishingDateTime={updatedFinishingDateTime}
                  />
                </div>
                <span className="car-list-content__line"></span>

                <div className="paragraph-content">
                  <div className="paragraph-content__image">
                    <div style={{ display: "flex" }}>
                      {(() => {
                        if (country?.toLowerCase() == "france") {
                          return (
                            <span
                              className={`flag-icon flag-icon-fr flex-shrink-0 mr-2`}
                            ></span>
                          );
                        } else if (country?.toLowerCase() == "italy") {
                          return (
                            <span
                              className={`flag-icon flag-icon-it flex-shrink-0 mr-2`}
                            ></span>
                          );
                        } else if (country?.toLowerCase() == "luxembourg") {
                          return (
                            <span
                              className={`flag-icon flag-icon-lu flex-shrink-0 mr-2`}
                            ></span>
                          );
                        } else {
                          return (
                            <span
                              className={`flag-icon flag-icon-${country?.toLowerCase()} flex-shrink-0 mr-2`}
                            ></span>
                          );
                        }
                      })()}
                      {/* <span
                        class={`flag-icon flag-icon-${country?.toLowerCase()} flex-shrink-0 mr-2`}
                      ></span> */}
                      {country?.includes(">")
                        ? country?.match(/> (.*?) </)[1]
                        : country?.trim()}
                    </div>

                    {/* {bidAmount != 0 && (
                    <span>
                      {t("Bid amount")}: {bidAmount?.toLocaleString()}€
                    </span>
                  )} */}

                    {/* {(startingPrice || startingPrice !== 0) && (
                    <span className="starting-price">
                      {t("Start Price")}:{" "}
                      <span>{startingPrice?.toLocaleString()}€</span>
                    </span>
                  )} */}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "0.4rem",
                    alignItems: "center",
                  }}
                >
                  <button
                    // onClick={detailsNavigate}
                    style={{
                      marginTop: "10px",
                      marginTop: "10px",
                      height: "fit-content",
                      padding: "0.4rem",
                    }}
                  >
                    {t("View details")}
                  </button>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "space-between",
                      paddingRight: "1.5%",
                    }}
                  >
                    {new Date(startingDateTime) > currentTime ? (
                      <div className="vat-excluded">{t("VAT excluded")}</div>
                    ) : (
                      <>
                        {startingPrice != null && startingPrice != 0 && (
                          <span className="starting-price">
                            {t("Start Price")}:{" "}
                            <span>{startingPrice?.toLocaleString()}€</span>
                          </span>
                        )}

                        <div>
                          {showBidInput && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                // marginRight: "1rem",
                              }}
                            >
                              <div className="car-details-label__text">
                                <span>{t("Bid price is excluding VAT")}</span>
                              </div>
                              <Input
                                onClick={(event) => event.preventDefault()}
                                disabled={
                                  formatedFinishingDate < currentTime ||
                                  (!webControls.isCarmax &&
                                    !isLoggedIn.isLoggedIn)
                                }
                                className={inputError}
                                style={{
                                  maxWidth: "100%",
                                }}
                                min={0}
                                suffix={<MdOutlineEuroSymbol />}
                                placeholder={
                                  !webControls.isCarmax &&
                                  !isLoggedIn.isLoggedIn
                                    ? "Nuk keni akses!"
                                    : t("Your bid")
                                }
                                type="number"
                                name="bidValue"
                                id="bid"
                                size="large"
                                onChange={(e) => {
                                  handleChangeBidValue(e);
                                }}
                                value={bidValue == 0 ? null : bidValue}
                                step="10"
                              />
                              <button
                                className="bidButton"
                                style={{
                                  opacity:
                                    !webControls.isCarmax &&
                                    !isLoggedIn.isLoggedIn
                                      ? "0.65"
                                      : "1",
                                }}
                                disabled={
                                  isRequestBeingMade ||
                                  (!webControls.isCarmax &&
                                    !isLoggedIn.isLoggedIn)
                                }
                                onClick={(event) => {
                                  handleSubmitBid(event);
                                }}
                              >
                                {isRequestBeingMade ? (
                                  <Spin
                                    style={{ color: "white" }}
                                    size="small"
                                    indicator={
                                      <LoadingOutlined
                                        style={{
                                          fontSize: 24,
                                        }}
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  t("Submit bid")
                                )}
                              </button>
                              {/* {(newHighestValue !== 0 ||
                                newHighestValue !== null) && (
                                <span className="max-bid-amount">
                                  <GoInfo /> {t("Highest Bid")}:
                                  {newHighestValue
                                    ? ` ${newHighestValue?.toLocaleString()}€`
                                    : " N/A"}
                                </span>
                              )} */}
                              {newHighestValue !== 0 && (
                                <span className="max-bid-amount">
                                  <GoInfo /> {t("Your Bid")}:{" "}
                                  {newHighestValue
                                    ? `${newHighestValue}€`
                                    : "N/A"}
                                </span>
                              )}
                              {!webControls.isCarmax &&
                                !isLoggedIn.isLoggedIn && (
                                  <span className="max-bid-amount">
                                    Për të ofruar bid, ju lutemi kyçuni!
                                  </span>
                                )}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {showInvoiceButton && (
                    <button
                      onClick={(event) => {
                        setShowInvoiceModal(true);
                        event.preventDefault();
                      }}
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "0.4rem",
                        marginRight: "1rem",
                      }}
                    >
                      <FaFileInvoiceDollar />
                      {t("Invoice")}
                    </button>
                  )}
                </div>
              </div>
              <div className="car-list-content__type">
                <div className="type">
                  <FaCar />
                  <span>
                    {" "}
                    {registrationMonth}, {registrationDate}
                  </span>
                </div>
                <span className="type__line"></span>
                <div className="type">
                  <FaGasPump />
                  <span>{petrol}</span>
                </div>
                <span className="type__line"></span>
                <div className="type">
                  <GiGearStickPattern />
                  <span>{transmission}</span>
                </div>
                <span className="type__line"></span>
                <div className="type">
                  <FaRoad />
                  <span>{km?.toLocaleString()} km</span>
                </div>
                <span className="type__line"></span>
                <div className="type">
                  <MdSpeed />
                  <span>{hp?.toLocaleString()} KW</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </Protector>
  );
}

export default AuctionCarList;
