import { Pagination, Skeleton } from "antd";
import { GetAuctionCarsList } from "../../endpoints";
import AuctionCarList from "../Auctions/AuctionCarList";
import CarFilter from "../Auctions/CarFilter/CarFilter";
import { useContext, useEffect, useState } from "react";
import { OpenedClosedAuctionEnum } from "../../Enums";
import { useTranslation } from "react-i18next";
import { FilterContext } from "../../Context/FilterContext";
import { useSearchParams } from "react-router-dom";

function SearchNow() {
  const { showList, setShowList, searchValues1 } =
    useContext(FilterContext);
  // const [showList, setShowList] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [auctionCarsList, setAuctionCarsList] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get("page")) || 1);
  const [isReady, setIsReady] = useState(false);
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);
  const { t } = useTranslation();
  const [searchValues, setSearchValues] = useState(searchValues1);


  useEffect(() => {
    const carId = searchParams.get("carId");
    if (carId) {
      const element = document.getElementById(carId);
      if (element && auctionCarsList) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 500); 
      }
    }
  }, [searchParams, isRequestBeingMade]);


  useEffect(() => {
    const pageNum = searchParams.get("page");
    if (showList) {
      setIsRequestBeingMade(true);
      setIsReady(false);
      GetAuctionCarsList(
        currentPage,
        searchValues,
        "",
        OpenedClosedAuctionEnum.Opened
      ).then((res) => {
        setAuctionCarsList(res.data.auctionCars);
        setTotalItems(res.data.total);
        if((res.data.total / 10) < pageNum){
          setSearchParams((prev) => {
            return {
              page: 1,
              carId: prev.get("carId"),
            };
          });
        }
        setIsReady(true);
        setIsRequestBeingMade(false);
      });
    }
  }, [searchValues, currentPage, setSearchParams, searchParams]);

  const handlePaginationChange = (page) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(page);
    setSearchParams({ page });
  };

  return (
    <>
      <div className="animation-inBottom" style={{ margin: "5rem auto" }}>
        <CarFilter
          isFilterOpened={true}
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          showList={showList}
          setShowList={setShowList}
          setCurrentPage={setCurrentPage}
          status={OpenedClosedAuctionEnum.Opened}
          isRequestBeingMade={isRequestBeingMade}
        />
        {showList && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
              marginTop: "5rem",
              textAlign: "center",
            }}
          >
            {isReady ? (
              <>
                {auctionCarsList?.length > 0 && (
                  <>
                    {auctionCarsList?.map((data) => {
                      return (
                        <div key={data.id}>
                          <AuctionCarList
                            id={data.id}
                            hp={data.hp}
                            km={data.km}
                            name={data.name}
                            petrol={data.petrol}
                            transmission={data.transmission}
                            registrationDate={data.registrationDate}
                            finishingDateTime={data.finishingDateTime}
                            registrationNumber={data.registrationNumber}
                            country={data.country}
                            vehicleCategory={data.vehicleCategory}
                            isFavorite={data.isFavorite}
                            src={data.carMainImage}
                            setCars={() => {}}
                            setCarsNo={() => {}}
                            startingPrice={data.startingPrice}
                            showBidInput={true}
                            highestBidAmount={data.bidAmount}
                            registrationMonth={data.registrationMonth}
                          />
                        </div>
                      );
                    })}
                    <Pagination
                      showSizeChanger={false}
                      defaultCurrent={currentPage}
                      total={totalItems}
                      onChange={handlePaginationChange}
                      defaultPageSize={20}
                    />
                  </>
                )}

                {auctionCarsList?.length == 0 && (
                  <div
                    className="description__title"
                    style={{ textAlign: "center" }}
                  >
                    {t("Sorry, no results were found.")}
                  </div>
                )}
              </>
            ) : (
              <div
                className="container auction-skeleton-containers "
                style={{ flexDirection: "column", gap: "2rem" }}
              >
                <Skeleton
                  avatar={{ size: 128, shape: "square" }}
                  active
                  paragraph={{
                    rows: 4,
                  }}
                  title={false}
                />
                <Skeleton
                  avatar={{ size: 128, shape: "square" }}
                  active
                  paragraph={{
                    rows: 4,
                  }}
                  title={false}
                />
                <Skeleton
                  avatar={{ size: 128, shape: "square" }}
                  active
                  paragraph={{
                    rows: 4,
                  }}
                  title={false}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default SearchNow;
