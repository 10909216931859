import React, { createContext, useEffect, useState } from "react";
import { AddFavorite, GetClientFavorites, RemoveFavorite } from "../endpoints";

export const FavoriteCarsContext = createContext({
  favoriteCars: "",
  favoriteCarsNo: 0,
  isReady: Boolean,
  getClientFavorites: () => {},
  setFavoriteCars: 0,
  setFavoriteCarsNo: 0,
});

export const FavoriteCarsContextProvider = ({ children }) => {
  const [favoriteCars, setFavoriteCars] = useState([]);
  const [favoriteCarsNo, setFavoriteCarsNo] = useState(0);
  const [isReady, setIsReady] = useState(false);

  const getClientFavorites = async () => {
    try {
      const res = await GetClientFavorites();
      const auctionCars = res.data?.auctionCars || [];
      setFavoriteCars(auctionCars);
      setFavoriteCarsNo(auctionCars?.length);
      setIsReady(true);
    } catch (error) {
      console.error("Error fetching client favorites: ", error);
    }
  };

  useEffect(() => {
    getClientFavorites();
  }, []);

  return (
    <FavoriteCarsContext.Provider
      value={{
        favoriteCars,
        favoriteCarsNo,
        isReady,
        getClientFavorites,
        setFavoriteCars,
        setFavoriteCarsNo,
      }}
    >
      {children}
    </FavoriteCarsContext.Provider>
  );
};
