import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
const bidURL = "https://localapi.carmaxlive.com/api/";

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

const bidInstance = axios.create({
  baseURL: bidURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

const currentUrl = window.location.href.toString().toLocaleLowerCase();

const isCarmax = () => {
  if (!currentUrl.includes("carmax-kos")) {
    instance.defaults.headers.common["Tenant"] =
      "1C8D5905-DF62-4AD1-9730-EAA3FD80919A";
    bidInstance.defaults.headers.common["Tenant"] =
      "1C8D5905-DF62-4AD1-9730-EAA3FD80919A";
    return true;
  } else {
    instance.defaults.headers.common["Tenant"] =
      "506A16B9-1397-4EB7-BB87-AABFE9E8E560";
    bidInstance.defaults.headers.common["Tenant"] =
      "506A16B9-1397-4EB7-BB87-AABFE9E8E560";
    return false;
  }
};

const webControls = {
  isCarmax: isCarmax(),
};

// LOGIN TOKEN
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

bidInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

// async function refreshAccessToken(setShowExpirySessionModal) {
//   try {
//     const response = await axios.post(
//       `${baseURL}Tokens/getClientRefreshToken`,
//       {
//         refreshToken: localStorage.getItem("refreshToken"),
//         token: localStorage.getItem("accessToken"),
//       }
//     );

//     localStorage.setItem("accessToken", response.data.token);
//     localStorage.setItem("refreshToken", response.data.refreshToken);
//   } catch (err) {
//     console.error(err);
//     setShowExpirySessionModal(true);
//     localStorage.removeItem("accessToken");
//     localStorage.removeItem("refreshToken");
//     throw err;
//   }
// }

// GET TOKEN
async function GetClientToken(tokenBody) {
  return instance.post("/Tokens/getClientToken", tokenBody).then((response) => {
    return Promise.resolve(response);
  });
}

// REGISTRATION
async function UploadFile(formData) {
  return instance.post("/Client/uploadDocument", formData).then((response) => {
    return Promise.resolve(response);
  });
}

async function DeleteUploadedDocument(id) {
  return instance.post(`/Client/deleteDocument?id=${id}`).then((response) => {
    return Promise.resolve(response);
  });
}

async function VerifyDocumentToken(token) {
  return instance
    .get(`/Tokens/verifyDocumentToken?token=${token}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function UploadData(body) {
  return instance.post("/Client/newClient", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function UploadContactData(contactBody) {
  return instance
    .post("/Contact/newContactMessage", contactBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetCountries() {
  return instance.get("/Service/getCountries").then((response) => {
    return Promise.resolve(response);
  });
}

//CHECK IF EMAIL ALREADY EXIST - REGISTRATION
async function CheckIfEmailAlreadyExist(email) {
  return instance
    .get(`/Client/checkIfClientEmailExists?email=${email}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

//CLIENT PROFILE
async function GetCurrentClient() {
  return instance.get(`/Tokens/getCurrentClient`).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetClientDetails(id) {
  return instance.get(`/Client/getClientDetails?id=${id}`).then((response) => {
    return Promise.resolve(response);
  });
}

async function UpdateCurrentClient(currentClientBody) {
  return instance
    .post(`/Client/updateClientProfile`, currentClientBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function ClientChangePassword(clientChangePasswordBody) {
  return instance
    .post("/Client/change-client-password", clientChangePasswordBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function ClientForgotPassword(email) {
  return instance
    .post(`/Client/forgot-password?email=${email}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function ClientResetPassword(resetPasswordBody) {
  return instance
    .post(`/Client/reset-client-password`, resetPasswordBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

// SERVICES AXIOS
async function GetServices(servicesBody) {
  return instance.get("/Service/getServices", servicesBody).then((response) => {
    return Promise.resolve(response);
  });
}

//CONTACT APP SERVICE
async function GetContactAppService(appName) {
  return instance
    .get(`/AppSettings/getAppSettingDetails?name=${appName}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetAuctionList(openedClosedAuction) {
  return instance
    .get(
      `/ActiveAuction/ActiveAuctionList?openedClosedAuction=${openedClosedAuction}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetAuctionCarsList(
  currentPage = 1,
  searchValues,
  id,
  status,
  allCars = false
) {
  const body = {
    auctionId: id,
    pageNumber: currentPage,
    fromKm: searchValues?.MileageFrom,
    toKm: searchValues?.MileageTo,
    fromPower: searchValues?.PowerFrom,
    toPower: searchValues?.PowerTo,
    fromEngineCapacity: searchValues?.EngineCapacityFrom,
    toEngineCapacity: searchValues?.EngineCapacityTo,
    greenhouseGasEmissionClass:
      searchValues?.EuroStandard == null ? "" : searchValues?.EuroStandard,
    fuel: searchValues?.Fuel == null ? "" : searchValues.Fuel,
    gearbox: searchValues?.Gearbox == null ? "" : searchValues?.Gearbox,
    brands: searchValues?.brands,
    fromFirstRegistration: searchValues?.yearFrom,
    toFirstRegistration: searchValues?.yearTo,
    status: status,
    registrationNumber: searchValues?.registrationNumber,
    allCars: allCars,
  };

  return instance
    .post(`/ActiveAuction/ActiveAuctionCarList`, body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetAuctionDetails(id) {
  return instance
    .get(`/ActiveAuction/ActiveAuctionDetails?auctionId=${id}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function ActiveAuctionCarDetails(id) {
  return instance
    .get(`/ActiveAuction/ActiveAuctionCarDetails?id=${id}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function AddFavorite(carId) {
  return instance
    .post(`/ClientFavorite/Add/ClientFavorite?CarAuctionId=${carId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function RemoveFavorite(carId) {
  return instance
    .post(`/ClientFavorite/Delete/ClientFavorite?Id=${carId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetClientFavorites() {
  return instance.get("/ClientFavorite/list").then((response) => {
    return Promise.resolve(response);
  });
}

async function GetPriceList() {
  return instance.get(`/Pricelist/getPriceList`).then((response) => {
    return Promise.resolve(response);
  });
}

async function AddNewBid(carId, bidAmount) {
  const body = {
    carAuctionId: carId,
    bidAmount: bidAmount,
  };
  return bidInstance.post("/ClientBids/newClientBid", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetBidsByStatus(status, currentPage = 1) {
  return instance
    .get(
      `ActiveAuction/ActiveAuctionCar/MyBids?StatusId=${status}&PageNumber=${currentPage}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetInvoiceByCarId(carAuctionId) {
  return instance
    .get(
      `ClientInvoices/Get/Invoice/ByCarAuctionId?CarAuctionId=${carAuctionId}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

function DownloadInvoice(id, carId) {
  window.location.href = `${baseURL}ClientInvoices/ClientInvoiceToPDF?id=${id}&CarId=${carId}`;
}

async function GetDownloadInvoicePdfData(id) {
  return instance
    .get(`ClientInvoices/ClientInvoiceToPDF?id=${id}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetCarBrands(id, status, fromSlider = false) {
  return instance
    .get(
      `/ActiveAuction/Get/BrandTypes?AuctionId=${id}&Status=${status}&fromSlider=${fromSlider}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetFuelTypes(id, status) {
  return instance
    .get(`/ActiveAuction/Get/FuelTypes?AuctionId=${id}&Status=${status}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetGreenhouseGasEmissionClass(id, status) {
  return instance
    .get(
      `/ActiveAuction/Get/GreenhouseGasEmissionClassDto?AuctionId=${id}&Status=${status}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function ConfirmDocuments(token) {
  return instance
    .post(`/Client/confirmDocuments?token=${token}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetCarSoldSlider(pageNumber = 1) {
  return instance
    .get(`/Website/CarsSold/Sliderlist?pageNumber=${pageNumber}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetCarsSoldList() {
  return instance.get(`/Website/CarsSold/list`).then((response) => {
    return Promise.resolve(response);
  });
}

export {
  webControls,
  GetClientToken,
  GetCurrentClient,
  GetClientDetails,
  UpdateCurrentClient,
  UploadFile,
  DeleteUploadedDocument,
  VerifyDocumentToken,
  UploadData,
  GetCountries,
  CheckIfEmailAlreadyExist,
  UploadContactData,
  ClientChangePassword,
  ClientForgotPassword,
  ClientResetPassword,
  GetServices,
  GetContactAppService,
  GetAuctionList,
  GetAuctionCarsList,
  GetAuctionDetails,
  ActiveAuctionCarDetails,
  AddFavorite,
  RemoveFavorite,
  GetClientFavorites,
  GetPriceList,
  AddNewBid,
  instance,
  GetBidsByStatus,
  GetInvoiceByCarId,
  GetDownloadInvoicePdfData,
  GetCarBrands,
  GetFuelTypes,
  GetGreenhouseGasEmissionClass,
  DownloadInvoice,
  ConfirmDocuments,
  GetCarSoldSlider,
  GetCarsSoldList,
};
