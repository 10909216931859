import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./components/Pages/Home";
import AboutPage from "./components/Pages/About";
import ServicesPage from "./components/Pages/Services";
import AuctionsPage from "./components/Pages/Auctions";
import AuctionsListPage from "./components/Pages/AuctionsListPage";
import SearchPage from "./components/Pages/SearchPage";
import ContactPage from "./components/Pages/Contact";
import RootLayout from "./components/Pages/Root";
import ErrorPage from "./components/Pages/Error";
import RegisterPage from "./components/Pages/RegisterPage";
import LoginPage from "./components/Pages/LoginPage";
import ForgotPasswordPage from "./components/Pages/ForgotPasswordPage";
import ForgotPasswordConfirmationPage from "./components/Pages/ForgotPasswordConfirmationPage";
import ResetPasswordPage from "./components/Pages/ResetPasswordPage";
import ChangePasswordPage from "./components/Pages/ChangePasswordPage";
import CarDetailsPage from "./components/Pages/CarDetailsPage";
import UserProfilePage from "./components/Pages/UserProfilePage";
import MyBidsPage from "./components/Pages/MyBidsPage";
import MyFavorites from "./components/MyBids/FavoriteCars/MyFavorites";
import UnderConsideration from "./components/MyBids/UnderConsiderationCars/UnderConsideration";
import ConfirmedCarsPage from "./components/MyBids/ConfirmedCars/ConfirmedCars";
import CancelledCars from "./components/MyBids/CancelledCars/CancelledCars";
import ReceivedCars from "./components/MyBids/ReceivedCars/ReceivedCars";
import { AuthContextProvider } from "./Context/AuthContext";
import { ContactContextProvider } from "./Context/ContactInfoContext";
import { FavoriteCarsContextProvider } from "./Context/FavoriteCarsContext";
import { AuctionsContextProvider } from "./Context/AuctionsContext";
import OpenedAuctions from "./components/Auctions/OpenedAuctions/OpenedAuctions";
import ClosedAuctions from "./components/Auctions/ClosedAuctions/ClosedAuctions";
import { BidsContextProvider } from "./Context/BidsContext";
import { createGlobalStyle } from "styled-components";
import { webControls } from "./endpoints";
import InvoiceFile from "./components/Modals/InvoiceFile";
import Carmax from "./assets/images/carmax-favicon.png";
import CarmaxKS from "./assets/images/carmaxks-favicon.png";
import SoldCarsList from "./components/SoldCarsList/SoldCarsList";
import Tidio from "./Tidio";
import GTM from "./GTM";
import { FilterContextProvider } from "./Context/FilterContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "about", element: <AboutPage /> },
      { path: "services", element: <ServicesPage /> },
      { path: "sold-cars", element: <SoldCarsList /> },
      {
        path: "auctions",
        element: <AuctionsPage />,
        children: [
          // { index: true, element: <AuctionsMain /> },
          { path: "opened", element: <OpenedAuctions /> },
          { path: "closed", element: <ClosedAuctions /> },
        ],
      },
      {
        path: "my-bids",
        element: (
          <FavoriteCarsContextProvider>
            <BidsContextProvider>
              <MyBidsPage />
            </BidsContextProvider>
          </FavoriteCarsContextProvider>
        ),
        children: [
          { path: "my-favorites", element: <MyFavorites /> },
          {
            path: "under-consideration",
            element: <UnderConsideration />,
          },
          { path: "confirmed-cars", element: <ConfirmedCarsPage /> },
          { path: "cancelled-cars", element: <CancelledCars /> },
          { path: "received-cars", element: <ReceivedCars /> },
        ],
      },
      // { path: "my-bids/under-consideration", element: <UnderConsideration /> },
      // { path: "my-bids/confirmed-cars", element: <ConfirmedCarsPage /> },
      // { path: "my-bids/cancelled-cars", element: <CancelledCars /> },
      // { path: "my-bids/received-cars", element: <ReceivedCars /> },
      { path: "auctions/opened/:id", element: <AuctionsListPage /> },
      { path: "auctions/closed/:id", element: <AuctionsListPage /> },
      { path: "car/:id", element: <CarDetailsPage /> },
      { path: "search", element: <SearchPage /> },
      { path: "contact", element: <ContactPage /> },
      { path: "registration/:token", element: <RegisterPage /> },
      { path: "login", element: <LoginPage /> },
      { path: "login/forgot-password", element: <ForgotPasswordPage /> },
      {
        path: "login/forgot-password/forgot-password-confirmation",
        element: <ForgotPasswordConfirmationPage />,
      },
      { path: "login/reset-password", element: <ResetPasswordPage /> },
      { path: "change-password", element: <ChangePasswordPage /> },
      { path: "profile", element: <UserProfilePage /> },
    ],
  },
  {
    path: "view-invoice/:id",
    element: <InvoiceFile />,
  },
]);

function App() {
  document.title = webControls.isCarmax ? "CARMAX" : "CARMAX-KS";

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = webControls.isCarmax ? Carmax : CarmaxKS;

  const GlobalStyles = createGlobalStyle`
:root {
  --primaryColor: ${webControls.isCarmax ? "#336699" : "#014175"};
  --secondaryColor: ${webControls.isCarmax ? "#f4c23d" : "#ffd213"};
  --headerBackground: ${webControls.isCarmax ? "#fafafa" : "#fff"};
  --socialMediaBackground : ${
    webControls.isCarmax
      ? "linear-gradient(-45deg, #2f5e8e 40%, #336699 40%)"
      : "#014175"
  };
  --logoMarginRight: ${webControls.isCarmax ? "6rem" : "auto"};
  --sliderWidth: ${webControls.isCarmax ? "94%" : "100%"};
  --sliderWidthResponsive: ${webControls.isCarmax ? "80%" : "100%"};
  --sliderWidthResponsive2: ${webControls.isCarmax ? "85%" : "100%"};
  --sliderPadding: ${webControls.isCarmax ? "0 10%" : "0"};
  --sliderPaddingResponsive: ${webControls.isCarmax ? "0" : "0 5%"};
  --sliderJustify: ${webControls.isCarmax ? "normal" : "center"};
  --sliderGap: ${webControls.isCarmax ? "3rem" : "2rem"};
  --sliderHeight: ${webControls.isCarmax ? "25rem" : "auto"};
  --sliderHeightResponsive: ${webControls.isCarmax ? "38rem" : "auto"};
  --sliderMarginTop: ${webControls.isCarmax ? "1rem" : "7rem"};
  --sliderMarginBottom: ${webControls.isCarmax ? "4rem" : "0"};
  --sliderMarginBottomResponsive: ${webControls.isCarmax ? "4rem" : "6rem"};

  --subHeaderHoverColorLink: ${webControls.isCarmax ? "#f4c23d" : "#ffd213"};

  --contactBtnColor: ${webControls.isCarmax ? "#12100b" : "#014175"};

  --footerHeight: ${webControls.isCarmax ? "20rem" : "23rem"};

  --headerHeight: ${webControls.isCarmax ? "110px" : "90px"};
  --navbarLinkFontSize: ${webControls.isCarmax ? "12px" : "14px"};
  --navbarLinksGap: ${webControls.isCarmax ? "2.2rem" : "1.8rem"};
  --navbarMarginRight: ${webControls.isCarmax ? "auto" : "0"};

  --LogoWidth: ${webControls.isCarmax ? "180px" : "100px"};

  --navbarLinkColor: ${webControls.isCarmax ? "#222222" : "#014175"};
  --navbarUnderlineLinkColor: ${webControls.isCarmax ? "#f4c23d" : "#FFD213"};
  --blackAndWhite: ${webControls.isCarmax ? "#fff" : "#000"};
  --footerHeaderLinks: ${webControls.isCarmax ? "#fff" : "#014175"};
  --whyChooseUsBgColor: ${webControls.isCarmax ? "#f5fafe" : "#fff"};
  --blackToWhite:  ${webControls.isCarmax ? "#12100b" : "#fff"};
  --primaryToBloack: ${webControls.isCarmax ? "#336699" : "#000"};

  --registerForFreeHoverBtn: ${webControls.isCarmax ? "#24486c" : "#014175"};

  --grayishToBloack: ${webControls.isCarmax ? "#8997a5" : "#014175"};


  --footerBg: ${webControls.isCarmax ? "#26313c" : "#fff"};

  --subFooterBg: ${webControls.isCarmax ? "#1e2832" : "#fff"};

  --primaryToBlack:  ${webControls.isCarmax ? "#336699" : "#000"};

  --registerNowTitleColor:  ${webControls.isCarmax ? "#218aac" : "#FFD213"};
  --registerNowBtnColor:  ${webControls.isCarmax ? "#fff" : "#014175"};
  --registerNowButtonHoverColor:  ${
    webControls.isCarmax ? "#1b738f" : "#FFD213"
  };

  --secondaryToBlack:  ${webControls.isCarmax ? "#f4c23d" : "#000"};
  --secondaryToGray:  ${webControls.isCarmax ? "#f4c23d" : "#f0f0f0"};

  --auctionBg:  ${webControls.isCarmax ? "#f5fafe" : "#ebf0f5"};

  --whiteToPrimary:  ${webControls.isCarmax ? "#fff" : "#f00"};

}`;

  return (
    <>
      {!webControls.isCarmax && <GTM />}
      <GlobalStyles />
      <AuthContextProvider>
        <FilterContextProvider>
          <ContactContextProvider>
            <AuctionsContextProvider>
              <RouterProvider router={router} />
            </AuctionsContextProvider>
          </ContactContextProvider>
        </FilterContextProvider>
      </AuthContextProvider>
      {!webControls.isCarmax && <Tidio />}
    </>
  );
}

export default App;
