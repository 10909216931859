import React, { createContext, useEffect, useState } from "react";
import { GetBidsByStatus } from "../endpoints";

export const BidsContext = createContext({
  isReady: Boolean,
  getBidsByStatus: () => {},
  bidsByStatus: [],
  underConsiderationCarsNo: 0,
  confirmedCarsNo: 0,
  cancelledCarsNo: 0,
  receivedCarsNo: 0,
  totalItems: 0,
});

export const BidsContextProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [bidsByStatus, setBidsByStatus] = useState();
  const [underConsiderationCarsNo, setunderConsiderationCarsNo] = useState(0);
  const [confirmedCarsNo, setconfirmedCarsNo] = useState(0);
  const [cancelledCarsNo, setcancelledCarsNo] = useState(0);
  const [receivedCarsNo, setreceivedCarsNo] = useState(0);

  const [totalItems, setTotalItems] = useState();

  const getBidsByStatus = async (status, currentPage) => {
    setIsReady(false);
    try {
      const res = await GetBidsByStatus(status, currentPage);
      const bids = res.data?.auctionCars || [];
      setBidsByStatus(bids);
      // setFavoriteCarsNo(auctionCars?.length);
      setunderConsiderationCarsNo(res.data?.underConsideration);
      setconfirmedCarsNo(res.data?.confirmedCars);
      setcancelledCarsNo(res.data?.cancelledCars);
      setreceivedCarsNo(res.data?.receivedCars);

      setTotalItems(res.data?.total);
      setIsReady(true);
    } catch (error) {
      console.error("Error fetching bids by status: ", error);
    }
  };

  return (
    <BidsContext.Provider
      value={{
        isReady,
        getBidsByStatus,
        bidsByStatus,
        underConsiderationCarsNo,
        confirmedCarsNo,
        cancelledCarsNo,
        receivedCarsNo,
        totalItems,
      }}
    >
      {children}
    </BidsContext.Provider>
  );
};
