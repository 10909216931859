import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// import en from "./lng/en-us.json";
import sq from "./lng/sq.json";
import { webControls } from "./endpoints";

const language = webControls.isCarmax ? undefined : "sq";

i18next.use(initReactI18next).init({
  resources: {
    // en: {
    //   translation: en,
    // },
    ...(language && { sq: { translation: sq } }),
  },
  lng: language,
});

export default i18next;
