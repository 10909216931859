import React from "react";

const GTM = () => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-H0KKRJGC1T";
    script.async = true;


    window.dataLayer = window.dataLayer || [];

    script.onload = () => {
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-H0KKRJGC1T");
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null; 
};

export default GTM;
