import ChangePassword from "../Login/ChangePassword"

function ChangePasswordPage() {
  return (
    <>
    <ChangePassword />
    </>
  )
}

export default ChangePasswordPage