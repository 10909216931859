import { useEffect, useState } from "react";

import { GetCarsSoldList } from "../../endpoints";
import Preloader from "../Preloader/Preloader";

const SoldCarsList = () => {
  const [soldCars, setSoldCars] = useState([]);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    GetCarsSoldList().then((res) => {
      if (res.data.success) {
        setSoldCars(res?.data?.soldCars);
        setIsReady(true);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="sold-cars-bg">
        <section
          style={{
            flexDirection: "column",
            height: "100%",
            paddingTop: "4rem",
            paddingBottom: "4rem",
          }}
          className="container"
        >
          <div className="sold-cars-content">
            <div
              style={{ width: "100%", textAlign: "center" }}
              className="sold-cars-titles"
            >
              <h2>Veturat e shitura</h2>
              <p>
                Këtu janë të gjitha veturat e shitura në platformën Carmax
                Kosova
              </p>
            </div>
          </div>
          <Preloader isReady={isReady}>
            <div className="sold-cars-container">
              {soldCars &&
                soldCars.map((soldCar, index) => (
                  <img
                    style={{ cursor: "default" }}
                    key={index}
                    src={soldCar.base64}
                    alt={`Sold Car ${index}`}
                  />
                ))}
            </div>
          </Preloader>
        </section>
      </div>
    </>
  );
};

export default SoldCarsList;
