import { Drawer } from "antd";
import { RxHamburgerMenu, RxInfoCircled } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import carmaxLogo from "../../assets/images/carmax-logo-ph.png";
import CarmaxKsLogo from "../../assets/images/carmax_new.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { webControls } from "../../endpoints";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

function Navbar({ style }) {
  const isLoggedIn = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const { t } = useTranslation();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  function handleRegister() {
    if (isLoggedIn.isLoggedIn) {
      toast(
        (y) => (
          <span className="toast-info">
            <RxInfoCircled className="info-icon" />
            {t("You are already Logged In")}!
          </span>
        ),
        {
          id: "alreadyLoggedIn",
        }
      );
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  const handleScrollClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setOpen(false);
  };

  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div
        className={`header ${isLoggedIn.isLoggedIn ? "header-postion" : ""}`}
        style={style}
      >
        <div className="container">
          <div className="logo">
            <Link to="/" onClick={handleScrollClick}>
              <img
                style={{
                  width: webControls.isCarmax ? "180px" : "100px",
                }}
                src={webControls.isCarmax ? carmaxLogo : CarmaxKsLogo}
                alt=""
              />
            </Link>
          </div>

          <nav className="navbar">
            <ul className="navbar__list">
              <li>
                <Link
                  className={`navbar__link ${
                    activeLink === "/" ? "active" : ""
                  }`}
                  to="/"
                  onClick={() => {
                    setActiveLink("//");
                    handleScrollClick();
                  }}
                >
                  {t("Home")}{" "}
                </Link>
              </li>

              {isLoggedIn.isLoggedIn && webControls.isCarmax && (
                <>
                  <li>
                    <Link
                      className={`navbar__link ${
                        activeLink === "/search" ? "active" : ""
                      }`}
                      to="search"
                      onClick={() => {
                        setActiveLink("/search");
                        handleScrollClick();
                      }}
                    >
                      {t("Search")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`navbar__link ${
                        activeLink.startsWith("/auctions/") ||
                        activeLink.startsWith("/car/")
                          ? "active"
                          : ""
                      }`}
                      to="/auctions/opened"
                      onClick={() => {
                        setActiveLink("/auctions/opened");
                        handleScrollClick();
                      }}
                    >
                      {t("Auctions")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className={`navbar__link ${
                        activeLink === "/my-bids" ||
                        activeLink === "/my-bids/my-favorites" ||
                        activeLink === "/my-bids/under-consideration" ||
                        activeLink === "/my-bids/confirmed-cars" ||
                        activeLink === "/my-bids/cancelled-cars" ||
                        activeLink === "/my-bids/received-cars"
                          ? "active"
                          : ""
                      }`}
                      to="my-bids/under-consideration"
                      onClick={() => {
                        setActiveLink("/my-bis");
                        handleScrollClick();
                      }}
                    >
                      {t("My bids")}
                    </Link>
                  </li>
                </>
              )}

              {!webControls.isCarmax && (
                <>
                  <li>
                    <Link
                      className={`navbar__link ${
                        activeLink === "/search" ? "active" : ""
                      }`}
                      to="search"
                      onClick={() => {
                        setActiveLink("/search");
                        handleScrollClick();
                      }}
                    >
                      {t("Search")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`navbar__link ${
                        activeLink.startsWith("/auctions/") ||
                        activeLink.startsWith("/car/")
                          ? "active"
                          : ""
                      }`}
                      to="/auctions/opened"
                      onClick={() => {
                        setActiveLink("/auctions/opened");
                        handleScrollClick();
                      }}
                    >
                      {t("Auctions")}
                    </Link>
                  </li>

                  {isLoggedIn.isLoggedIn && !webControls.isCarmax && (
                    <li>
                      <Link
                        className={`navbar__link ${
                          activeLink === "/my-bids" ||
                          activeLink === "/my-bids/my-favorites" ||
                          activeLink === "/my-bids/under-consideration" ||
                          activeLink === "/my-bids/confirmed-cars" ||
                          activeLink === "/my-bids/cancelled-cars" ||
                          activeLink === "/my-bids/received-cars"
                            ? "active"
                            : ""
                        }`}
                        to="my-bids/under-consideration"
                        onClick={() => {
                          setActiveLink("/my-bis");
                          handleScrollClick();
                        }}
                      >
                        {t("My bids")}
                      </Link>
                    </li>
                  )}
                </>
              )}

              <li>
                <Link
                  className={`navbar__link ${
                    activeLink === "/services" ? "active" : ""
                  }`}
                  to="services"
                  onClick={() => {
                    setActiveLink("/services");
                    handleScrollClick();
                  }}
                >
                  {t("Services")}
                </Link>
              </li>
              <li>
                <Link
                  className={`navbar__link ${
                    activeLink === "/about" ? "active" : ""
                  }`}
                  to="about"
                  onClick={() => {
                    setActiveLink("/about");
                    handleScrollClick();
                  }}
                >
                  {t("About Us")}
                </Link>
              </li>
              <li>
                <Link
                  className={`navbar__link ${
                    activeLink === "/contact" ? "active" : ""
                  }`}
                  to="contact"
                  onClick={() => {
                    setActiveLink("/contact");
                    handleScrollClick();
                  }}
                >
                  {t("Contact")}
                </Link>
              </li>
              {!webControls.isCarmax && !isLoggedIn.isLoggedIn && (
                <li>
                  <Link
                    style={{ display: "inline", fontSize: "14px" }}
                    to={!isLoggedIn.isLoggedIn ? "registration/0" : ""}
                    className="register__btn-ks"
                    onClick={handleRegister}
                  >
                    Regjistrohu
                  </Link>
                </li>
              )}
            </ul>
          </nav>
          {/* <SearchBar /> */}
          <RxHamburgerMenu className="hamburger-icon" onClick={showDrawer} />
          <Drawer
            // title={
            //   <RxHamburgerMenu
            //     style={{ marginLeft: "auto", fontSize: "20px" }}
            //   />
            // }
            placement="right"
            onClose={onClose}
            open={open}
          >
            <nav className="navbar">
              <ul style={{ display: "flex" }} className="navbar__list">
                <li>
                  <Link
                    className={`navbar__link ${
                      activeLink === "/" ? "active" : ""
                    }`}
                    to="/"
                    onClick={() => {
                      setActiveLink("//");
                      handleScrollClick();
                    }}
                  >
                    {t("Home")}
                  </Link>
                </li>

                {isLoggedIn.isLoggedIn && webControls.isCarmax && (
                  <>
                    <li>
                      <Link
                        className={`navbar__link ${
                          activeLink === "/search" ? "active" : ""
                        }`}
                        to="search"
                        onClick={() => {
                          setActiveLink("/search");
                          handleScrollClick();
                        }}
                      >
                        {t("Search")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`navbar__link ${
                          activeLink.startsWith("/auctions/") ||
                          activeLink.startsWith("/car/")
                            ? "active"
                            : ""
                        }`}
                        to="/auctions/opened"
                        onClick={() => {
                          setActiveLink("/auctions/opened");
                          handleScrollClick();
                        }}
                      >
                        {t("Auctions")}
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={`navbar__link ${
                          activeLink === "/my-bids" ||
                          activeLink === "/my-bids/my-favorites" ||
                          activeLink === "/my-bids/under-consideration" ||
                          activeLink === "/my-bids/confirmed-cars" ||
                          activeLink === "/my-bids/cancelled-cars" ||
                          activeLink === "/my-bids/received-cars"
                            ? "active"
                            : ""
                        }`}
                        to="my-bids/under-consideration"
                        onClick={() => {
                          setActiveLink("/my-bis");
                          handleScrollClick();
                        }}
                      >
                        {t("My bids")}
                      </Link>
                    </li>
                  </>
                )}

                {!webControls.isCarmax && (
                  <>
                    <li>
                      <Link
                        className={`navbar__link ${
                          activeLink === "/search" ? "active" : ""
                        }`}
                        to="search"
                        onClick={() => {
                          setActiveLink("/search");
                          handleScrollClick();
                        }}
                      >
                        {t("Search")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`navbar__link ${
                          activeLink === "/auctions" ||
                          activeLink === "/auctions/opened" ||
                          activeLink === "/auctions/list" ||
                          activeLink === "/auctions/list" ||
                          activeLink === "/auctions/list/146290/details"
                            ? "active"
                            : ""
                        }`}
                        to="auctions/opened"
                        onClick={() => {
                          setActiveLink("/auctions/opened");
                          handleScrollClick();
                        }}
                      >
                        {t("Auctions")}
                      </Link>
                    </li>

                    {isLoggedIn.isLoggedIn && !webControls.isCarmax && (
                      <li>
                        <Link
                          className={`navbar__link ${
                            activeLink === "/my-bids" ||
                            activeLink === "/my-bids/my-favorites" ||
                            activeLink === "/my-bids/under-consideration" ||
                            activeLink === "/my-bids/confirmed-cars" ||
                            activeLink === "/my-bids/cancelled-cars" ||
                            activeLink === "/my-bids/received-cars"
                              ? "active"
                              : ""
                          }`}
                          to="my-bids/under-consideration"
                          onClick={() => {
                            setActiveLink("/my-bis");
                            handleScrollClick();
                          }}
                        >
                          {t("My bids")}
                        </Link>
                      </li>
                    )}
                  </>
                )}
                <li>
                  <Link
                    className={`navbar__link ${
                      activeLink === "/services" ? "active" : ""
                    }`}
                    to="services"
                    onClick={() => {
                      setActiveLink("/services");
                      handleScrollClick();
                    }}
                  >
                    {t("Services")}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`navbar__link ${
                      activeLink === "/about" ? "active" : ""
                    }`}
                    to="about"
                    onClick={() => {
                      setActiveLink("/about");
                      handleScrollClick();
                    }}
                  >
                    {t("About Us")}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`navbar__link ${
                      activeLink === "/contact" ? "active" : ""
                    }`}
                    to="contact"
                    onClick={() => {
                      setActiveLink("/contact");
                      handleScrollClick();
                    }}
                  >
                    {t("Contact")}
                  </Link>
                </li>
                {!webControls.isCarmax && !isLoggedIn.isLoggedIn && (
                  <li>
                    <Link
                      style={{ display: "inline", fontSize: "18px" }}
                      to={!isLoggedIn.isLoggedIn && "registration/0"}
                      className="register__btn-ks"
                      onClick={handleRegister}
                    >
                      Regjistrohu
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </Drawer>
        </div>
      </div>
    </>
  );
}

export default Navbar;
