import React from "react";
import Profile from "../Profile/Profile";

function UserProfilePage() {
  return (
    <>
      <Profile />
    </>
  );
}

export default UserProfilePage;
