import React, { createContext, useEffect, useState } from "react";
import { GetContactAppService } from "../endpoints";

// Create the context
export const ContactContext = createContext({
  data: "",
  setData: () => {},
});

// Create a provider component
export const ContactContextProvider = ({ children }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    GetContactAppService("Contact_Info").then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <ContactContext.Provider
      value={{
        data: data,
        setData: setData,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
