import { useState } from "react";
// import Select from "react-select";
import "./FuelBodyGearbox.css";
import { Input, Select } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";

function FuelBodyGearbox({
  searchValues,
  setSearchValues,
  fuelTypes,
  greenhouseGasEmissionClass,
}) {
  const { t } = useTranslation();

  const fuelOptions = fuelTypes
  ?.filter(({ fuel }) => fuel !== null)
  .map(({ fuel }) => {
    const value = fuel;
    const label = fuel.charAt(0).toUpperCase() + fuel.slice(1).toLowerCase();

    return { value, label };
  });

  const euroOptions = greenhouseGasEmissionClass
  ?.filter(({ greenStandard }) => greenStandard !== null && greenStandard !== undefined)
  .map(({ greenStandard }) => {
    const value = greenStandard;
    const label = greenStandard.charAt(0).toUpperCase() + greenStandard.slice(1).toLowerCase();

    return { value, label };
  });

  const gearboxOptions = [
    { value: "manual", label: "Manual" },
    { value: "automatic", label: t("Automatic") },
  ];

  const handleChangeSearchValue = (option, field) => {
    setSearchValues((prev) => ({ ...prev, [field]: option }));
  };

  return (
    <div className="carFilter-dropdowns__content">
      <div className="fuelBodyGear">
        <div className="fuelBodyGear__content">
          <span>{t("Fuel")}</span>
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            options={fuelOptions}
            value={searchValues?.Fuel}
            onChange={(option) => handleChangeSearchValue(option, "Fuel")}
          />
        </div>
        <div className="fuelBodyGear__content">
          <span>{t("Gearbox")}</span>
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            options={gearboxOptions}
            value={searchValues?.Gearbox}
            onChange={(option) => handleChangeSearchValue(option, "Gearbox")}
          />
        </div>
      </div>
      <div className="fuelBodyGear">
        <div className="fuelBodyGear__content">
          <span>Euro standard</span>
          <Select
            allowClear
            suffixIcon={<IoIosArrowDown />}
            placeholder={t("Select...")}
            options={euroOptions}
            value={searchValues?.EuroStandard}
            onChange={(option) =>
              handleChangeSearchValue(option, "EuroStandard")
            }
          />
        </div>
        <div className="fuelBodyGear__content">
          <span>{t("Registration Number")}</span>
          <Input
            className="registrationno-input"
            value={searchValues?.registrationNumber}
            name={"registrationNumber"}
            onChange={(e) =>
              handleChangeSearchValue(e.target.value, "registrationNumber")
            }
          />
        </div>{" "}
      </div>
    </div>
  );
}

export default FuelBodyGearbox;
