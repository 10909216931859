import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { CgClose } from "react-icons/cg";
import "./GalleryCarousel.css";

const GalleryCarousel = ({ sliderImages }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [indexBoard, setIndexBoard] = useState(false);
  const [index, setIndex] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const images = sliderImages?.map((url) => {
    return {
      src: url,
    };
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--carousel-z-index",
      carouselIndex
    );
  }, [carouselIndex]);

  useEffect(() => {
    const carouselGalleryElements =
      document.getElementsByClassName("_1_Dg2 _3q7r8");
    for (let i = 0; i < carouselGalleryElements.length; i++) {
      carouselGalleryElements[i].classList.add("carousel-gallery");
    }
  }, [carouselIndex]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        carouselRef.current.minimize();
        setIsMinimized(false);
        setCarouselIndex(99);
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isMinimized]);

  const onIndexChangeHandler = ({ curIndex }) => {
    setIndex(curIndex);
  };

  const carouselRef = useRef(null);

  const handleMinimizeClick = () => {
    carouselRef.current.minimize();
    setIsMinimized(false);
    setCarouselIndex(99);
  };

  const handleMaximizeClick = () => {
    setIsMinimized(true);
    setIndexBoard(true);
    carouselRef.current.maximize();
    setCarouselIndex(99999);
  };

  return (
    <>
      <Carousel
        ref={carouselRef}
        images={images}
        index={index}
        className="galleryCarousel"
        hasMediaButton={false}
        hasSizeButton={false}
        onTap={handleMaximizeClick}
        transitionSpeed={6}
        hasLeftButton={isMinimized ? "centerLeft" : false}
        hasRightButton={isMinimized ? "centerRight" : false}
        hasIndexBoard={
          isMinimized ? (indexBoard ? "bottomCenter" : false) : false
        }
        onIndexChange={onIndexChangeHandler}
      />
      {isMinimized ? (
        <button
          className="gallery-carousel__button"
          onClick={handleMinimizeClick}
        >
          <CgClose />
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default GalleryCarousel;
