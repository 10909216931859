import { useContext } from "react";
import AuctionsMain from "../Auctions/AuctionsMain";
import PageHeading from "../PageHeading/PageHeading";
import Protector from "../Protector/Protector";
import { AuthContext } from "../../Context/AuthContext";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { webControls } from "../../endpoints";

function AuctionsPage() {
  const isLoggedIn = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <>
      <Protector
        isLoggedIn={webControls.isCarmax ? isLoggedIn.isLoggedIn : true}
        error={
          <div className="my-bids-error">
            {t("You don't have access to this path!")}
          </div>
        }
      >
        <PageHeading
          title={t("Auctions")}
          thisPage={t("Auctions")}
          paragraph={t(
            "Explore our wide selection of vehicles and hassle-free auctions"
          )}
        />
        <Outlet />
      </Protector>
    </>
  );
}

export default AuctionsPage;
