import { useContext, useEffect, useState } from "react";

import "./Register.css";

import EnterDataStep from "./EnterDataSteps/EnterDataStep";
import ActivateAccount from "./ActivateAccount/ActivateAccount";
import BuyCars from "./BuyCars";
import UploadDocumentsProvider from "../../Context/UploadDocumentsContext";
import RegistersIcons from "./RegistersIcons/RegistersIcons";
import UploadDocumentsSteps from "./UploadDocuments/UploadDocumentsSteps";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import Protector from "../Protector/Protector";
import { VerifyDocumentToken } from "../../endpoints";
import { useTranslation } from "react-i18next";
import UploadDocumentsStepsPrivate from "./UploadDocuments/UploadDocumentsStepsPrivate";

function Register() {
  const [step, setStep] = useState(1);
  const { token } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (token == 0) {
      return;
    }
    VerifyDocumentToken(token).then((res) => {
      const clientType = parseInt(res.data.message);
      if (res.data.success) {
        if (clientType === 1) {
          setStep(3);
        } else {
          setStep(5);
        }
      }
    });
  }, [token]);

  const isLoggedIn = useContext(AuthContext);

  return (
    <UploadDocumentsProvider>
      <Protector
        isLoggedIn={
          !isLoggedIn.isLoggedIn || isLoggedIn.isLoggedIn != undefined
        }
        error={
          <div className="register-loggedIn-error">
            {t("You are already Logged In")}!
          </div>
        }
      >
        <RegistersIcons step={step} />
        <div className="register-bg">
          <div className="container">
            <div className="register__content">
              {step === 1 && <EnterDataStep setStep={setStep} />}
              {step === 2 && <ActivateAccount />}
              {step === 3 && <UploadDocumentsSteps setStep={setStep} />}
              {step === 4 && <BuyCars />}
              {step === 5 && <UploadDocumentsStepsPrivate setStep={setStep} />}
            </div>
          </div>
        </div>
      </Protector>
    </UploadDocumentsProvider>
  );
}

export default Register;
