import Main from "../HomeMain/Main";

function HomePage() {
  return (
    <>
      <Main />
    </>
  );
}

export default HomePage;
