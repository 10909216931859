import Card from "./Card";
import "./Services.css";
import { TbCurrencyDollar } from "react-icons/tb";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { Fragment, useEffect, useState } from "react";
import { GetServices } from "../../endpoints";
import { Empty } from "antd";
import Preloader from "../Preloader/Preloader";
import { useTranslation } from "react-i18next";

function Services() {
  const [data, setData] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    GetServices().then((res) => {
      setData(res.data.services);
      setIsReady(true);
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="services">
          <h3>{t("PRICING TABLES")}</h3>
          <p>
            {t(
              "Find concise pricing tables guiding informed decisions with tailored options to meet your unique needs."
            )}
          </p>
          <div className="services__icon">
            <TbCurrencyDollar />
          </div>
          <Preloader isReady={isReady}>
            <div className="services__content">
              {data?.map((card) => {
                return (
                  <Fragment key={card.id}>
                    <Card
                      title={card.countryName}
                      price={`${t("From")} ${card.price}€`}
                      countryCode={card.countryCode}
                      standardPassenger={
                        card.standardPassengerCar ? (
                          <AiFillCheckCircle className="card-list__check" />
                        ) : (
                          <AiFillCloseCircle className={"card-list__close"} />
                        )
                      }
                      minivan={
                        card.minivan ? (
                          <AiFillCheckCircle className="card-list__check" />
                        ) : (
                          <AiFillCloseCircle className={"card-list__close"} />
                        )
                      }
                      suvOffRoad={
                        card.suv ? (
                          <AiFillCheckCircle className="card-list__check" />
                        ) : (
                          <AiFillCloseCircle className={"card-list__close"} />
                        )
                      }
                    />
                  </Fragment>
                );
              })}
              {data.length === 0 && (
                <Empty description={t("No services yet")} />
              )}
            </div>
          </Preloader>
        </div>
      </div>
    </>
  );
}

export default Services;
