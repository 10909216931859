import "./ContactForm.css";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { FaPaperPlane } from "react-icons/fa";
import { UploadContactData } from "../../endpoints";
import { useContext, useReducer, useState } from "react";
import { toast } from "react-hot-toast";
import { Spin } from "antd";
import { ContactContext } from "../../Context/ContactInfoContext";
import { useTranslation } from "react-i18next";

const contactReducer = (state, action) => {
  switch (action.type) {
    case "INPUT__TYPE__TEXT": {
      const { field, payload } = action;
      const errorMessage = payload.trim() === "" ? "input-red-border" : "";
      return {
        ...state,
        [field]: { value: payload, error: errorMessage },
      };
    }

    default:
      return state;
  }
};

function ContactForm() {
  const { data } = useContext(ContactContext);
  const { t } = useTranslation();

  const getAddressValueByKey = (key) => {
    const addressData = data.find((item) => item.key === key);
    if (addressData) {
      return addressData.value;
    }
    return null;
  };

  const getTelValueByKey = (key) => {
    const keyData = data.find((item) => item.key === key);
    if (keyData) {
      return keyData.value;
    }
    return null;
  };

  const [preLoader, setPreLoader] = useState(false);
  const initialFormState = {
    firstName: {
      error: "",
      value: "",
    },
    lastName: {
      error: "",
      value: "",
    },
    email: {
      error: "",
      value: "",
    },
    phoneNumber: {
      error: "",
      value: "",
    },
    message: {
      error: "",
      value: "",
    },
  };

  const [contactState, dispatch] = useReducer(contactReducer, initialFormState);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChangeText = (e) => {
    dispatch({
      type: "INPUT__TYPE__TEXT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSubmitContact = (e) => {
    e.preventDefault();
    let contactFormIsValid = true;

    if (contactState.firstName.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: contactState.firstName.name,
        error: "input-red-border",
        payload: contactState.firstName.value,
      });
      contactState.firstName.error = "input-red-border";
      contactFormIsValid = false;
    } else {
      contactState.firstName.error = "";
    }
    if (contactState.lastName.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: contactState.lastName.name,
        error: "input-red-border",
        payload: contactState.lastName.value,
      });
      contactState.lastName.error = "input-red-border";
      contactFormIsValid = false;
    } else {
      contactState.lastName.error = "";
    }

    if (
      contactState.email.value === "" ||
      !isValidEmail(contactState.email.value)
    ) {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: contactState.email.name,
        error: "input-red-border",
        payload: contactState.email.value,
      });
      contactState.email.error = "input-red-border";
      contactFormIsValid = false;
    } else {
      contactState.email.error = "";
    }

    if (contactState.phoneNumber.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: contactState.phoneNumber.name,
        error: "input-red-border",
        payload: contactState.phoneNumber.value,
      });
      contactState.phoneNumber.error = "input-red-border";
      contactFormIsValid = false;
    } else {
      contactState.phoneNumber.error = "";
    }

    if (contactState.message.value === "") {
      dispatch({
        type: "INPUT__TYPE__TEXT",
        field: contactState.message.name,
        error: "input-red-border",
        payload: contactState.message.value,
      });
      contactState.message.error = "input-red-border";
      contactFormIsValid = false;
    } else {
      contactState.message.error = "";
    }

    if (contactState.email.value !== "") {
      if (!isValidEmail(contactState.email.value)) {
        contactFormIsValid = false;
        toast.error(t("Please enter a valid email address!"), {
          duration: 2000,
          id: "emailIsNotValid",
        });
      }
    }

    const invalidCharacters = /[ ,:;()[\]<>\\|~`!#$%&*+/=?^]/;

    const containsInvalidChar = invalidCharacters.test(
      contactState.email.value
    );

    if (containsInvalidChar) {
      contactFormIsValid = false;
      toast.error(t("An invalid character was found in the mail header."), {
        duration: 3000,
        id: "emailIsNotValidSemiColonid",
      });
    }

    if (contactFormIsValid) {
      setPreLoader(true);

      UploadContactData({
        firstName: contactState.firstName.value,
        lastName: contactState.lastName.value,
        email: contactState.email.value,
        phoneNumber: contactState.phoneNumber.value,
        message: contactState.message.value,
      })
        .then((response) => {
          if (response.data.success) {
            contactState.firstName.value = "";
            contactState.lastName.value = "";
            contactState.email.value = "";
            contactState.phoneNumber.value = "";
            contactState.message.value = "";
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message, {
              duration: 2000,
              id: "messageNotSentSuccessfuly",
            });
          }
        })
        .finally(() => {
          setPreLoader(false);
        });
    }
  };

  return (
    <>
      <div className="container">
        <div className="contact-section">
          <div className="contact-section__content">
            <h1 className="contact-section__title">
              {t("Reason for Contact")}
            </h1>
            <p className="contact-section__paragraph">
              {t(
                "We would love to hear from you, please use the form below to get in touch with us!"
              )}
            </p>
            <form className="contact-form" action="">
              <div className="contact-form__content">
                <div className="contact-form__inputs">
                  <input
                    className={`${contactState.firstName.error} name-input`}
                    type="text"
                    placeholder={t("First name")}
                    name="firstName"
                    value={contactState.firstName.value}
                    onChange={handleChangeText}
                    maxLength="20"
                    autoComplete="off"
                  />
                  <input
                    className={`${contactState.lastName.error} last-name-input`}
                    type="text"
                    placeholder={t("Last name")}
                    name="lastName"
                    value={contactState.lastName.value}
                    onChange={handleChangeText}
                    maxLength="20"
                    autoComplete="off"
                  />
                </div>
                <div className="contact-form__inputs">
                  <input
                    className={`${contactState.email.error} email-input`}
                    type="text"
                    placeholder={t("Email address")}
                    name="email"
                    value={contactState.email.value}
                    onChange={handleChangeText}
                    maxLength="50"
                    autoComplete="off"
                  />
                  <input
                    className={`${contactState.phoneNumber.error} phone-input`}
                    type="tel"
                    name="phoneNumber"
                    placeholder={t("Phone Number")}
                    value={contactState.phoneNumber.value}
                    onChange={handleChangeText}
                    maxLength="15"
                    autoComplete="off"
                  />
                </div>
                <textarea
                  className={`${contactState.message.error} contact-form__textarea`}
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                  placeholder={t("Write message")}
                  value={contactState.message.value}
                  onChange={handleChangeText}
                  autoComplete="off"
                ></textarea>
                <button
                  className="contact-form__button-bg"
                  onClick={handleSubmitContact}
                  disabled={preLoader}
                >
                  {preLoader ? (
                    <Spin className="contact-spin" indicator={antIcon} />
                  ) : (
                    <Link className="contact-form__btn" to="">
                      {t("Send Message")}
                    </Link>
                  )}
                  <div className="contact-form__icon">
                    {" "}
                    <FaPaperPlane className="" />
                  </div>
                </button>
              </div>
            </form>
          </div>
          <div className="contact-hours">
            <div className="contact-working-hours">
              <h3 className="contact-working-hours__title">
                {t("Headquarters")}
              </h3>
              <p className="contact-working-hours__paragraph">
                {getAddressValueByKey("Address")}
              </p>
            </div>
            <span className="contact-hours__line"></span>
            <div>
              <h3 className="contact-working-hours__title">{t("Call us")}</h3>
              <p className="contact-working-hours__paragraph">
                {getTelValueByKey("Tel")}
              </p>
            </div>
            <span className="contact-hours__line"></span>

            <div>
              <h3 className="contact-working-hours__title">
                {t("Monday - Friday")}
              </h3>
              <p className="contact-working-hours__paragraph">
                9:00am – 10:00pm
              </p>
              <p className="contact-working-hours__paragraph">CEST</p>
            </div>
            <span className="contact-hours__line"></span>

            <div>
              <h3 className="contact-working-hours__title">{t("Weekend")}</h3>
              <p className="contact-working-hours__paragraph">{t("Closed")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
