import React, { createContext, useReducer } from "react";

const defaultFiles = [{ name: "", src: "", fileName: "" }];
const UploadDocumentsContext = createContext({
  uploadedFiles: [],
  addFile: () => {},
});

function files(state, action) {
  if (action.type === "ADDFILE") {
    const existingFileIndex = state.findIndex(
      (el) => el.name === action.file.name
    );

    const existingFile = state[existingFileIndex];

    if (existingFile) {
      const filesArr = [...state];
      filesArr[existingFileIndex] = action.file;

      return filesArr;
    }
    return [...state, action.file];
  }
}

function UploadDocumentsProvider({ children }) {
  const [uploadedDocuments, dispatchUploadedDocuments] = useReducer(
    files,
    defaultFiles
  );

  function addFile(newFiles) {
    dispatchUploadedDocuments({ type: "ADDFILE", file: newFiles });
  }

  return (
    <UploadDocumentsContext.Provider
      value={{ uploadedFiles: uploadedDocuments, addFile: addFile }}
    >
      {children}
    </UploadDocumentsContext.Provider>
  );
}

export { UploadDocumentsContext };

export default UploadDocumentsProvider;
