import React, { createContext, useEffect, useState } from "react";
import { GetAuctionList } from "../endpoints";

export const AuctionsContext = createContext({
  auctionsList: "",
  setAuctionsList: () => {},
  isReady: Boolean,
  allAuctionsNo: Number,
  openedAuctionsNo: Number,
  closedAuctionsNo: Number,
  getAuctionsList: () => {},
  totalItems: Number,
});

export const AuctionsContextProvider = ({ children }) => {
  const [auctionsList, setAuctionsList] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [allAuctionsNo, setAllAuctionsNo] = useState(0);
  const [closedAuctionsNo, setClosedAuctionsNo] = useState(0);
  const [openedAuctionsNo, setOpenedAuctionsNo] = useState(0);

  const [totalItems, setTotalItems] = useState();

  const getAuctionsList = async (openedClosedAuctionEnum) => {
    setIsReady(false);
    try {
      GetAuctionList(openedClosedAuctionEnum).then((res) => {
        const updatedAuctions = res.data?.auctions?.map((auction) => {
          const finishingDateTime = new Date(auction.finishingDateTime);

          const updatedFinishingDateTime = `${String(
            finishingDateTime.getDate()
          ).padStart(2, "0")}/${String(
            finishingDateTime.getMonth() + 1
          ).padStart(2, "0")}/${finishingDateTime.getFullYear()} ${String(
            finishingDateTime.getHours()
          ).padStart(2, "0")}:${String(finishingDateTime.getMinutes()).padStart(
            2,
            "0"
          )}`;

          const startingDateTime = new Date(auction.startingDateTime);

          const updatedStartingDateTime = `${String(
            startingDateTime.getDate()
          ).padStart(2, "0")}/${String(
            startingDateTime.getMonth() + 1
          ).padStart(2, "0")}/${startingDateTime.getFullYear()} ${String(
            startingDateTime.getHours()
          ).padStart(2, "0")}:${String(startingDateTime.getMinutes()).padStart(
            2,
            "0"
          )}`;

          return {
            ...auction,
            finishingDateTime: updatedFinishingDateTime,
            startingDateTime:
              auction.startingDateTime == null
                ? auction.startingDateTime
                : updatedStartingDateTime,
          };
        });
        setAuctionsList(updatedAuctions || []);
        setAllAuctionsNo(res.data?.allAuctionCount);
        setOpenedAuctionsNo(res.data?.openedAuctionCount);
        setClosedAuctionsNo(res.data?.closedAuctionCount);
        setTotalItems(res.data?.allAuctionCount);
        setIsReady(true);
      });
    } catch (error) {
      setAuctionsList([]);
      console.error("Error fetching auctions: ", error);
    }
  };

  return (
    <AuctionsContext.Provider
      value={{
        auctionsList,
        setAuctionsList,
        isReady,
        allAuctionsNo,
        openedAuctionsNo,
        closedAuctionsNo,
        getAuctionsList,
        totalItems,
      }}
    >
      {children}
    </AuctionsContext.Provider>
  );
};
