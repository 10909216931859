import { useTranslation } from "react-i18next";
import AboutMain from "../AboutMain/AboutMain";
import PageHeading from "../PageHeading/PageHeading";

function AboutPage() {
  const { t } = useTranslation();
  return (
    <>
      <PageHeading
        title={t("About Us")}
        thisPage={t("About Us")}
        paragraph={t(
          "Our team is committed to transparency and helping you find your dream car at an affordable price. Start browsing and bidding today!"
        )}
      />
      <AboutMain />
    </>
  );
}

export default AboutPage;
