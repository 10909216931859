import React, { useContext, useEffect } from "react";
import AuctionNav from "../AuctionNav";
import { AuctionsContext } from "../../../Context/AuctionsContext";
import { OpenedClosedAuctionEnum } from "../../../Enums";
import { Skeleton } from "antd";
import AuctionListContent from "../AuctionListContent";
import { useTranslation } from "react-i18next";

const OpenedAuctions = () => {
  const { auctionsList, isReady, getAuctionsList, openedAuctionsNo } =
    useContext(AuctionsContext);
  const { t } = useTranslation();

  useEffect(() => {
    getAuctionsList(OpenedClosedAuctionEnum.Opened);
  }, []);

  return (
    <>
      <AuctionNav />
      <div className="auction-list animation-inBottom">
        <div className="container">
          <span className="auction-list__line"></span>
        </div>
        {isReady ? (
          Array.isArray(auctionsList) && auctionsList.length > 0 ? (
            <>
              {auctionsList.map((data) => (
                <div key={data?.auction}>
                  <AuctionListContent
                    carNo={data?.numberOfCars}
                    auctionId={data?.auction}
                    country={data?.country}
                    endDate={data?.finishingDateTime}
                    countryFlag={data?.countryFlag}
                    status={OpenedClosedAuctionEnum.Opened}
                    startingDate={data?.startingDateTime || null}
                  />
                </div>
              ))}
              {/* <Pagination
                showSizeChanger={false}
                defaultCurrent={currentPage}
                total={openedAuctionsNo}
                onChange={handlePaginationChange}
              /> */}
            </>
          ) : (
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "#222222",
                fontWeight: "700",
              }}
            >
              {t("There are no auctions currently open.")}
            </div>
          )
        ) : (
          <div className="container auction-skeleton-containers">
            <div className="auction-list__content">
              <Skeleton
                active
                paragraph={{
                  rows: 3,
                }}
                title={false}
              />
              <Skeleton
                active
                paragraph={{
                  rows: 3,
                }}
                title={false}
              />
              <Skeleton
                active
                paragraph={{
                  rows: 3,
                }}
                title={false}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OpenedAuctions;
