import React, { useEffect } from "react";

const Tidio = () => {
  useEffect(() => {
    const tidioScript = document.createElement("script");
    tidioScript.src = "//code.tidio.co/pd5oedqbbj1cdodf3nmwl0sgerircq7a.js";
    tidioScript.async = true;
    document.body.appendChild(tidioScript);
  }, []); 

  return <div></div>;
};

export default Tidio;
