import "./RegisterForFree.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { RxInfoCircled } from "react-icons/rx";
import { toast } from "react-hot-toast";
import { webControls } from "../../endpoints";
import { useTranslation } from "react-i18next";

import carmaxWhiteLogo from "../../assets/images/carmax-ks_white-logo.png";

function RegisterForFreeKs() {
  const isLoggedIn = useContext(AuthContext);
  const { t } = useTranslation();

  function handleRegister() {
    if (isLoggedIn.isLoggedIn) {
      toast(
        (y) => (
          <span className="toast-info">
            <RxInfoCircled className="info-icon" />
            {t("You are already Logged In")}!
          </span>
        ),
        {
          id: "alreadyLoggedIn",
        }
      );
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <>
      <div style={{ backgroundColor: "#014175" }}>
        <div className="container">
          <div className="register-for-free">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <span>
                <img src={carmaxWhiteLogo} alt="" />
              </span>
              <h3
                style={{
                  color: "#fff",
                  fontWeight: "600 !important",
                  marginBottom: "0 !important",
                  fontSize: "18px",
                }}
              >
                Ankandet e veturave{" "}
              </h3>
            </div>
            <div className="register-for-free__content">
              <p className="register-for-free__paragraph">
                {webControls.isCarmax ? "Carmax" : "Carmax-Ks"}{" "}
                {t("is an international platform for auto auctions")}.
              </p>
              <p className="register-for-free__paragraph">
                {t(
                  "Used cars after leasing, owned by European banks and leasing companies, are sold in the car auctions. Here you can buy various vehicles: cars, trucks and motorcycles"
                )}
                .
              </p>
              <p className="register-for-free__paragraph">
                {webControls.isCarmax
                  ? t(
                      "All cars on the CARMAX platform are evaluated by independent experts. Some cars have factory warranties"
                    )
                  : t(
                      "All cars on the iDRIVE platform are evaluated by independent experts. Some cars have factory warranties."
                    )}
              </p>
              <p className="register-for-free__paragraph">
                {webControls.isCarmax ? "Carmax" : "Carmax-ks"}{" "}
                {t(
                  "car auctions are updated every day and more than 7000 auto advertisements are provided here each week. Always wide selection of cars from Audi, Volkswagen, Škoda, Toyota, BMW, Mercedes-Benz, Renault, Peugeot, Lexus, Ford, Opel, Citroen and other manufacturers"
                )}
                .
              </p>

              <p className="register-for-free__paragraph">
                {t("Purchase vehicles in different types of car auctions")}:
              </p>
              <ul className="register-for-free__list">
                <li>
                  {t(
                    "In the fixed price auction, you agree to the price offered by the seller"
                  )}
                </li>
                <li>
                  {t(
                    "In the open auctions, you offer a price which is 100 € higher than the current maximum price visible to all the auction participants"
                  )}
                  .
                </li>
              </ul>
              <p className="register-for-free__paragraph">
                {webControls.isCarmax ? "Carmax" : "Carmax-ks"}{" "}
                {t(
                  "offers car delivery, preparation of documents and auto workshop services"
                )}
                .
              </p>
              <p className="register-for-free__paragraph">
                {t("We will be happy to answer all of your questions")}.
              </p>
            </div>
            <Link
              to={!isLoggedIn.isLoggedIn && "registration/0"}
              className="register__btn-ks"
              onClick={handleRegister}
            >
              Regjistrohu
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterForFreeKs;
