import { FiEdit3 } from "react-icons/fi";
import "./Profile.css";

import { CiUser } from "react-icons/ci";
import { useContext, useEffect, useState } from "react";
import { GetClientDetails, UpdateCurrentClient } from "../../endpoints";
import { AuthContext } from "../../Context/AuthContext";
import { toast } from "react-hot-toast";
import Protector from "../Protector/Protector";
import { useTranslation } from "react-i18next";

function Profile() {
  const data = useContext(AuthContext);

  const [formData, setFormData] = useState({
    name: "",
    firstName: "",
    lastName: "",
    countryName: "",
    companyRegistrationNumber: "",
    city: "",
    email: "",
    mobileNo: "",
    address: "",
  });

  const [isEditingName, setIsEditingName] = useState(false);

  const [error, setError] = useState("");
  const { t } = useTranslation();

  const handleEditClick = () => {
    setIsEditingName(true);
  };

  const handleCancelClick = () => {
    GetClientDetails(data.data.id).then((res) => {
      setFormData(res.data);
    });
    setIsEditingName(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    GetClientDetails(data.data.id).then((res) => {
      setFormData(res.data);
    });
  }, [data.data.id]);

  const handleSaveClick = () => {
    if (
      formData.firstName.trim() === "" ||
      formData.lastName.trim() === "" ||
      formData.name.trim() === "" ||
      formData.companyRegistrationNumber.trim() === "" ||
      formData.mobileNo.trim() === ""
    ) {
      setError("error-input");
    } else {
      UpdateCurrentClient({
        id: data.data.id,
        companyName: formData.name,
        firstName: formData.firstName,
        lastName: formData.lastName,
        companyRegistrationNumber: formData.companyRegistrationNumber,
        mobileNo: formData.mobileNo,
      }).then((res) => {
        if (res.data.success) {
          data.setData((prevData) => ({
            ...prevData,
            firstName: formData.firstName,
            lastName: formData.lastName,
          }));
          toast.success(res.data.message, {
            duration: 2000,
            id: "profileUpdatedSuccessfuly",
          });
        }
      });
      setIsEditingName(false);
    }
  };

  return (
    <>
      <Protector
        isLoggedIn={data.isLoggedIn}
        error={
          <div className="my-bids-error">
            {t("You don't have access to this path!")}
          </div>
        }
      >
        <div className="user-profile-bg">
          <div className="container">
            <div className="user-profile">
              <div className="user-profile__icon">
                <CiUser className="user-profile__icon-svg" />
                <span>
                  {formData.firstName} {formData.lastName}
                </span>
                <button
                  className="user-profile__icon-button"
                  onClick={handleEditClick}
                >
                  {t("Edit profile")} <FiEdit3 />
                </button>
              </div>
              <div className="user-profile-info">
                <span className="user-profile-info__title">
                  {t("Information")}
                </span>
                <span className="user-profile-line"></span>
                <div className="user-profile-info__content">
                  <div className="user-profile-info__content-text">
                    <h5>{t("First name")}</h5>
                    {isEditingName ? (
                      <>
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          className={
                            formData.firstName.trim() === "" ? error : ""
                          }
                          placeholder={
                            error ? t("Input field cannot be empty!") : ""
                          }
                        />
                      </>
                    ) : (
                      <span>{formData.firstName}</span>
                    )}
                  </div>
                  <div className="user-profile-info__content-text">
                    <h5>{t("Last name")}</h5>
                    {isEditingName ? (
                      <>
                        <input
                          type="text"
                          value={formData.lastName}
                          name="lastName"
                          onChange={handleChange}
                          className={
                            formData.lastName.trim() === "" ? error : ""
                          }
                          placeholder={
                            error ? t("Input field cannot be empty!") : ""
                          }
                        />
                      </>
                    ) : (
                      <span>{formData.lastName}</span>
                    )}
                  </div>
                </div>
                <div className="user-profile-info__content">
                  <div className="user-profile-info__content-text">
                    <h5>{t("Company")}</h5>
                    {isEditingName ? (
                      <>
                        <input
                          type="text"
                          value={formData.name}
                          name="name"
                          onChange={handleChange}
                          className={formData.name.trim() === "" ? error : ""}
                          placeholder={
                            error ? t("Input field cannot be empty!") : ""
                          }
                        />
                      </>
                    ) : (
                      <span>{formData.name}</span>
                    )}
                  </div>
                  <div className="user-profile-info__content-text">
                    <h5>{t("Company registration number")}</h5>
                    {isEditingName ? (
                      <>
                        <input
                          type="number"
                          name="companyRegistrationNumber"
                          value={formData.companyRegistrationNumber}
                          onChange={handleChange}
                          className={
                            formData.companyRegistrationNumber.trim() === ""
                              ? error
                              : ""
                          }
                          placeholder={
                            error ? t("Input field cannot be empty!") : ""
                          }
                        />
                      </>
                    ) : (
                      <span>{formData.companyRegistrationNumber}</span>
                    )}
                  </div>
                </div>
                <span className="user-profile-info__title user-profile-info__title--margin">
                  {t("Contact")}
                </span>
                <span className="user-profile-line"></span>
                <div className="user-profile-info__content">
                  <div className="user-profile-info__content-text">
                    <h5>Email</h5>
                    <span>{formData.email}</span>
                  </div>
                  <div className="user-profile-info__content-text">
                    <h5>{t("Phone Number")}</h5>
                    {isEditingName ? (
                      <>
                        <input
                          type="tel"
                          value={formData.mobileNo}
                          name="mobileNo"
                          onChange={handleChange}
                          className={
                            formData.mobileNo.trim() === "" ? error : ""
                          }
                          placeholder={
                            error ? t("Input field cannot be empty!") : ""
                          }
                        />
                      </>
                    ) : (
                      <span>{formData.mobileNo}</span>
                    )}
                  </div>
                </div>
                <span className="user-profile-info__title user-profile-info__title--margin">
                  {t("Address")}
                </span>
                <span className="user-profile-line"></span>
                <div className="user-profile-info__content">
                  <div className="user-profile-info__content-text">
                    <h5>{t("Country")}</h5>
                    <span>{formData.countryName}</span>
                  </div>
                  <div className="user-profile-info__content-text">
                    <h5>{t("City")}</h5>
                    <span>{formData.city}</span>
                  </div>
                </div>
                <div className="user-profile-info__content-text">
                  <h5>{t("Address")}</h5>
                  <span>{formData.address}</span>
                </div>
                {isEditingName ? (
                  <div className="user-profile-info__buttons">
                    <button
                      className="user-profile-info__cancel-button"
                      onClick={handleCancelClick}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="user-profile-info__save-button"
                      onClick={handleSaveClick}
                    >
                      {t("Save Changes")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </Protector>
    </>
  );
}

export default Profile;
